import React, { useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { BookingFile } from '../../../../interfaces/Booking';
import { AG_GRID_LOCALE_RO } from '../../../../functions/langHelper';
import Actions from '../../../../components/Actions';
import useGridState from '../../../../functions/useGridState';
import { createDateFromString, formatDate, formatDateTime } from '../../../../functions/dateHelper';
import { errorMessage } from '../../../../functions/generalHelper';

interface FilesTableProps {
  files: Partial<BookingFile>[];
  onDelete: (id: number) => void;
}

const FilesTable: React.FC<FilesTableProps> = ({ files, onDelete }) => {
    const { saveState, restoreState } = useGridState('bookingFiles-grid');

    const onGridReady = useCallback((event: GridReadyEvent) => {
      const api = event.api;
      event.api.closeToolPanel();
      
      // Restaurăm starea salvată
      const savedState = localStorage.getItem(`agGrid-bookingFiles-grid`);
      if (savedState) {
        // Dacă există state salvat, îl restaurăm
        restoreState(api);
      }
      // Adăugăm event listeners pentru salvarea stării
      const saveCurrentState = () => saveState(api);
      
      api.addEventListener('filterChanged', saveCurrentState);
      api.addEventListener('sortChanged', saveCurrentState);
      api.addEventListener('columnMoved', saveCurrentState);
      api.addEventListener('columnResized', saveCurrentState);
      api.addEventListener('paginationChanged', saveCurrentState);

      // Optional: Cleanup function
      return () => {
        api.removeEventListener('filterChanged', saveCurrentState);
        api.removeEventListener('sortChanged', saveCurrentState);
        api.removeEventListener('columnMoved', saveCurrentState);
        api.removeEventListener('columnResized', saveCurrentState);
        api.removeEventListener('paginationChanged', saveCurrentState);
      };
    }, [saveState, restoreState]);

    const columns: ColDef<BookingFile>[] = [
    { 
      headerName: 'Denumire', 
      field: 'originalName', 
      minWidth: 160,
      cellRenderer : (params: any) => {
        return <>
          <a href={`${process.env.REACT_APP_BACKEND_URI}/file/bookingFiles/${params.data.bookingId}/${params.data.storedName}`} target="_blank" rel="noopener noreferrer" className="font-medium cursor-pointer text-blue-600 dark:text-blue-500 hover:underline">
            {params.value}
          </a>
        </>
      }
    },
    { 
      headerName: 'Dimensiune',
      field: 'fileSize',
      minWidth: 160,
      valueFormatter: (params) => {
        const units = ['B', 'KB', 'MB', 'GB', 'TB'];
        let unitIndex = 0;
        
        while (params.value >= 1024 && unitIndex < units.length - 1) {
          params.value /= 1024;
          unitIndex++;
        }
      
        return `${params.value.toFixed(2)} ${units[unitIndex]}`;
      }
    },
    { 
      headerName: 'Data încărcării', 
      field: 'uploadDate', 
      minWidth: 160,
      cellRenderer: (params : any) => <>{formatDateTime(createDateFromString(params.value))}</>,
      comparator: (valueA, valueB) => {
          const dateA = createDateFromString(valueA)?.getTime();
          const dateB = createDateFromString(valueB)?.getTime();
          return dateA && dateB ? dateA - dateB : 0;
      },
    },
    { 
      headerName: 'Utilizator', 
      field: 'user.name', 
      minWidth: 160,
    },
    {
      headerName: 'Acțiuni',
      width: 150, 
      maxWidth: 150,
      field: 'id',
      cellRenderer: (params: any) => (
        <div>
          <Actions 
            id={params.data.id} 
            onDelete={() => onDelete(params.data.id)} 
            moduleName="fisier"
          />
        </div>
      )
    }
  ];

  return (
    <div className="ag-theme-quartz">
      <AgGridReact 
        localeText={AG_GRID_LOCALE_RO}
        columnDefs={columns} 
        rowData={files}
        defaultColDef={{ flex: 1, minWidth: 20 }}
        domLayout='autoHeight'
        pagination={false}
        gridOptions={{rowHeight: 35}}
        enableRangeSelection={true}
        suppressClickEdit={true}
        stopEditingWhenCellsLoseFocus={true}
        onGridReady={onGridReady}
      />
    </div>
  );
};

export default FilesTable;