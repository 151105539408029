import React, { useEffect, useMemo, useState } from 'react';
import { HtmlEditor, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar, Table } from '@syncfusion/ej2-react-richtexteditor';
import { Button } from 'flowbite-react';
import { RecommandationService } from '../../services/RecommandationService';
import { successMessage } from '../../functions/generalHelper';

const EmailSignature: React.FC = () => {
    const [content, setContent] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const userService = useMemo(() => new RecommandationService(), []);

    const toolbarSettings: object = useMemo(() => (
        {
            items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
            'FontSize', 'FontColor', 'BackgroundColor',
            'LowerCase', 'UpperCase', 'Image', '|',
            'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
            'Outdent', 'Indent', 'CreateTable', '|',
            'CreateLink', '|', 'ClearFormat',
            'SourceCode', 'FullScreen', '|', 'Undo', 'Redo']
        }
    ), []);

    useEffect(() => {
        setLoading(true);
        userService.getEmailSignature().then((response) => {
            setContent(response);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    const changeSignature = async () => {
        setLoading(true);
        await userService.saveEmailSignature(content).then((response) => {
            successMessage(response.message);
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <>
            <h1 className="text-xl font-bold mb-4 max-w-[1200px] mx-auto">Semnatura email</h1>
            { loading ? <p>Se incarca...</p> : 
                <RichTextEditorComponent className="max-w-[1200px] mx-auto emailEditor" toolbarSettings={toolbarSettings} change={(e) => setContent(e.value)} >
                    <div dangerouslySetInnerHTML={{ __html: content}} />
                    <Inject services={[Toolbar, Link, HtmlEditor, QuickToolbar, Table]}/>
                </RichTextEditorComponent>
            }
            <Button type="button" disabled={loading} className="mt-3 mx-auto" onClick={changeSignature}>
                {loading ? 'Se incarca...' : 'Modifica semnatura'}
            </Button>
        </>
    );
};

export default EmailSignature;