import React, { useCallback, useState } from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import Actions from '../../components/Actions';
import { AG_GRID_LOCALE_RO } from '../../functions/langHelper';
import Tooltip from '../../components/Tooltip';
import { FaEye } from 'react-icons/fa6';
import { Offer } from '../../interfaces/Offer';
import { formatDate } from '../../functions/dateHelper';
import { IoSend } from "react-icons/io5";
import { OfferService } from '../../services/OfferService';
import { errorMessage, successMessage } from '../../functions/generalHelper';
import Status from './components/Status';
import { Badge } from 'flowbite-react';
import { FaRegCopy } from 'react-icons/fa';
import CheckPermission from '../../components/CheckPermission';
import Loader from '../../components/CustomLoader';
import useGridState from '../../functions/useGridState';
import CustomDateComponent from '../../components/AgGrid/dateFilter/DateFilter';
import DateFloatingFilter from '../../components/AgGrid/dateFilter/DateFloatingFilter';

interface OfferProps {
    offers: Offer[];
    isTemplate: boolean;
    onEdit: (id: number) => void;
    onDelete: (id: number) => void;
    onView: (id: number) => void;
    onSend: (result: Partial<Offer>) => void;
    onClone: (id: number) => void;
}

const Table: React.FC<OfferProps> = ({ offers, isTemplate, onEdit, onDelete, onView, onSend, onClone }) => {
  const pagination = true;
  const paginationPageSize = 20;
  const paginationPageSizeSelector = [10, 20, 50, 100];
  const gridOptions = {rowHeight: 35};
  const offerService = new OfferService();
  const [loading, setLoading] = useState(false);
  const { saveState, restoreState } = useGridState('offers-grid');

  const onGridReady = useCallback((event: GridReadyEvent) => {
    const api = event.api;
    event.api.closeToolPanel();
    
    // Restaurăm starea salvată
    const savedState = localStorage.getItem(`agGrid-offers-grid`);
    if (savedState) {
      // Dacă există state salvat, îl restaurăm
      restoreState(api);
    } else {
        // Configurarea implicita a gridului
    }
    // Adăugăm event listeners pentru salvarea stării
    const saveCurrentState = () => saveState(api);
    
    api.addEventListener('filterChanged', saveCurrentState);
    api.addEventListener('sortChanged', saveCurrentState);
    api.addEventListener('columnMoved', saveCurrentState);
    api.addEventListener('columnResized', saveCurrentState);
    api.addEventListener('paginationChanged', saveCurrentState);
    // Optional: Cleanup function
    return () => {
      api.removeEventListener('filterChanged', saveCurrentState);
      api.removeEventListener('sortChanged', saveCurrentState);
      api.removeEventListener('columnMoved', saveCurrentState);
      api.removeEventListener('columnResized', saveCurrentState);            
      api.removeEventListener('paginationChanged', saveCurrentState);
    };
  }, [saveState, restoreState]);

  const sendOffer = (id : number) => {
    setLoading(true);
    offerService.sendOffer(id).then((result) => {
        if(result.status === 'Trimisă'){
          onSend(result);
          successMessage('Ofertă trimisă cu success!');
        }else{
          errorMessage('Oferta nu a putut fi trimisă, contactați administratorul!');
        }
    }).finally(() => setLoading(false));
  }
  
  const columns: ColDef<Offer>[] =
  isTemplate ?
    [
      { headerName: 'Id', field: 'id', sortable: true, filter: true, minWidth: 60 },
      { headerName: 'Denumire', field: 'email', sortable: true, filter: true, minWidth: 160 },
      { headerName: 'Utilizator', field: 'user.name', sortable: true, minWidth: 160, hide: true, filter: true, },
      {
        headerName: 'Acțiuni',
        field: 'id',
        minWidth: 100,
        cellRenderer: (params: any) => (
          <div>
            <Actions 
              id={params.data.id} 
              onEdit={() => onEdit(params.data.id)} 
              onDelete={() => {onDelete(params.data.id)}} 
              moduleName="ofertă"
              deletePermissions={['OFFER_DELETE']}
              editPermissions={['OFFER_UPDATE']}
            >
              <Tooltip content="Previzualizare">
                <button type="button" className="text-green-500 hover:text-green-700 h-[20px] mt-1 flex items-center mx-1" aria-label="Info" onClick={() => onView(params.data.id)}>
                  <FaEye className="w-[17px] h-[17px] hover:scale-110 duration-75"/>
                </button>
              </Tooltip>
            </Actions>
          </div>
        )
      }
    ]
  :
    [
      { headerName: 'Id', field: 'id', sortable: true, filter: 'agNumberColumnFilter', floatingFilter: true, minWidth: 100, maxWidth: 110 },
      { headerName: 'Client', field: 'client.company', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true, minWidth: 140 },
      { headerName: 'Email', field: 'email', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true, minWidth: 140 },
      { headerName: 'Utilizator', field: 'user.name', sortable: true, minWidth: 160, hide: true, filter: 'agSetColumnFilter', floatingFilter: true, },
      {
        headerName: 'Stare', 
        field: 'status', 
        sortable: true, 
        filter: 'agSetColumnFilter', floatingFilter: true,
        cellStyle: { display: 'flex', justifyContent: 'start', alignItems: 'center' },
        cellRenderer: (params: any) => (<Status status={params.data.status} />),
        minWidth: 130
      },
      { 
        headerName: 'Tip', 
        field: 'type', 
        sortable: true, 
        filter: 'agSetColumnFilter', floatingFilter: true,
        cellStyle: { display: 'flex', justifyContent: 'start', alignItems: 'center' },
        cellRenderer: (params: any) => params.value === 'Import' ? <Badge color="blue">Import</Badge> : <Badge color="warning">Export</Badge>,
        minWidth: 130
      },
      {
        headerName: 'Dată ofertă',
        field: 'validityStartDate',
        minWidth: 130,
        filter: CustomDateComponent,
        floatingFilterComponent: DateFloatingFilter,
        filterParams: {
          field: 'validityStartDate',
          browserDatePicker: true,
        },
        floatingFilter: true,
        sortable: true,
        enableRowGroup: true,
        cellRenderer: (params: any) => (
          <div>{params.value ? formatDate(params.value) : ''}</div>
        )
      },
      {
        headerName: 'Dată expirare',
        field: 'validityEndDate',
        minWidth: 130,
        filter: CustomDateComponent,
        floatingFilterComponent: DateFloatingFilter,
        filterParams: {
          field: 'validityEndDate',
          browserDatePicker: true,
        },
        floatingFilter: true,
        sortable: true,
        enableRowGroup: true,
        cellRenderer: (params: any) => (
          <div>{params.value ? formatDate(params.value) : ''}</div>
        )
      },
      {
        headerName: 'Creată la',
        field: 'createdAt',
        minWidth: 130,
        filter: CustomDateComponent,
        floatingFilterComponent: DateFloatingFilter,
        filterParams: {
          field: 'createdAt',
          browserDatePicker: true,
        },
        floatingFilter: true,
        sortable: true,
        enableRowGroup: true,
        cellRenderer: (params: any) => (
          <div>{params.value ? formatDate(params.value) : ''}</div>
        )
      },
      {
        headerName: 'Acțiuni',
        field: 'id',
        minWidth: 150,
        cellRenderer: (params: any) => (
          <div>
            <Actions 
              id={params.data.id} 
              onEdit={() => onEdit(params.data.id)} 
              onDelete={() => {onDelete(params.data.id)}} 
              moduleName="ofertă"
              deletePermissions={['OFFER_DELETE']}
              editPermissions={['OFFER_UPDATE']}
            >
                <CheckPermission permissions={['OFFER_CREATE']}>
                  <Tooltip content="Clonează">
                    <button type="button" className="text-fuchsia-500 hover:text-fuchsia-700 h-[20px] mt-1 flex items-center mx-1" aria-label="Info" onClick={() => onClone(params.data.id)}>
                      <FaRegCopy className="w-[17px] h-[17px] hover:scale-110 duration-75"/>
                    </button>
                  </Tooltip>
                </CheckPermission>
                <Tooltip content="Previzualizare">
                  <button type="button" className="text-green-500 hover:text-green-700 h-[20px] mt-1 flex items-center mx-1" aria-label="Info" onClick={() => onView(params.data.id)}>
                    <FaEye className="w-[17px] h-[17px] hover:scale-110 duration-75"/>
                  </button>
                </Tooltip>
                <CheckPermission permissions={['OFFER_UPDATE']}>
                  <Tooltip content="Trimite">
                    <button type="button" className="text-yellow-500 hover:text-yellow-700 h-[20px] mt-1 flex items-center mx-1" aria-label="Info" onClick={() => sendOffer(params.data.id)}>
                      <IoSend className="w-[17px] h-[17px] hover:scale-110 duration-75"/>
                    </button>
                  </Tooltip>
                </CheckPermission>
              </Actions>
          </div>
        )
      }
    ];

  return (
    <>
      <Loader isLoading={loading} />
      <AgGridReact 
        localeText={AG_GRID_LOCALE_RO}
        columnDefs={columns} 
        rowData={offers}
        defaultColDef={{ flex: 1, minWidth: 20 }}
        domLayout='autoHeight'
        pagination={pagination}
        paginationPageSize={paginationPageSize}
        paginationPageSizeSelector={paginationPageSizeSelector}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        sideBar={true}
      />
    </>
  );
};



export default Table;
