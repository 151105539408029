import client from '../apollo-client';
import { gql } from '@apollo/client';
import { MovementFile, MovementLogic } from '../interfaces/Movement';

export class MovementFileService {
    async create(movementFile: MovementFile): Promise<number> {
        const response = await client.mutate({
          mutation: gql`
            mutation createMovementFile($columns: [FieldHeaderInputDto!]!){
                createMovementFile(
                  input: { 
                      name: "${movementFile.name}", 
                      description: ${movementFile.description ? `"${movementFile.description}"` : null},
                      type: "${movementFile.type}", 
                      terminal: "${movementFile.terminal}", 
                      headerRow: ${movementFile.headerRow}, 
                      columns: $columns
                    }
                ){
                  id
                }
            }
          `,
          variables: {
            columns: movementFile.columns
          }
        });
        return response.data.createMovementFile.id;
    }

    async createMovementLogic(movementLogic: MovementLogic): Promise<MovementLogic> {
      const CREATE_MOVEMENT_LOGIC = gql`
        mutation CreateMovementLogic($name: String!, $movementFileId: Int!, $description: String, $build: [BuildRuleInputDto!]!) {
          createMovementLogic(
            input: {
              name: $name,
              movementFileId: $movementFileId,
              description: $description,
              build: $build
            }
          ) {
            id
            name
            description
            build {
              header
              filter
              text
            }
            movementFileId
          }
        }
      `;
      
      const response = await client.mutate({
        mutation: CREATE_MOVEMENT_LOGIC,
        variables: {
          name: movementLogic.name,
          movementFileId: movementLogic.movementFileId,
          description: movementLogic.description,
          build: movementLogic.build
        }
      });
      
      return response.data.createMovementLogic;
    }

    async getMovementFiles(): Promise<MovementFile[]> {
        const response = await client.query({
          query: gql` 
            query {
              movementFiles{
                id
                name
                description
                type
                headerRow
              }
            }
          `,
        });
        return response.data.movementFiles;
    }

    async getMovementLogic(id: number): Promise<MovementLogic[]> {
        const response = await client.query({
          query: gql`
            query {
              movementLogics(id: ${id}){
                id
                name
                description
                build{
                  header
                  filter
                  text
                }
                movementFileId
              }
            }
          `,
        });
        return response.data.movementLogics;
    }

    async deleteMovementFile(id: number): Promise<boolean> {
        const response = await client.mutate({
          mutation: gql`
            mutation {
              removeMovementFile(id: ${id})
            }
          `,
        });
        return response.data.removeMovementFile;
    }

    async deleteMovementLogic(id: number): Promise<boolean> {
        const response = await client.mutate({
          mutation: gql`
            mutation {
              removeMovementLogic(id: ${id})
            }
          `,
        });
        return response.data.removeMovementLogic;
    }

    async getMovementErrors(): Promise<{ id: number; filename: string; date: Date; }[]> {
      const response = await client.query({
        query: gql` 
          query {
            movementErrors{
              id
              filename
              date
            }
          }
        `,
      });
      return response.data.movementErrors;
  }
}