
import { IsDate, IsInt, IsNotEmpty, IsOptional } from 'class-validator';
import { CustomBooleanField, CustomFloatField, CustomIntField, CustomStringField } from '../general/customValidators';
import { Type } from 'class-transformer';

export class CreateBookingContainerBooking {

    @CustomStringField()
    containerNumber?: string;

    @CustomStringField()
    size?: string|null;
    
    @CustomStringField()
    type?: string|null;
    
    @CustomStringField()
    iso?: string;
    
    @CustomIntField()
    freeDays?: number;
    
    @CustomFloatField()
    weight?: number;
    
    @CustomFloatField()
    vgmNumber?: number;
    
    @CustomFloatField()
    volume?: number;
    
    @CustomStringField()
    sealNumber?: string;
    
    @CustomFloatField()
    packageNumber?: number;
    
    @CustomStringField()
    mrnNumber?: string;
    
    @CustomStringField()
    riNumber?: string;
    
    @CustomStringField()
    customsNumber?: string;
    
    @CustomStringField()
    arrivalCarNumber?: string;
    
    @CustomStringField()
    departureCarNumber?: string;
    
    @CustomStringField()
    reeferTemp?: string;
    
    @CustomStringField()
    imoDetails?: string;
    
    @CustomStringField()
    oogDims?: string;
    
    @CustomStringField()
    remarks?: string;

    @CustomBooleanField()
    dangerCargo?: boolean;

    @CustomBooleanField()
    soc?: boolean;

    @CustomIntField()
    bookingId?: number;

    @CustomFloatField()
    estVgm?: number;

    @CustomStringField()
    shipperName?: string;

    @CustomStringField()
    consigneeName?: string;

    @CustomStringField()
    loadingPlace?: string;

    @CustomStringField()
    note?: string;

    @CustomStringField()
    commodity?: string;

    @CustomStringField()
    hsCode?: string;

    @CustomStringField()
    noticeStatus?: string;

    @CustomBooleanField()
    isRail?: boolean;

    @CustomStringField()
    fullIn?: string;
    
    @IsOptional()
    @IsDate()
    @Type(() => Date)
    pDate?: Date|null;

    @IsOptional()
    @IsDate()
    @Type(() => Date)
    aDate?: Date|null;

    @CustomIntField()
    detention?: number;

    @CustomStringField()
    shippingCompany?: string;

    @CustomStringField()
    car?: string;
}

export type BookingContainerCreateForm = InstanceType<typeof CreateBookingContainerBooking>;

export class UpdateBookingContainerBooking extends CreateBookingContainerBooking {
    @IsInt()
    @IsNotEmpty()
    id: number;
}

export type BookingContainerUpdateForm = InstanceType<typeof UpdateBookingContainerBooking>;