import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Button, Tooltip } from "flowbite-react";
import { Vessel, VesselServiceLoop } from '../../../interfaces/Vessel';
import { VesselService } from '../../../services/VesselService';
import renderSkeleton from '../../../components/SkeletonLoader';
import ModalServiceLoop from './ModalServiceLoop';
import DeleteModal from '../../../components/DeleteModal';
import { ColDef } from 'ag-grid-community';
import Actions from '../../../components/Actions';
import { AG_GRID_LOCALE_RO } from '../../../functions/langHelper';
import { AgGridReact } from 'ag-grid-react';

const IndexServiceLoop: React.FC = () => {
  const [services, setServices] = useState<VesselServiceLoop[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [idToDelete, setIdToDelete] = useState<number>(0);
  const [showModalServiceLoop, setShowModalServiceLoop] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const moduleService = new VesselService();
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const onSave = (serviceLoop : VesselServiceLoop, type : string) => {
    if(type === 'create'){
        setServices([...services, serviceLoop]);
    }else{
        setServices(services.map(service => service.id === serviceLoop.id ? serviceLoop : service));
    }
  }

  const fetchData = useCallback(
    async () => {
        setLoading(true);
        moduleService.getServiceLoops().then((data) => { setServices(data); setLoading(false)}).catch(() => {setLoading(false)});
  },[])

  const confirmDelete = async () => {
    if (idToDelete !== 0) {
      await moduleService.deleteServiceLoop(idToDelete);
      setShowModalServiceLoop(false);
      setServices(services.filter(service => service .id !== idToDelete));
      setIdToDelete(0);
    }
  };
  
  const onEdit = async (id: number) => {
    setIdToDelete(id);
  };

  const handleDelete = (id: number) => {
    setIdToDelete(id);
    setShowModalDelete(true);
  };

    const columns: ColDef<VesselServiceLoop>[] = useMemo(() => [
        { headerName: 'Id', field: 'id', sortable: true, filter: 'agNumberColumnFilter', floatingFilter: true, width: 110, maxWidth: 110},
        { headerName: 'Denumire', field: 'name', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true},
        { headerName: 'Import bound', field: 'firstBound', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true, maxWidth: 120 },
        { headerName: 'Export bound', field: 'secondBound', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true, maxWidth: 120 },
        { headerName: 'Port', field: 'port', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true},
        {
            headerName: 'Acțiuni',
            width: 150, 
            maxWidth: 150,
            field: 'id',
            cellRenderer: (params: any) => (
            <div>
                <Actions 
                    id={params.data.id} 
                    onEdit={() => onEdit(params.data.id)} 
                    onDelete={() => handleDelete(params.data.id)} 
                    moduleName="serviciu"
                />
            </div>
            )
        }
    ], []);
  
  return (
    <div className="ag-theme-quartz px-4">
        <div className="flex justify-between items-center py-2">
            <div className="text-lg font-bold">
                Nave
            </div>
            <div className="flex gap-2">
              <Tooltip content="Înapoi">
                  <Button size="sm" onClick={() => { navigate('/bookings/vessels/index'); }}>Înapoi</Button>
              </Tooltip>
              <Tooltip content="Adaugă serviciu">
                  <Button size="sm" onClick={() => { setShowModalServiceLoop(true); setIdToDelete(0); }}>Adaugă serviciu</Button>
              </Tooltip>
            </div>
        </div>
        {loading ? ( renderSkeleton() ) : (
            <>
                <AgGridReact 
                    localeText={AG_GRID_LOCALE_RO}
                    columnDefs={columns} 
                    rowData={services}
                    defaultColDef={{ flex: 1, minWidth: 100 }}
                    domLayout='autoHeight'
                    pagination={false}
                    gridOptions={{rowHeight: 35}}
                />
            </>
        )}
        <DeleteModal isOpen={showModalDelete} onClose={() => {setShowModalDelete(false)}} onConfirm={confirmDelete} deleteText='Sunteți sigur că doriți să eliminați acest serviciu?' />
        <ModalServiceLoop showModal={showModalServiceLoop} setShowModal={() => {setShowModalServiceLoop(false)}} onSave={onSave} id={idToDelete} />
    </div>
  );
};

export default IndexServiceLoop;