import { GridApi } from "ag-grid-community";

const useGridState = (gridId: string) => {
  const saveState = (api: any) => {
    try {
      const state = {
        columnState: api.getColumnState(),
        filterModel: api.getFilterModel(),
        // Verificăm care metodă este disponibilă
        sortModel: api.getSortModel?.() || api.getServerSideSortModel?.() || [],
        // Adăugăm starea paginării
        paginationState: {
          pageSize: api.paginationGetPageSize()
        }
      };
      localStorage.setItem(`agGrid-${gridId}`, JSON.stringify(state));
    } catch (error) {
      console.error('Error saving grid state:', error);
    }
  };

  const restoreState = (api: any) => {
    try {
      const savedState = localStorage.getItem(`agGrid-${gridId}`);
      if (savedState) {
        const state = JSON.parse(savedState);
        
        // Restaurăm starea coloanelor
        api.applyColumnState({
          state: state.columnState,
          applyOrder: true
        });

        // Restaurăm filtrele
        if (state.filterModel) {
          api.setFilterModel(state.filterModel);
        }

        // Restaurăm sortarea
        if (state.sortModel && state.sortModel.length > 0) {
          if (api.setSortModel) {
            api.setSortModel(state.sortModel);
          } else if (api.setServerSideSortModel) {
            api.setServerSideSortModel(state.sortModel);
          }
        }
        
        // Restaurăm paginarea
        if (state.paginationState) {
          if (state.paginationState.pageSize) {
            api.setGridOption('paginationPageSize', state.paginationState.pageSize);
          }

        }
      }
    } catch (error) {
      console.error('Error restoring grid state:', error);
    }
  };

  return { saveState, restoreState };
};

export default useGridState;