import { formatDateForBackend } from '../functions/dateHelper';
import client from '../apollo-client';
import { gql } from '@apollo/client';
import { FileService } from './FileService';
import { Movement } from '../interfaces/Movement';
import { Booking } from '../interfaces/Booking';

const API_URL = process.env.REACT_APP_GRAPHQL_URI!;

export class MovementService {
    async uploadCSV(file: File, movementFile : number): Promise<{status: string; message: string;}> {
        const fileService = new FileService();
        const fileResponse = await fileService.uploadFile(file);
        if(fileResponse.status === 'success'){
            try {
                const response = await client.mutate({
                    mutation: gql`
                        mutation {
                            uploadCSV(filename: "${fileResponse.filename}", type: ${movementFile}){
                                status
                                message
                            }
                        }
                    `,        
                });
                return response.data.uploadCSV;
            } catch (error) {
                return { status: 'error', message: 'Ceva nu a functionat va rugam incercati din nou.' };
            }
        }else{
            return { status: 'error', message: 'Din pacate am intampinat o eroare la incarcarea fisierului.' };
        }
    }

    async getMovements(): Promise<Movement[]> {
        const response = await client.query({
          query: gql` 
            query {
              movements{
                id
                movement
                terminal
                containerCode
                dischargePort
                loadingPort
                vesselName
                vesselCode
                date
                dateSystem
                action
                bookingId
                createdAtSystem
                isReportedWithin12Hours
                note
                port
                isLastMovement
                container{
                    size
                    type
                    iso
                }
                booking{
                    number
                    shippingType
                }
              }
            }
          `,
        });
        return response.data.movements.map((movement : Movement) => ({
            ...movement,
            date: new Date(movement.date),
            dateSystem: movement.dateSystem ? new Date(movement.dateSystem) : null,
            size: movement.container ? movement.container.size : '',
            type: movement.container ? movement.container.type : '',
            bookingNumber: movement.booking ? movement.booking.number : '',
        }));
    }

    async createMovement(movement: Movement): Promise<{status: string; message: string;}> {
        const response = await client.mutate({
          mutation: gql`
            mutation {
                createMovement(
                  input: { 
                      movement: "${movement.movement}", 
                      containerCode: "${movement.containerCode}", 
                      bookingId: ${movement.bookingId ? movement.bookingId : null}, 
                      port: ${movement.port ? `"${movement.port}"` : null},
                      terminal: ${movement.terminal ? `"${movement.terminal}"` : null},
                      size: ${movement.size ? `"${movement.size}"` : null},
                      type: ${movement.type ? `"${movement.type}"` : null},
                      iso: ${movement.iso ? `"${movement.iso}"` : null},
                      note: ${movement.note ? `"${movement.note}"` : null},
                      vesselCode: ${movement.vesselCode ? `"${movement.vesselCode}"` : null},
                      vesselName: ${movement.vesselName ? `"${movement.vesselName}"` : null},
                      date: "${formatDateForBackend(movement.date)}",
                      dateSystem: ${movement.dateSystem ? `"${formatDateForBackend(movement.dateSystem)}"` : null},
                    }
                ){
                  status
                  message
                }
            }
          `,
        });
        return response.data.createMovement;
    }

    //delete
    async deleteMovement(id: number): Promise<boolean> {
        const response = await client.mutate({
          mutation: gql`
            mutation {
                deleteMovement(id: ${id})
            }
          `,
        });
        return response.data.deleteMovement;
    }

    async changeAction(id: number): Promise<Partial<Movement>> {
        const response = await client.mutate({
          mutation: gql`
            mutation {
                changeAction(id: ${id}){
                    id
                }
            }
          `,
        });
        return response.data.changeAction;
    }
    
    async getBookings(): Promise<Partial<Booking>[]>{
        const response = await client.query({
            query: gql`
                query {
                    bookings {
                        id
                        number
                    }
                }
            `,
        });
        return response.data.bookings;
    }

    async getContainerInfo(containerCode: string): Promise<{movement?: string|null; bookingId?: number|null; type?: string|null; iso?: string|null; size?: string|null; terminal?: string|null;} | null> {
        try {
            const response = await client.query({
                query: gql`
                    query {
                        containerInfo(containerCode: "${containerCode}"){
                            movement
                            terminal
                            bookingId
                            type
                            iso
                            size
                        }
                    }
                `,
            });
            return response.data.containerInfo;
        } catch (error) {
            return null;
        }
    }

    async removeMovements(ids : number[]): Promise<{ status: string; message: string }> {
        const response = await client.mutate({
            mutation: gql`
                mutation removeMovements($ids: [Int!]!) {
                    removeMovements(ids: $ids) {
                        status
                        message
                    }
                }
            `,
            variables: {
                ids
            }
        });
        return response.data.removeMovements;
    }
}