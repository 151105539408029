import React, { useMemo, useState, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { ColDef, TextMatcherParams, GridReadyEvent, RowSelectionOptions, SelectionChangedEvent } from 'ag-grid-community';
import { AG_GRID_LOCALE_RO } from '../../../functions/langHelper';
import "ag-grid-charts-enterprise";
import { BookingBillTable } from '../../../interfaces/Booking';
import { createDateFromString, formatDate } from '../../../functions/dateHelper';
import { NavLink } from 'react-router-dom';
import { Dropdown } from 'flowbite-react';
import ArrivalNoticeModal from '../components/ArrivalNoticeModal';
import ViewBillModal from '../components/ViewBillModal';
import useGridState from '../../../functions/useGridState';

interface BookingTableProps {
  bills: Partial<BookingBillTable>[];
}

const Table: React.FC<BookingTableProps> = ({ bills }) => {
  const [viewId, setViewId] = useState<number>(0);
  const [showViewModal, setShowViewModal] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const pagination = true;
  const paginationPageSize = 20;
  const paginationPageSizeSelector = [10, 20, 50, 100];
  const { saveState, restoreState } = useGridState('bills-grid');

  const onGridReady = useCallback((event: GridReadyEvent) => {
    const api = event.api;
    event.api.closeToolPanel();
    
    // Restaurăm starea salvată
    const savedState = localStorage.getItem(`agGrid-bills-grid`);
    if (savedState) {
      // Dacă există state salvat, îl restaurăm
      restoreState(api);
    } else {
        // Configurarea implicita a gridului
    }
    // Adăugăm event listeners pentru salvarea stării
    const saveCurrentState = () => saveState(api);
    
    api.addEventListener('filterChanged', saveCurrentState);
    api.addEventListener('sortChanged', saveCurrentState);
    api.addEventListener('columnMoved', saveCurrentState);
    api.addEventListener('columnResized', saveCurrentState);
    api.addEventListener('paginationChanged', saveCurrentState);
    
    // Optional: Cleanup function
    return () => {
      api.removeEventListener('filterChanged', saveCurrentState);
      api.removeEventListener('sortChanged', saveCurrentState);
      api.removeEventListener('columnMoved', saveCurrentState);
      api.removeEventListener('columnResized', saveCurrentState);
      api.removeEventListener('paginationChanged', saveCurrentState);
    };
  }, [saveState, restoreState]);

  const onSelectionChanged = (event: SelectionChangedEvent) => {
    var rowCount = event.api.getSelectedNodes();
    setSelectedRows(rowCount.map(item => item.data.id));
  }

  const gridOptions = {
    rowHeight: 35,
    defaultColDef: {
      enableRowGroup: true,
    },
    rowSelection: {
      mode: 'multiRow',
      selectAll: 'filtered',
      enableClickSelection: false,
    } as RowSelectionOptions,
    alwaysMultiSort: true,
    onSelectionChanged,
  };
  
  const [showArrivalNoticeModal, setShowArrivalNoticeModal] = useState<boolean>(false);
  const [documentType, setDocumentType] = useState<string>('');

  const columns = useMemo<ColDef<Partial<BookingBillTable>>[]>(() => [
    { 
        headerName: 'B/L', 
        field: 'code', 
        sortable: true, 
        filter: 'agTextColumnFilter', 
        floatingFilter: true, 
        minWidth: 160,
        cellRenderer: (params: any) => (
          <a onClick={() => {setViewId(params.data.id); setShowViewModal(true); }} className="font-medium cursor-pointer text-blue-600 dark:text-blue-500 hover:underline">{params.value}</a>
        )
    },
    { 
      headerName: 'Booking',  
      field: 'booking.number', 
      sortable: true, 
      filter: 'agTextColumnFilter', 
      floatingFilter: true,
      minWidth: 140,
      cellRenderer: (params: any) => (
        <NavLink to={`/bookings/view/${params.data.booking.id}`} className="font-medium cursor-pointer text-blue-600 dark:text-blue-500 hover:underline">{params.value}</NavLink>
      )
    },
    { 
        headerName: 'Consignee', 
        field: 'consigneeName', 
        sortable: true, 
        minWidth: 200,
        filter: 'agTextColumnFilter', 
        floatingFilter: true,
        enableRowGroup: true
    },
    { 
        headerName: 'Navă(ROCND)', 
        field: 'booking.lastVesselCode', 
        sortable: true, 
        minWidth: 140,
        filter: 'agTextColumnFilter', 
        floatingFilter: true,
        enableRowGroup: true
    },
    { 
        headerName: 'Navă(MV)', 
        field: 'booking.motherVesselCode', 
        sortable: true, 
        minWidth: 140,
        filter: 'agTextColumnFilter', 
        floatingFilter: true,
        enableRowGroup: true
    },
    { 
        headerName: 'Shipper', 
        field: 'shipperName', 
        sortable: true, 
        minWidth: 200,
        filter: 'agTextColumnFilter', 
        floatingFilter: true,
        enableRowGroup: true
    },
    { 
        headerName: 'Notify', 
        field: 'notifyName', 
        sortable: true, 
        minWidth: 160,
        filter: 'agTextColumnFilter',   
        floatingFilter: true,
    },
    { 
      headerName: 'Cont.', 
      type: 'number',
      sortable: true, 
      minWidth: 100,
      filter: 'agNumberColumnFilter', 
      floatingFilter: true, 
      valueFormatter: (params: any) => {
        const uniqueContainerCodes = new Set(
          params.data.bookingContainers.map((container: any) => container.containerNumber)
        );
        return uniqueContainerCodes.size.toString();
      },
      valueGetter: (params: any) => {
        const uniqueContainerCodes = new Set(
          params.data.bookingContainers.map((container: any) => container.containerNumber)
        );
        return uniqueContainerCodes.size;
      },
    },
    { 
      headerName: 'Terminal', 
      field: 'terminal', 
      sortable: true, 
      minWidth: 100,
      filter: 'agTextColumnFilter', 
      floatingFilter: true,
      enableRowGroup: true
    },
    { 
      headerName: 'Sales', 
      field: 'booking.user.name', 
      sortable: true, 
      filter: 'agSetColumnFilter', 
      floatingFilter: true,
      enableRowGroup: true,
      minWidth: 170
    },
    {
      headerName: 'Dată',
      field: 'date',
      sort: 'desc',
      sortable: true,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: {
          filterOptions: ['contains', 'notContains', 'equals', 'notEqual'],
          textMatcher: (params: TextMatcherParams) => {
              if(params.filterText === null || params.filterText.trim() === ''){
                  return false;
              }
              const dateString = formatDate(createDateFromString(params.value));
              if(dateString==null){
                  return false;
              }
              switch (params.filterOption) {
                  case 'contains':
                      return dateString.indexOf(params.filterText) >= 0;
                  case 'notContains':
                      return dateString.indexOf(params.filterText) < 0;
                  case 'equals':
                      return dateString === params.filterText;
                  case 'notEqual':
                      return dateString != params.filterText;
                  case 'startsWith':
                      return dateString.indexOf(params.filterText) === 0;
                  case 'endsWith':
                      const index = dateString.lastIndexOf(params.filterText);
                      return index >= 0 && index === (dateString.length - params.filterText.length);
                  default:
                      return false;
              }
          }
      },
      minWidth: 100,
      cellRenderer: (params : any) => <>{formatDate(createDateFromString(params.value))}</>,
      comparator: (valueA, valueB) => {
          const dateA = createDateFromString(valueA)?.getTime();
          const dateB = createDateFromString(valueB)?.getTime();
          return dateA && dateB ? dateA - dateB : 0;
      },
    },
  ], []);

  return (
    <>
      {selectedRows.length ? (
        <div className="block mb-2 butonCuMargineMinus">
          <Dropdown label="Trimite selecții" size="sm">
            <Dropdown.Item onClick={() => {setShowArrivalNoticeModal(true); setDocumentType('arrivalNotice')}}>Preavizare</Dropdown.Item>
            <Dropdown.Item onClick={() => {setShowArrivalNoticeModal(true); setDocumentType('arrivalUpdate')}}>Avizare</Dropdown.Item>
            <Dropdown.Item onClick={() => {setShowArrivalNoticeModal(true); setDocumentType('deliveryOrder')}}>Liber de vamă</Dropdown.Item>
          </Dropdown>
        </div>
      ) : <></>}
      <AgGridReact 
        className="tabel-agGrid"
        localeText={AG_GRID_LOCALE_RO}
        columnDefs={columns} 
        rowData={bills}
        defaultColDef={{ flex: 1, minWidth: 20 }}
        domLayout='autoHeight'
        pagination={pagination}
        paginationPageSize={paginationPageSize}
        paginationPageSizeSelector={paginationPageSizeSelector}
        gridOptions={gridOptions}
        enableCharts={true}
        enableRangeSelection={true}
        sideBar={true}
        onGridReady={onGridReady}
        rowGroupPanelShow="always"
        groupDisplayType="groupRows"
      />
      <ViewBillModal id={viewId} showModal={showViewModal} setShowModal={setShowViewModal} />
      <ArrivalNoticeModal setDocumentType={setDocumentType} documentType={documentType} type="bill" ids={selectedRows} showModal={showArrivalNoticeModal} setShowModal={setShowArrivalNoticeModal} />
    </>
  );
};

export default Table;