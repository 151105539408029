import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, IRichCellEditorParams, ValueSetterParams } from 'ag-grid-community';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { defaultContainer } from '../../../../interfaces/Booking';
import Actions from '../../../../components/Actions';
import { AG_GRID_LOCALE_RO } from '../../../../functions/langHelper';
import { errorMessage, successMessage } from '../../../../functions/generalHelper';
import { BookingUpdateForm } from '../../../../validations/booking/CreateBooking';
import { UpdateBookingContainerBooking } from '../../../../validations/booking/CreateBookingContainerBooking';
import { columns as tableColumns } from '../../../containers/constants/columns';
import { ContainerService } from '../../../../services/ContainerService';

interface ContainersTableProps {
  booking: Partial<BookingUpdateForm>;
  setBooking: React.Dispatch<React.SetStateAction<Partial<BookingUpdateForm>>>;
  isEdit: number;
  setIsEdit: React.Dispatch<React.SetStateAction<number>>;
  setNewContainer: React.Dispatch<React.SetStateAction<UpdateBookingContainerBooking>>;
}

const ContainersTable: React.FC<ContainersTableProps> = ({ booking, setBooking, isEdit, setIsEdit, setNewContainer }) => {
  const containerService = useMemo(() => new ContainerService(), []);
  
  const [tableKey, setTableKey] = useState(0);
  const handleEditContainer = (index: number) => {
      setIsEdit(index);
      setNewContainer(booking.containers![index]);
  };

  const handleDeleteContainer = useCallback((index: number) => {
    const updatedContainers = booking.containers!.filter((_, i) => i !== index);
    // use prev
    setBooking(prev => ({ ...prev, containers: updatedContainers }));
    if (index === isEdit) {
        setIsEdit(-1);
        setNewContainer(defaultContainer as UpdateBookingContainerBooking);
    }
  },[booking.containers]);


  const modificaCelula = async (params : any, coloana : string) => {
    // @ts-ignore
    const newVal = 
      (coloana == "vgmNumber" || coloana == "weight" || coloana == "volume" || coloana == "estVgm") ? (
        params.newValue === null || params.newValue === undefined || params.newValue === "" ? null : parseFloat(params.newValue)
      ) : 
      (coloana == "freeDays" || coloana == "packageNumber" || coloana == "detention") ? (
        params.newValue === null || params.newValue === undefined || params.newValue === "" ? null : parseInt(params.newValue)
      ) :
      params.newValue;

    if(Number.isNaN(newVal)){
      errorMessage('Valoarea introdusă nu este un numar valid!');
      return;
    }

    // nu modificam daca mai exista deja acest container in booking
    if(coloana === "containerNumber" && newVal !== "TBA"){
      if(booking.containers!.some(container => container.containerNumber === newVal)){
        errorMessage('Containerul există deja în booking!');
        return;
      }
    }

    const newPartialContainer = {[coloana] : newVal};
    if(coloana === "containerNumber"){
      if(params.newValue){
        if(params.newValue.length === 11){
          const existingCont = await containerService.getContainerByNumber(newVal)
          if(existingCont){
            newPartialContainer['size'] = existingCont.size;
            newPartialContainer['type'] = existingCont.type;
            newPartialContainer['iso'] = existingCont.iso;
          }
        }
      }
    }

    params.value = newVal;
    setBooking(prev => ({
      ...prev,
      containers: prev.containers!.map((container, index) =>
        index === params.node.rowIndex
          ? { ...container, ...newPartialContainer }
          : container
      )
    }));
    successMessage('Container editat cu succes!');
  }

  const actionsColumn = useMemo(() => ({
    headerName: 'Acțiuni',
    minWidth: 100,
    cellRenderer: (params: any) => (
      <>
        <Actions
          id={params.node.rowIndex}
          onEdit={() => handleEditContainer(params.node.rowIndex)}
          onDelete={() => handleDeleteContainer(params.node.rowIndex)}
          moduleName="container"
        />
      </>
    )
  }), [booking.containers, handleEditContainer, handleDeleteContainer]);

  const columns: ColDef<UpdateBookingContainerBooking>[] = useMemo(() => [
    ...tableColumns(modificaCelula, 'booking', actionsColumn)
  ], [modificaCelula, booking.containers, actionsColumn]);

  useEffect(() => {
    setTableKey(prevKey => prevKey + 1);
  }, [booking.containers!.length]);

  return (
    <div className="ag-theme-quartz tabel-ascunde-filtre tabelContainere">
        <AgGridReact 
          key={`tabel-${tableKey}`}
          localeText={AG_GRID_LOCALE_RO}
          columnDefs={columns}
          rowData={booking.containers}
          defaultColDef={{ flex: 1, minWidth: 2 }}
          domLayout='autoHeight'
          pagination={false}
          gridOptions={{rowHeight: 35}}
          enableRangeSelection={true}
          suppressClickEdit={true}
          stopEditingWhenCellsLoseFocus={true}
        />
    </div>
  );
};

export default ContainersTable;