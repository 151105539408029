import { BookingUpdateForm } from "../validations/booking/CreateBooking";
import { UpdateBookingBillBooking } from "../validations/booking/CreateBookingBillBooking";
import { UpdateBookingContainerBooking } from "../validations/booking/CreateBookingContainerBooking";
import { UpdateRoute } from "../validations/booking/CreateRoute";
import { Movement } from "./Movement";
import { User } from "./User";
import { 
  HiDocumentText, 
  HiCheck, 
  HiTruck,
  HiClipboardCheck, 
  HiX
} from 'react-icons/hi';
import { RiShip2Fill } from "react-icons/ri";

export interface ViewBooking extends Omit<BookingUpdateForm, 'containers'> {
  bls: string[];
  lastVesselCode?: string|null;
  motherVesselCode?: string|null;
  teuCount?: number;
  createdAt?: Date|null;
  updatedAt?: Date|null;
  cargoManifests: CargoManifest[];
  user?: Partial<User>|null;
  movements?: Movement[];
  client?: {id: number; company: string;};
  bookingBills: ViewBookingBill[];
  containers: ViewContainer[];
}

export interface ViewContainer extends UpdateBookingContainerBooking {
  bookingBillId?: number|null;
  bookingId?: number;
}
export interface ViewContainerWithBookingData extends ViewContainer {
  booking?: {
    id: number;
    number: string;
    email: string;
    lastVesselCode: string;
    motherVesselCode: string;
    creationDate?: Date|null;
  };
}

export interface ViewBookingBill extends UpdateBookingBillBooking {
  id: number;
  bookingId?: number;
  // Shipper
  shipperPhone?: string|null;
  shipperEmail?: string|null;

  // Consignee
  consigneePhone?: string|null;
  consigneeEmail?: string|null;

  // Notify
  notifyName?: string|null;
  notifyAddress?: string|null;
  notifyPhone?: string|null;
  notifyEmail?: string|null;

  // Alte date:
  date: Date|null;
  translatedCommodity?: string|null;
  shortCommodity?: string|null;
  containers?: Partial<Container>[];

}

export interface Booking {
  // General Tab
  id?: number|null;
  number: string;
  bookingEmail?: string|null;
  status: string;
  shippingType: string|null;
  loadType: string|null;
  bookingType: string|null;
  tradeId: string|null;
  shippingLineName: string|null; // doar daca tip == Forwarding
  svcTermOrigin: string|null;
  svcTermDestination: string|null;
  creationDate: Date | null;
  createdBy: number|null;
  blockStatus: string|null; // select
  waiting: string|null; // de adaugat recomandarile in backend
  cancelReason: string|null; // doar daca status == Cancelled
  cancelledDate: Date|null; // doar daca status == Cancelled
  blockStowage: string|null; // Blocarea incarcarii -> Detalii blocare + Select
  terminal?: string|null;
  pol?: string|null;
  pod?: string|null;
  avizareExport?: string|null;
  lastVesselCode?: string|null;
  motherVesselCode?: string|null;
  bls: string[];
  bookingBills: BookingBill[];

  // office: string; // de ascuns
  // chinaAgent: string; // de ascuns
  // via: string; // de ascuns
  // trade: string; // de ascuns
  // rated: boolean; // de ascuns

  // Client Tab
  clientId: number|null;
  offerId?: number|null;
  // contractId: number;

  // Shipper, Consignee, etc.
  shipperName?: string|null;
  shipperAddress?: string|null;
  consigneeName?: string|null;
  consigneeAddress?: string|null;
  loadingPlace?: string|null;
  note?: string|null;
  commodity?: string|null;
  hsCode?: string|null;

  // Forwarder
  forwarderName: string|null;
  forwarderTaxId: string|null;
  forwarderCountry: string|null;
  forwarderState: string|null;
  forwarderCity: string|null;
  forwarderStreet: string|null;
  forwarderAddress: string|null;
  forwarderZip: string|null;
  forwarderPhone: string|null;
  forwarderEmail: string|null;

  // Shipper
  shipperMdmName: string|null;
  shipperBillName: string|null;
  shipperTaxId: string|null;
  shipperCountry: string|null;
  shipperState: string|null;
  shipperCity: string|null;
  shipperStreet: string|null;
  shipperZip: string|null;
  shipperPhone: string|null;
  shipperEmail: string|null;

  // Consignee
  consigneeTaxId: string|null;
  consigneeCountry: string|null;
  consigneeState: string|null;
  consigneeCity: string|null;
  consigneeStreet: string|null;
  consigneeZip: string|null;
  consigneePhone: string|null;
  consigneeEmail: string|null;

  // Notify
  notifyName?: string|null;
  notifyAddress?: string|null;
  notifyPhone?: string|null;
  notifyEmail?: string|null;

  createdAt?: Date;
  updatedAt?: Date;
  teuCount?: number;
  twentyContainersCount?: number;
  fourtyContainersCount?: number;
  fourtyFiveContainersCount?: number;

  // Route Tab
  routes: Route[];
  // Containers Tab
  containers: Container[];
  // Cargo Manifest Tab
  cargoManifests: CargoManifest[];
  // User
  user?: Partial<User>|null;
  movements?: Movement[];
  client?: {id: number; company: string;};
}

export const defaultBooking: Partial<BookingUpdateForm> = {
  // General Tab
  number: '',
  status: 'Draft',
  shippingType: 'Export CIF',
  loadType: 'FCL',
  bookingType: 'Linie',
  shippingLineName: 'Yang Ming Line',
  creationDate: new Date(),
  cancelReason: '',
  cancelledDate: undefined,
  blockStatus: '',
  waiting: '',
  loadingPlace: '',
  pol: '',
  pod: '',
  hsCode: '',
  commodity: '',
  note: '',
  svcTermOrigin: 'CY(Container Yard)',
  svcTermDestination: 'CY(Container Yard)',
  terminal: 'SOCEP',

  // Shipper, Consignee, etc.
  shipperName: '',
  shipperAddress: '',
  consigneeName: '',
  consigneeAddress: '',

  clientId: undefined, // de adaugat
  offerId: undefined, // de adaugat
  createdBy: undefined,
  
  // Relations
  routes: [],
  bookingBills: [],
  containers: [],
};

export interface Route { 
  id: number | null;
  from: string | null;
  to: string | null;
  vesselName: string | null;
  vesselCode: string | null;
  loop: string | null;
  rocndVessel: boolean;
  etd: Date | null;
  atd: Date | null;
  eta: Date | null;
  ata: Date | null;
}

export const defaultRoute : Partial<UpdateRoute> = {
  from: '',
  to: '',
  vesselName: '',
  vesselCode: '',
  etd: null,
  atd: null,
  eta: null,
  ata: null
}

export interface Container {
  id?: number;
  containerNumber?: string|null;
  sealNumber?: string|null;
  bookingId?: number;
  tare: number;
  weight: number;
  volume: number;
  type: string|null;
  size: string|null;
  iso?: string|null;
  bookingBillId?: number|null;
  bl?: string|null;
  bls?: string[];
  bookingBill?: BookingBill;
  freeDays?: number;
  dangerCargo?: boolean;
  packageNumber?: number;
  vgmNumber?: number;
  active?: boolean;
  grossWeight?: number;
  mrnNumber?: string|null;
  riNumber?: string|null;
  customsNumber?: string|null;
  arrivalCarNumber?: string|null;
  departureCarNumber?: string|null;
  estVgm?: number;
  reeferTemp?: string|null;
  imoDetails?: string|null;
  oogDims?: string|null;
  category?: string|null;
  cargoType?: string|null;
  remarks?: string|null;
  soc?: boolean;
}

export interface BookingBill {
    id: number;
    code: string;
    bookingId?: number;
    // Shipper
    shipperName?: string|null;
    shipperAddress?: string|null;
    shipperPhone?: string|null;
    shipperEmail?: string|null;
  
    // Consignee
    consigneeName?: string|null;
    consigneeAddress?: string|null;
    consigneePhone?: string|null;
    consigneeEmail?: string|null;
  
    // Notify
    notifyName?: string|null;
    notifyAddress?: string|null;
    notifyPhone?: string|null;
    notifyEmail?: string|null;

    // Alte date:
    date: Date|null;
    terminal?: string|null;
    hsCode?: string|null;
    releaseType?: string|null;
    loadingPlace?: string|null;
    commodity?: string|null;
    translatedCommodity?: string|null;
    shortCommodity?: string|null;
    containers?: Partial<Container>[];
    booking?: Partial<Booking>;
}

export interface BookingBillTable extends BookingBill {
    // relatii
    bookingContainers: Partial<Container>[];
    cargoManifests: Partial<CargoManifest>[];
    booking: Partial<Booking>;
}

export const defaultBookingBill: Partial<UpdateBookingBillBooking> = {
  code: '',
  date: new Date(),
  terminal: 'SOCEP',
  releaseType: '',
  shipperName: '',
  shipperAddress: '',
  consigneeName: '',
  consigneeAddress: '',
  hsCode: '',
  loadingPlace: '',
  commodity: ''
};
  
export const defaultContainer: Partial<UpdateBookingContainerBooking> = {
  containerNumber: 'TBA',
  sealNumber: '',
  weight: 0,
  volume: 0,
  type: 'DC',
  size: '40',
  iso: '',
  freeDays: 14,
  dangerCargo: false,
  packageNumber: 0,
  soc: false,
  isRail: false
};

export const defaultFormContainer: ViewContainer = {
  id: 0,
  containerNumber: '',
  sealNumber: '',
  weight: 0,
  volume: 0,
  type: 'DC',
  size: '40',
  iso: '',
  freeDays: 14,
  dangerCargo: false,
  packageNumber: 0,
  soc: false
};

export interface CargoManifest {
  id: number;
  bookingContainerId?: number|null;
  bookingBillId: number|null;
  hsCode: string|null;
  packageNumber: number;
  packageType: string|null;
  weight: number;
  volume: number;
}

export const defaultCargoManifest: CargoManifest = {
  id: 0,
  bookingContainerId: null,
  bookingBillId: null,
  hsCode: '',
  packageNumber: 0,
  packageType: '',
  weight: 0,
  volume: 0,
};

export interface ArrivalNotice {
  type: string;
  documentType: string;
  vesselCode?: string|null;
  ids?: number[];
  ata: Date;
}
  
export const defaultArrivalNotice = (type : string, documentType: string) : ArrivalNotice => ({
  type,
  documentType,
  vesselCode: null,
  ids: [],
  ata: new Date()
});

export interface Manifest {
  id?: number;
  date: Date|null;
  status?: string;
  vesselName?: string;
  vesselCode?: string;
  terminal?: string;
  filepath?: string;
  pol: string;
  translatedFilepath?: string;
  content?: BillInterface[]; 
}
  
export const defaultManifest : Manifest = {
  date: new Date(),
  pol: 'TRIST',
  vesselName: '',
  vesselCode: '',
  terminal: 'CSCT',
}

export const bookingStatuses : {name:string;Icon:any}[] = [
  { name: 'Anulat', Icon: HiX },
  { name: 'Confirmat', Icon: HiCheck },
  { name: 'În tranzit(mother vessel)', Icon: RiShip2Fill },
  { name: 'În tranzit(marea neagră)', Icon: RiShip2Fill },
  { name: 'Preavizat', Icon: HiDocumentText },
  { name: 'Avizat', Icon: HiDocumentText },
  { name: 'Descărcat', Icon: HiClipboardCheck },
  { name: 'Eliberat de vamă', Icon: HiCheck },
  { name: 'În livrare', Icon: HiTruck },
  { name: 'Finalizat', Icon: HiClipboardCheck },
];

export const bookingStatusesExport : {name:string;Icon:any}[] = [
  { name: 'Anulat', Icon: HiX },
  { name: 'Creat', Icon: HiCheck },
  { name: 'Introducere VGM', Icon: HiDocumentText },
  { name: 'Încărcat', Icon: HiClipboardCheck },
  { name: 'În tranzit(marea neagră)', Icon: RiShip2Fill },
  { name: 'În tranzit(mother vessel)', Icon: RiShip2Fill },
  // { name: 'Avizat', Icon: HiDocumentText },
  // { name: 'Eliberat de vamă', Icon: HiCheck },
  // { name: 'În livrare', Icon: HiTruck },
  { name: 'Finalizat', Icon: HiClipboardCheck },
];

export const getNextStatusName = ( currentStatusName : string, type : string|null) => {
  const statuses = type === 'Export FOB' || 'Export CIF' ? bookingStatusesExport : bookingStatuses;
  const index = statuses.findIndex(status => status.name === currentStatusName);
  
  if (index === -1 || index === statuses.length - 1) {
    return null;
  }
  
  return statuses[index + 1].name;
}

export interface ContainerInterface {
  packNumber : string;
  containerNumber : string;
  seal : string;
  size : string;
  tare : number;
  weight : number;
  vol : number; 
}

export interface CommodityItemInterface {
  hsCode : string;
  packNumber : number;
  packType : string;
  weight : number;
  vol : number;
}

export interface BillInterface {
  id: number;
  containers: ContainerInterface[];
  commodityItems: CommodityItemInterface[];
  pages: number[];
  commodity: string;
  marfa: string;
  shortCommodity: string;
  billNumber: string;
  bookingNumber: string;
  pol: string;
  ata: string;
  atd: string;
  vesselName: string;
  vesselCode: string;
  shipperName: string;
  shipperAddress: string;
  consigneeName: string;
  consigneeAddress: string;
  notifyName: string;
  notifyAddress: string;
}

export interface BillCheck {
  id?: number;
  billNumber: string;
  vesselName?: string;
  vesselCode?: string;
  initialVesselCode?: string;
  status?: string;
  info?: string;
}

export const defaultBillCheck : BillCheck = {
  billNumber: '',
}

export interface BookingForEdit {
  booking: {
    // Câmpuri principale
    number?: string;
    status?: string;
    shippingType?: string;
    loadType?: string;
    bookingType?: string;
    shippingLineName?: string;
    creationDate?: string; // GraphQL returnează date ca string
    cancelReason?: string;
    cancelledDate?: string;
    blockStatus?: string;
    waiting?: string;
    loadingPlace?: string;
    pol?: string;
    pod?: string;
    hsCode?: string;
    commodity?: string;
    note?: string;
    svcTermOrigin?: string;
    svcTermDestination?: string;
    shipperName?: string;
    shipperAddress?: string;
    consigneeName?: string;
    consigneeAddress?: string;
    clientId?: number;
    offerId?: number;
    createdBy?: number;

    // Routes
    routes?: {
      from?: string;
      to?: string;
      vesselCode?: string;
      vesselName?: string;
      etd?: string;
      atd?: string;
      eta?: string;
      ata?: string;
    }[];

    // Bills
    bookingBills?: {
      code: string;
      date?: string;
      terminal?: string;
      releaseType?: string;
      shipperName?: string;
      shipperAddress?: string;
      consigneeName?: string;
      consigneeAddress?: string;
      hsCode?: string;
      loadingPlace?: string;
      commodity?: string;
    }[];

    // Containers
    containers?: {
      containerNumber?: string;
      size?: string;
      type?: string;
      bls?: string[];
      iso?: string;
      freeDays?: number;
      tare?: number;
      weight?: number;
      vgmNumber?: number;
      volume?: number;
      grossWeight?: number;
      sealNumber?: string;
      packageNumber?: number;
      mrnNumber?: string;
      riNumber?: string;
      customsNumber?: string;
      arrivalCarNumber?: string;
      departureCarNumber?: string;
      reeferTemp?: string;
      imoDetails?: string;
      oogDims?: string;
      category?: string;
      cargoType?: string;
      remarks?: string;
      dangerCargo?: boolean;
      soc?: boolean;
    }[];
  };
}

export interface EmailQueue {
  id: number;
  subject: string|null; //
  body: string|null;
  recipients?: string[]|null; //
  cc?: string[]|null;
  bcc?: string[]|null;
  replyTo?: string|null;
  from?: string|null;
  fromName?: string|null; //
  attachments?: {
    filename: string|null;
    path: string|null;
    contentType: string|null;
  }[];
  priority: EmailPriority;
  status: EmailStatus; //
  scheduledFor?: Date;
  retryCount: number;
  lastRetryAt?: Date;
  errorMessage?: string|null; //
  createdAt: Date;
  updatedAt: Date;
  sentAt?: Date; //
  isActive: boolean;
  maxRetries: number;
  reference?: string|null; // 
  type?: string|null;
}

export enum EmailPriority {
  LOW = 'low',
  NORMAL = 'normal',
  HIGH = 'high',
  URGENT = 'urgent',
}

export enum EmailStatus {
  PENDING = 'pending',
  PROCESSING = 'processing',
  SENT = 'sent',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
}

export interface BookingFile {
  id: number;
  bookingId: number;
  originalName: string;
  storedName: string;
  filePath: string;
  mimeType: string;
  fileSize: number;
  uploadDate: Date;
  uploadedBy?: number;
  description?: string;
  isActive: boolean;
  user?: {id: number; name: string};
}

export interface BookingHistory {
  id: number;
  bookingId: number;
  userId: number;
  date: Date;
  description: string;
  title: string;
  type: string;
  user: Partial<User>;
}

export interface ContainerSetting {
  id: number;
  number: string;
  type?: string;
  size?: string;
  iso?: string;
}

export const defaultContainerSetting: ContainerSetting = {
  id: 0,
  number: '',
  type: '20',
  size: 'DC',
  iso: '22G1',
}