import React, { useState, useEffect, useCallback } from 'react';
import { ViewContainerWithBookingData } from '../../interfaces/Booking';
import renderSkeleton from '../../components/SkeletonLoader';
import Table from './Table';
import { ContainerService } from '../../services/ContainerService';

const Index: React.FC = () => {
  const [containers, setContainers] = useState<Partial<ViewContainerWithBookingData>[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const containerService = new ContainerService();

  useEffect(() => {
    fetchContainers();
  }, []);

  const fetchContainers = useCallback(
    async () => {
      setLoading(true);
      containerService.getContainers().then((data) => { setContainers(data); setLoading(false)}).catch(() => {setLoading(false)});
  },[])

  return (
    <div className="ag-theme-quartz px-4">
      <div className="flex justify-between items-center py-3">
      <div className="text-lg font-bold hidden md:block">
        Containere Export
      </div>
    </div>
      {loading ? ( renderSkeleton() ) : (
        <>
          <Table containers={containers} setContainers={setContainers} />
        </>
      )}
    </div>
  );
};

export default Index;