import { Bounce, toast } from "react-toastify";
import { LabelValueDate, LabelValueInt, LabelValueString, RecommendationResp } from "../interfaces/Recommandation";
import { UserService } from "../services/UserService";

export const errorMessage = (message: string, duration: number = 2000) => {
    toast.error(message, {
        position: "top-right",
        autoClose: duration,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        style: { whiteSpace: 'pre-line' }
    });
}

export const successMessage = (message : string, duration : number = 2000) => {
  toast.success(message, {
    position: "top-right",
    autoClose: duration,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
    style: { whiteSpace: 'pre-line' }
  });
  toast.clearWaitingQueue();
}

export const warningMessage = (message : string, duration : number = 4000) => {
  toast.warning(message, {
      position: "top-right",
      autoClose: duration,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
    });
}

export const handleOptions = (recommendations : RecommendationResp[], slug : string) : (LabelValueDate|LabelValueInt|LabelValueString)[] => {
  return recommendations.filter((recommendation) => recommendation.categorySlug === slug).map(
    (recommendation) => ({
      label: recommendation.label,
      value: recommendation.value
    })
  );
}

export const handleRecommendations = (recommendations : RecommendationResp[], slug : string) : string[] => {
  return recommendations.filter((recommendation) => recommendation.categorySlug === slug).map(
    (recommendation) => recommendation.value
  );
}

export const getUsersForSelect = async () : Promise<LabelValueInt[]> => {
  const userService = new UserService();
  return await userService.getUsers().then((data) => data.map(user => ({ value: user.id!, label: user.name! })));
}

export const isNullOrEmpty = (value: string | null | undefined): boolean => {
  return !value || value === undefined || value.toString().trim() === '';
}

export const removeTypename = <T extends { [key: string]: any }>(obj: T): Omit<T, '__typename'> => {
  const { __typename, ...rest } = obj as any;
  return rest;
}