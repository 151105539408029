import React, { useCallback, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridReadyEvent, ProcessCellForExportParams, RowSelectionOptions, SelectionChangedEvent } from 'ag-grid-community';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { ViewBooking } from '../../../../interfaces/Booking';
import { AG_GRID_LOCALE_RO } from '../../../../functions/langHelper';
import Actions from '../../../../components/Actions';
import { errorMessage, successMessage } from '../../../../functions/generalHelper';
import useGridState from '../../../../functions/useGridState';
import { UpdateBookingContainerBooking } from '../../../../validations/booking/CreateBookingContainerBooking';
import { columns as tableColumns } from '../../../containers/constants/columns';
import { ContainerService } from '../../../../services/ContainerService';
import { Button } from 'flowbite-react';
import ExportNoticeModal from './ExportNoticeModal';

interface ContainersExportTableProps {
  booking: ViewBooking;
  setBooking: React.Dispatch<React.SetStateAction<ViewBooking>>;
  onDelete: (id: number) => void;
  fetchBooking: () => void;
}

const ContainersExportTable: React.FC<ContainersExportTableProps> = ({ booking, onDelete, setBooking, fetchBooking }) => {
    const containerService = new ContainerService();
    const { saveState, restoreState } = useGridState('bookingContainers-import-grid');
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [showArrivalNoticeModal, setShowArrivalNoticeModal] = useState<boolean>(false);

    const onGridReady = useCallback((event: GridReadyEvent) => {
      const api = event.api;
      event.api.closeToolPanel();
      
      // Restaurăm starea salvată
      const savedState = localStorage.getItem(`agGrid-bookingContainers-import-grid`);
      if (savedState) {
        // Dacă există state salvat, îl restaurăm
        restoreState(api);
      }
      // Adăugăm event listeners pentru salvarea stării
      const saveCurrentState = () => saveState(api);
      
      api.addEventListener('filterChanged', saveCurrentState);
      api.addEventListener('sortChanged', saveCurrentState);
      api.addEventListener('columnMoved', saveCurrentState);
      api.addEventListener('columnResized', saveCurrentState);
      api.addEventListener('paginationChanged', saveCurrentState);

      // Optional: Cleanup function
      return () => {
        api.removeEventListener('filterChanged', saveCurrentState);
        api.removeEventListener('sortChanged', saveCurrentState);
        api.removeEventListener('columnMoved', saveCurrentState);
        api.removeEventListener('columnResized', saveCurrentState);
        api.removeEventListener('paginationChanged', saveCurrentState);
      };
    }, [saveState, restoreState]);

    const modificaCelula = async (params : any, coloana : string) => {
      try {
          const newValueUnchanged = params.newValue;
          if(coloana === "vgmNumber" || coloana === "weight" || coloana === "volume" || coloana === "estVgm"){
              if(params.newValue === null || params.newValue === undefined || params.newValue === ""){
                params.newValue = null;
              }else{
                params.newValue = parseFloat(params.newValue);
              }
          }
          if(coloana === "freeDays" || coloana === "packageNumber" || coloana === "detention"){
              if(params.newValue === null || params.newValue === undefined || params.newValue === ""){
                params.newValue = null;
              }else{
                params.newValue = parseInt(params.newValue);
              }
          }

          if(Number.isNaN(params.newValue)){
            errorMessage(`Valoarea ${newValueUnchanged} nu este un numar valid!`);
            return;
          }

          if(coloana === "aDate" || coloana === "pDate"){
            if(params.newValue === null){
              return;
            }
          }

          // Valideaza daca containerul exista deja in booking
          if(coloana === "containerNumber" && params.newValue !== "TBA"){
            if(booking.containers!.some(container => container.containerNumber === params.newValue)){
              errorMessage('Containerul există deja în booking!');
              return;
            }
          }
          
          const updatedContainer = {
            [coloana!]: params.newValue,
            id: params.data.id,
            bookingId: params.data.bookingId
          };

          try {
            // Actualizăm containerul în backend
            const savedContainer = await containerService.updateBookingContainer(updatedContainer);
            // Actualizăm state-ul local cu noul container

            const toUpdate = {[coloana!]: params.newValue};
            if(coloana === 'containerNumber'){
              toUpdate['size'] = savedContainer.size;
              toUpdate['type'] = savedContainer.type;
              toUpdate['iso'] = savedContainer.iso;
            }

            setBooking(prev => ({ 
              ...prev, 
              containers: prev.containers.map(cont => savedContainer.id === cont.id ? { ...cont, ...toUpdate } : cont) 
            }));
            // params.node.setData(savedContainer);
            successMessage('Container editat cu succes!');
          }catch(error : any){
              errorMessage(error.message || 'A aparut o eroare la editarea containerului!');
              return;
          }
      } catch (error : any) {
          errorMessage(error.message || 'A aparut o eroare la editarea containerului!');
      }
      
    }

    const actionsColumn : ColDef<UpdateBookingContainerBooking> = {
      headerName: 'Acțiuni',
      minWidth: 100,
      field: 'id',
      cellRenderer: (params: any) => (
        <div>
          <Actions 
            id={params.data.id} 
            onDelete={() => onDelete(params.data.id)} 
            moduleName="container"
          />
        </div>
      )
    };

    const columns: ColDef<UpdateBookingContainerBooking>[] = [
      ...tableColumns(modificaCelula, 'viewBooking', actionsColumn)
    ];
    
    const onSelectionChanged = (event: SelectionChangedEvent) => {
      var rowCount = event.api.getSelectedNodes();
      setSelectedRows(rowCount.map(item => item.data.id));
    }

    const processCellFromClipboard = (params: ProcessCellForExportParams) => {
      // if (params.column.getColId() === 'pDate' || params.column.getColId() === 'aDate') {
      //   return undefined;
      // }
      return params.value
    }

    const gridOptions = {
      rowHeight: 35,
      defaultColDef: {
        enableRowGroup: true,
      },
      rowSelection: {
        mode: 'multiRow',
        selectAll: 'filtered',
        enableClickSelection: false,
      } as RowSelectionOptions,
      alwaysMultiSort: true,
      onSelectionChanged,
    };

    const createNoticeModalDate = () => {
      setShowArrivalNoticeModal(true);
    }

    const clearNotice = () => {
      fetchBooking();
      setShowArrivalNoticeModal(false);
    }
      
    return (
      <>
        <ExportNoticeModal bookings={[{
          id: booking.id,
          number: booking.number,
          email: booking.bookingEmail || '',
          containers: booking.containers.filter(c => selectedRows.includes(c.id)).map(c => ({number: c.containerNumber || '', id: c.id, iso: c.iso, departureCarNumber: c.departureCarNumber, car: c.car, shipperName: c.loadingPlace, isRail: c.isRail}))
        }]} openModal={showArrivalNoticeModal} setOpenModal={setShowArrivalNoticeModal} onSendNotice={clearNotice} />
          {selectedRows.length ? (
            <div className="block mb-2 butonCuMargineMinusContainerInBooking">
              <Button size="sm" onClick={createNoticeModalDate}>Trimite avizare</Button>
            </div>
          ) : <></>}
          <div className="ag-theme-quartz tabelContainere">
            <AgGridReact 
              localeText={AG_GRID_LOCALE_RO}
              columnDefs={columns} 
              rowData={booking.containers}
              defaultColDef={{ flex: 1, minWidth: 20 }}
              domLayout='autoHeight'
              pagination={false}
              gridOptions={gridOptions}
              suppressClickEdit={true}
              stopEditingWhenCellsLoseFocus={true}
              onGridReady={onGridReady}
              enableCharts={true}
              sideBar={true}
              cellSelection={true}
            />
          </div>
      </>
    );
};

export default ContainersExportTable;