import React, { useState, useMemo } from 'react';
import { Button, Modal } from 'flowbite-react';
import CustomSelect from '../../../components/CustomSelect';
import DragDropFileUpload from '../../../components/DragDropFileUpload';
import { errorMessage, successMessage } from '../../../functions/generalHelper';
import { BookingService } from '../../../services/BookingService';
import Loader from '../../../components/CustomLoader';

interface ModalImportProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: () => void;
}

const ModalImport: React.FC<ModalImportProps> = ({ showModal, setShowModal, onSuccess }) => {
    const [file, setFile] = useState<File | null>(null);
    const [fileType, setFileType] = useState<string>('Import');
    const [loading, setLoading] = useState<boolean>(false);
    const bookingService = new BookingService();

    const fileOptions = useMemo(() => ([
        { label: 'Import', value: 'Import' },
        { label: 'Export', value: 'Export' },
    ]), []);

    // useEffect(() => {
    // }, [showModal]);
    
    const handleFileChange = (file : File) => {
      setFile(file);
    };
  
    const handleSubmit = async (event: React.FormEvent) => {
      setLoading(true);
      event.preventDefault();
      if (!file){
        errorMessage('Fișierul nu a fost încărcat');
        return
      }
      const response : { status: string; message: string; } = await bookingService.importBookings(file, fileType);
      if(response.status == 'success'){
        successMessage(response.message);
        setFile(null);
        setFileType('Import');
        setShowModal(false);
        onSuccess();
      }else{
        errorMessage(response.message, 8000);
        setFile(null);
        setFileType('Import');
        setShowModal(false);
        onSuccess();
      }
      setLoading(false);
    };

    const formatFileSize = (size: number): string => {
      const units = ['B', 'KB', 'MB', 'GB', 'TB'];
      let unitIndex = 0;
      
      while (size >= 1024 && unitIndex < units.length - 1) {
        size /= 1024;
        unitIndex++;
      }
    
      return `${size.toFixed(2)} ${units[unitIndex]}`;
    };
  
    return (
      <>
        <Loader isLoading={loading} />
        <Modal show={showModal} size="xl" popup onClose={() => setShowModal(false)}>
          <Modal.Header>
              Importă date
          </Modal.Header>
          <Modal.Body className="overflow-visible">
              <div className="space-y-2 pt-5">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <CustomSelect
                          value={fileOptions.find(option => option.value === fileType)}
                          onChange={(data) => {setFileType(data.value)}}
                          options={fileOptions}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Tip de fișier"
                      />
                    </div>
                    <div className="mb-3">
                      <DragDropFileUpload onFileUpload={handleFileChange} />
                      { file ? <>
                        <h3 className="mb-3 mt-3 text-md font-semibold text-dark dark:text-white">
                          Fișiere încărcate:
                        </h3>
                        <div className="flex items-center justify-between overflow-hidden rounded-lg border border-stroke p-3 pr-5 dark:border-dark-3">
                          <div className="flex items-center gap-3">
                            <div className="flex h-[52px] w-[50px] items-center justify-center rounded-md bg-primary text-white">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M18.5624 0.674988H5.4374C4.1624 0.674988 3.1499 1.68749 3.1499 2.96249V15C3.1499 15.5625 3.3749 16.0875 3.7874 16.4625L10.5374 22.8375C10.9124 23.2125 11.3999 23.4 11.9249 23.4H18.5249C19.7999 23.4 20.8124 22.3875 20.8124 21.1125V2.96249C20.8499 1.68749 19.7999 0.674988 18.5624 0.674988ZM6.0749 16.275H10.4624C10.6499 16.275 10.7999 16.425 10.7999 16.575V20.7L6.0749 16.275ZM19.1624 21.0375C19.1624 21.375 18.8999 21.6375 18.5624 21.6375H12.4874V16.575C12.4874 15.4875 11.5874 14.5875 10.4624 14.5875H4.8374V2.96249C4.8374 2.62499 5.0999 2.36249 5.4374 2.36249H18.5249C18.8624 2.36249 19.1249 2.62499 19.1249 2.96249V21.0375H19.1624Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div>
                              <p className="text-base text-dark dark:text-white">
                                {file.name}
                              </p>
                              <p className="text-base text-body-color dark:text-dark-6">
                                {formatFileSize(file.size)}
                              </p>
                            </div>
                          </div>
                          <div>
                            <button onClick={() => { setFile(null) }} className="flex items-center justify-center gap-2 rounded-lg border border-stroke px-3 py-2 text-sm font-medium text-dark-4 duration-200 hover:bg-dark hover:text-red-600 hover:border-red-600 dark:border-dark-3 dark:text-dark-6">
                              Șterge fișier
                              <span>
                                <svg
                                  width="12"
                                  height="12"
                                  viewBox="0 0 12 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clip-path="url(#clip0_2330_10311)">
                                    <path
                                      d="M6.5998 5.99999L11.1748 1.42499C11.3436 1.25624 11.3436 0.993738 11.1748 0.824988C11.0061 0.656238 10.7436 0.656238 10.5748 0.824988L5.9998 5.39999L1.4248 0.824988C1.25605 0.656238 0.993555 0.656238 0.824805 0.824988C0.656055 0.993738 0.656055 1.25624 0.824805 1.42499L5.3998 5.99999L0.824805 10.575C0.656055 10.7437 0.656055 11.0062 0.824805 11.175C0.899805 11.25 1.0123 11.3062 1.1248 11.3062C1.2373 11.3062 1.3498 11.2687 1.4248 11.175L5.9998 6.59999L10.5748 11.175C10.6498 11.25 10.7623 11.3062 10.8748 11.3062C10.9873 11.3062 11.0998 11.2687 11.1748 11.175C11.3436 11.0062 11.3436 10.7437 11.1748 10.575L6.5998 5.99999Z"
                                      fill="currentColor"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_2330_10311">
                                      <rect width="12" height="12" fill="white" />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </span>
                            </button>
                          </div>
                        </div>
                      </> : <></>}
                    </div>
                    <div className="flex justify-end">
                      <Button size="sm" type="submit">Importă date</Button>
                    </div>
                  </form>
              </div>
          </Modal.Body>
      </Modal>
      </>
    );
};

export default ModalImport;