import client from '../apollo-client';
import { gql } from '@apollo/client';
import { ContainerSetting, ViewContainerWithBookingData } from '../interfaces/Booking';
import { UpdateBookingContainerBooking } from '../validations/booking/CreateBookingContainerBooking';
import { removeTypename } from '../functions/generalHelper';
import { formatDateForBackend } from '../functions/dateHelper';

const containerSelect = `
  id
  containerNumber
  size
  type
  iso
  freeDays
  weight
  vgmNumber
  volume
  sealNumber
  packageNumber
  mrnNumber
  riNumber
  customsNumber
  arrivalCarNumber
  departureCarNumber
  reeferTemp
  imoDetails
  oogDims
  remarks
  dangerCargo
  soc
  bookingId
  estVgm
  shipperName
  consigneeName
  loadingPlace
  note
  commodity
  hsCode
  noticeStatus
  isRail
  fullIn
  pDate
  aDate
  detention
  shippingCompany
  car
  booking{
    id
    number
    lastVesselCode
    motherVesselCode
    bookingEmail
    terminal
  }
`;

export class ContainerService {
  async getContainers(): Promise<ViewContainerWithBookingData[]> {
      const response = await client.query({
        query: gql`
          query {
            findAllExportContainers{
              ${containerSelect}
            }
          }
        `,
      });        
      return response.data.findAllExportContainers;
  }

  async updateBookingContainer(container: Partial<ViewContainerWithBookingData>): Promise<ViewContainerWithBookingData> {
      const newContainer = Object.fromEntries(
        Object.entries(container).filter(([key]) => new UpdateBookingContainerBooking().hasOwnProperty(key))
      );

      const updatedData = removeTypename(newContainer);
      if(updatedData.pDate){
        updatedData.pDate = updatedData.pDate ? formatDateForBackend(updatedData.pDate as Date) : null;
      }
      if(updatedData.aDate){
        updatedData.aDate = updatedData.aDate ? formatDateForBackend(updatedData.aDate as Date) : null;
      }

      const response = await client.mutate({
        mutation: gql`
          mutation updateContainerExport($input: UpdateBookingContainerBookingDto!) {
            updateContainerExport(input: $input) {
              ${containerSelect}
            }
          }
        `,
        variables: {
          input: updatedData,
        }
      });
      return response.data.updateContainerExport;
  }

  async updateBookingVessel(booking: { bookingId: number, vesselCode: string }): Promise<ViewContainerWithBookingData> {
    const response = await client.mutate({
      mutation: gql`
        mutation updateBookingVessel($input: UpdateBookingVesselDto!) {
          updateBookingVessel(input: $input) {
            id
          }
        }
      `,
      variables: {
        input: booking,
      }
    });
    return response.data.updateBookingVessel;
  }

  async updateBookingMotherVessel(booking: { bookingId: number, vesselCode: string }): Promise<ViewContainerWithBookingData> {
    const response = await client.mutate({
      mutation: gql`
        mutation updateBookingMotherVessel($input: UpdateBookingVesselDto!) {
          updateBookingMotherVessel(input: $input) {
            id
          }
        }
      `,
      variables: {
        input: booking,
      }
    });
    return response.data.updateBookingMotherVessel;
  }

  async updateBookingTerminal(booking: { bookingId: number, terminal: string }): Promise<ViewContainerWithBookingData> {
    const response = await client.mutate({
      mutation: gql`
        mutation updateBookingTerminal($input: UpdateBookingTerminalDto!) {
          updateBookingTerminal(input: $input)
        }
      `,
      variables: {
        input: booking,
      }
    });
    return response.data.updateBookingTerminal;
  }

  async sendExportNotice(bookings : { bookingId: number, email: string, containers: number[] }[]) {
    const response = await client.mutate({
      mutation: gql`
        mutation sendExportNotice($input: [SendExportNoticeDto!]!) {
          sendExportNotice(input: $input){
            status
            message
          }
        }
      `,
      variables: {
        input: bookings,
      }
    });
    return response.data.sendExportNotice;
  }

  async generateLoadingListExcel(ids: number[], transShipmentPort: string) : Promise<{status: string; message: string;}>{
    const response = await client.mutate({
      mutation: gql`
        mutation generateLoadingListExcel($ids: [Int!]!, $transShipmentPort: String!) {
          generateLoadingListExcel(ids: $ids, transShipmentPort: $transShipmentPort){
            status
            message
          }
        }
      `,
      variables: {
        ids: ids,
        transShipmentPort
      }
    });
    return response.data.generateLoadingListExcel;
  }

  async getContainersForConfiguration(): Promise<ContainerSetting[]> {
    const response = await client.query({
      query: gql`
        query {
          containers{
            id
            number
            type
            size
            tare
            iso
          }
        }
      `,
    });
    return response.data.containers;
  }

  async updateContainer(container: Partial<ContainerSetting>) {
    const response = await client.mutate({
      mutation: gql`
        mutation updateContainer($input: UpdateContainerDto!) {
          updateContainer(input: $input){
            id
            iso
          }
        }
      `,
      variables: {
        input: container,
      }
    });
    return response.data.updateContainer;
  }

  async moveContainerToBooking(bookingId: number, newBookingId: number, number: string) {
    const response = await client.mutate({
      mutation: gql`
        mutation moveContainerToBooking($input: MoveContainerDto!) {
          moveContainerToBooking(input: $input){
            ${containerSelect}
          }
        }
      `,
      variables: {
        input: {
          bookingId,
          newBookingId,
          number,
        },
      }
    });
    return response.data.moveContainerToBooking;
  }

  async getExportBookings() {
    const response = await client.query({
      query: gql`
        query { 
          getExportBookings {
            id
            number
          }
        }
      `,
    });
    return response.data.getExportBookings;
  }

  async getContainerByNumber(number: string) {
    const response = await client.query({
      query: gql`
        query getContainerByNumber($number: String!) {
          getContainerByNumber(number: $number){
            size
            type
            iso
          }
        }
      `,
      variables: {
        number,
      }
    });
    return response.data.getContainerByNumber;
  }

  async getContainersByBookingId(bookingId: number) : Promise<ContainerData[]> {
    const response = await client.query({
      query: gql`
        query findAllExportContainersByBookingId($bookingId: Int!) {
          findAllExportContainersByBookingId(bookingId: $bookingId){
            id
            containerNumber
            noticeStatus
          }
        }
      `,
      variables: {
        bookingId,
      }
    });
    return response.data.findAllExportContainersByBookingId;
  }
}

interface ContainerData {
  id: number;
  containerNumber: string;
  noticeStatus: string;
  isSelected: boolean;
  iso: string|null;
  departureCarNumber: string|null;
  isRail: boolean;
  car: string|null;
  loadingPlace: string|null;
}