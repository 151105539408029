import { gql } from '@apollo/client';

export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!, $remember: Boolean) {
    login(loginInput: { email: $email, password: $password, remember: $remember }) {
      access_token
      user {
        id
        name
        email
      }
    }
  }
`;

export const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile($name: String!, $email: String!, $emailPassword: String!) {
    updateProfile(input: { name: $name, email: $email, emailPassword: $emailPassword }) {
      id
      name
      email
      emailPassword
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($currentPassword: String!, $newPassword: String!) {
    changePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
      success
      message
    }
  }
`;