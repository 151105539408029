import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BookingService } from '../../../services/BookingService';
import { BookingHistory } from '../../../interfaces/Booking';
import { formatDateTime } from '../../../functions/dateHelper';

interface HistoryProps {
  id?: number;
}

const History: React.FC<HistoryProps> = ({ id }) => {
  const [history, setHistory] = useState<BookingHistory[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [expandedItems, setExpandedItems] = useState<Set<number>>(new Set());
  const bookingService = new BookingService();

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      bookingService.getBookingHistory(id)
        .then((data) => { 
          setHistory(data); 
        })
        .catch((error) => {
          console.error("Error fetching history:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id]);

  const toggleExpand = (index: number) => {
    const newExpanded = new Set(expandedItems);
    if (newExpanded.has(index)) {
      newExpanded.delete(index);
    } else {
      newExpanded.add(index);
    }
    setExpandedItems(newExpanded);
  };

  const formatDescription = (description: string) => {
    return description.split('\n').map((line, i) => (
      <span key={i} className="block">{line || '\u00A0'}</span>
    ));
  };

  return (
    <div className="w-full bg-white rounded-lg shadow-md">
      <div className="p-6">
        <h2 className="text-xl font-semibold text-gray-800 mb-4">Istoric booking</h2>
        
        {isLoading ? (
          <div className="flex justify-center p-8">
            <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
          </div>
        ) : history.length === 0 ? (
          <div className="text-center py-8 text-gray-500">
            Nu există înregistrări în istoric pentru această rezervare.
          </div>
        ) : (
          <div className="w-full overflow-auto pb-4">
            <ul className="space-y-4 w-full">
              {history.map((item, index) => (
                <li 
                  key={item.id}
                  className="border border-gray-200 rounded-lg overflow-hidden bg-gray-50 hover:bg-gray-100 transition-colors"
                >
                  <div 
                    className="flex items-center justify-between p-4 cursor-pointer"
                    onClick={() => toggleExpand(index)}
                  >
                    <div className="flex items-center space-x-4">
                      <div className={`w-3 h-3 rounded-full ${getStatusColor(item.type)}`}></div>
                      <span className="font-medium text-gray-700">{item.title}</span>
                      <span className="text-sm text-gray-500">{formatDateTime(item.date)}</span>
                    </div>
                    <div className="flex items-center space-x-4">
                      <span className="text-sm text-gray-600">{item.user?.name || 'Utilizator necunoscut'}</span>
                      <svg 
                        className={`w-5 h-5 text-gray-500 transition-transform ${expandedItems.has(index) ? 'transform rotate-180' : ''}`} 
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                      </svg>
                    </div>
                  </div>
                  
                  {expandedItems.has(index) && (
                    <div className="px-4 pb-4">
                      <div className="bg-white p-4 rounded border border-gray-200 max-h-96 overflow-y-auto whitespace-pre-wrap">
                        <div className="text-sm font-mono text-gray-700 leading-relaxed">
                          {formatDescription(item.description)}
                        </div>
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

// Utilitar pentru a determina culoarea în funcție de tipul evenimentului
const getStatusColor = (type: string): string => {
  switch (type.toLowerCase()) {
    case 'create':
    case 'new':
    case 'add':
      return 'bg-green-500';
    case 'error':
    case 'failure':
    case 'cancel':
      return 'bg-red-500';
    case 'update':
    case 'modify':
    case 'change':
      return 'bg-yellow-500';
    case 'info':
    case 'detail':
    case 'view':
      return 'bg-blue-500';
    case 'complete':
    case 'finish':
      return 'bg-purple-500';
    default:
      return 'bg-gray-500';
  }
};

export default History;