import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'flowbite-react';
import { defaultServiceLoop, VesselServiceLoop } from '../../../interfaces/Vessel';
import { VesselService } from '../../../services/VesselService';
import { handleInputChange } from '../../../functions/selectHelper';
import DynamicPortSelect from '../../../components/DynamicPortSelect';
import { errorMessage, successMessage } from '../../../functions/generalHelper';
import Input from '../../../components/Input';

interface ModalServiceLoopProps {
    id: number;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    onSave: (serviceLoop : VesselServiceLoop, type : string) => void;
}

const ModalServiceLoop: React.FC<ModalServiceLoopProps> = ({ id, showModal, setShowModal, onSave }) => {
    const [serviceLoop, setServiceLoop] = useState<VesselServiceLoop>(defaultServiceLoop);
    const vesselService = new VesselService();

    useEffect(() => {
        if (!showModal) {
            if(id){
                // get service loop by id
                vesselService.getServiceLoop(id).then((data) => { setServiceLoop(data); }).catch(() => {});
            }else{
                setServiceLoop(defaultServiceLoop);
            }
        }
    }, [showModal]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            if (id !== 0) {
                const newServiceLoop = await vesselService.updateServiceLoop(serviceLoop);
                onSave(newServiceLoop, 'update');
                successMessage('Serviciul a fost modificat cu succes.');
            } else {
                const newServiceLoop = await vesselService.createServiceLoop(serviceLoop);
                onSave(newServiceLoop, 'create');
                successMessage('Serviciul a fost adaugat cu succes.');
            }
            setShowModal(false);
            setServiceLoop(defaultServiceLoop);
        } catch (error) {
            errorMessage('An error occurred while saving the Service Loop.');
        }
    };

    return (
        <>
            <Modal show={showModal} size="xl" popup onClose={() => setShowModal(false)}>
                <Modal.Header>
                    {id ? 'Editează Serviciu' : 'Adaugă Serviciu'}
                </Modal.Header>
                <Modal.Body className="overflow-visible">
                    <div className="space-y-2 pt-5">
                        <form onSubmit={handleSubmit}>
                            <div className="gap-4 grid grid-cols-1 mb-2">
                                <div className="mb-1">
                                    <Input 
                                        id="name" 
                                        label="Nume"
                                        value={serviceLoop.name}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(serviceLoop, setServiceLoop, 'name', e.target.value, defaultServiceLoop)}
                                    />
                                </div>
                                <div className="mb-1">
                                    <DynamicPortSelect
                                        placeholder="Port"
                                        value={serviceLoop.port ? serviceLoop.port : ''}
                                        onChange={(newValue) => setServiceLoop(prev => ({...prev, port: newValue.value}))}
                                    />
                                </div>
                                <div className="mb-1">
                                    <Input 
                                        id="firstBound" 
                                        label="Import bound(W sau N)"
                                        value={serviceLoop.firstBound}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(serviceLoop, setServiceLoop, 'firstBound', e.target.value, defaultServiceLoop)}
                                    />
                                </div>
                                <div className="mb-1">
                                    <Input 
                                        id="secondBound" 
                                        label="Export bound(E sau S)"
                                        value={serviceLoop.secondBound}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(serviceLoop, setServiceLoop, 'secondBound', e.target.value, defaultServiceLoop)}
                                    />
                                </div>
                            </div>
                            <div className="flex justify-end">
                                <Button size="sm" type="submit">Salvează</Button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ModalServiceLoop;