import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'flowbite-react';
import { errorMessage, successMessage } from '../../../functions/generalHelper';
import Input from '../../../components/Input';
import CustomSelect from '../../../components/CustomSelect';
import { ViewBooking, CargoManifest, defaultCargoManifest } from '../../../interfaces/Booking';
import { handleInputChange, handleSelectChange } from '../../../functions/selectHelper';
import { BookingService } from '../../../services/BookingService';
import { LabelValueInt } from '../../../interfaces/Recommandation';

interface CargoModalProps {
    id: number;
    showModal: boolean;
    booking: ViewBooking;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    onSave: (cargo: CargoManifest, create: boolean) => void;
}

const CargoModal: React.FC<CargoModalProps> = ({ id, showModal, booking, setShowModal, onSave }) => {
    const [cargoManifest, setCargoManifest] = useState<CargoManifest>(defaultCargoManifest);
    const [containerOptions, setContainerOptions] = useState<LabelValueInt[]>([]);
    const bookingService = new BookingService();

    useEffect(() => {
        if (cargoManifest.bookingBillId) {
            setContainerOptions(booking.containers.filter(container => container.bookingBillId === cargoManifest.bookingBillId && container.containerNumber && container.id).map(container => ({label: container.containerNumber!, value: container.id!})));
            // verificam daca este selectat un container si daca da verificam si daca apartine bookingBill-ului selectat
            // daca nu apartine, setam containerul pe default
            if (cargoManifest.bookingContainerId !== defaultCargoManifest.bookingContainerId) {
                const container = booking.containers.find(container => container.id === cargoManifest.bookingContainerId);
                if (!container || container.bookingBillId !== cargoManifest.bookingBillId) {
                    setCargoManifest(prev => ({...prev, bookingContainerId: defaultCargoManifest.bookingContainerId}));
                }
                // daca exista un singur container in bookingBill-ul selectat il selectam automat
                if (booking.containers.filter(container => container.bookingBillId === cargoManifest.bookingBillId && container.containerNumber && container.id).length === 1) {
                    setCargoManifest(prev => ({...prev, bookingContainerId: booking.containers.filter(container => container.bookingBillId === cargoManifest.bookingBillId && container.containerNumber && container.id)[0].id}));
                }
            }
        }else{
            setContainerOptions([]);
            setCargoManifest(prev => ({...prev, bookingContainerId: defaultCargoManifest.bookingContainerId}));
        }
    }, [cargoManifest.bookingBillId]);

    useEffect(() => {
        if (!showModal) {
            setCargoManifest(defaultCargoManifest);
        }else{
            if(id !== 0){
                setCargoManifest({
                    ...defaultCargoManifest, 
                    ...booking.cargoManifests.find(cargo => cargo.id === id) || defaultCargoManifest, 
                });
            }else{
                setCargoManifest(prev => ({
                    ...prev, 
                    bookingBillId: booking.bookingBills.length === 1 ? booking.bookingBills[0].id : defaultCargoManifest.bookingBillId,
                    bookingContainerId: booking.bookingBills.length === 1 ? 
                        booking.containers.filter(container => container.bookingBillId === booking.bookingBills[0].id && container.containerNumber && container.id).length === 1 ?
                            booking.containers.filter(container => container.bookingBillId === booking.bookingBills[0].id && container.containerNumber && container.id)[0].id : defaultCargoManifest.bookingContainerId
                    : defaultCargoManifest.bookingContainerId
                }));
            }
        }
    }, [showModal]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            if (cargoManifest.bookingBillId == defaultCargoManifest.bookingBillId) {
                errorMessage('B/L-ul mărfii este obligatoriu!');
                return;
            }
            const newCargoManifest = await bookingService.createBookingCargo(cargoManifest);
            console.log('sss');
            setShowModal(false);
            successMessage('Marfă adaugată cu succes!');
            onSave(newCargoManifest, id === 0);
        } catch (error) {
            errorMessage('A aparut o eroare la adaugarea mărfii!');
        }
    };

    return (
        <Modal show={showModal} size="3xl" popup onClose={() => setShowModal(false)}>
            <Modal.Header>Adaugă marfă</Modal.Header>
            <Modal.Body className="overflow-visible">
                <div className="space-y-2 pt-5">
                    <form onSubmit={handleSubmit}>
                        <div className="gap-4 grid grid-cols-2 mb-2">
                            <CustomSelect
                                value={booking.bookingBills?.filter(bill => bill.id === cargoManifest.bookingBillId).map(bill => ({ value: bill.id, label: bill.code }))[0]}
                                onChange={(data) => handleSelectChange(cargoManifest, setCargoManifest, 'bookingBillId', data)}
                                options={booking.bookingBills?.map(bill => ({ value: bill.id, label: bill.code })) || []}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Booking Bill"
                            />
                            <CustomSelect
                                value={booking.containers.map(container => ({label: container.containerNumber, value: container.id})).find(option => option.value === cargoManifest.bookingContainerId)}
                                onChange={(data) => {setCargoManifest(prev => ({...prev, bookingContainerId: data.value}))}}
                                options={booking.containers.map(container => ({label: container.containerNumber, value: container.id}))}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Container"
                            />
                            <Input 
                                id="hsCode"
                                label="HS Code"
                                value={cargoManifest.hsCode}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(cargoManifest, setCargoManifest, 'hsCode', e.target.value, defaultCargoManifest)}
                            />
                            <Input 
                                id="packageNumber"
                                label="Număr de pachete"
                                step="1"
                                type="number"
                                value={cargoManifest.packageNumber}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(cargoManifest, setCargoManifest, 'packageNumber', parseInt(e.target.value), defaultCargoManifest)}
                            />
                            <Input 
                                id="packageType"
                                label="Tip pachet"
                                value={cargoManifest.packageType}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(cargoManifest, setCargoManifest, 'packageType', e.target.value, defaultCargoManifest)}
                            />
                            <Input 
                                id="weight"
                                label="Greutate"
                                type="number"
                                step="0.001"
                                value={cargoManifest.weight}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(cargoManifest, setCargoManifest, 'weight', parseFloat(e.target.value), defaultCargoManifest)}
                            />
                            <Input 
                                id="volume"
                                label="Volum"
                                type="number"
                                step="0.001"
                                value={cargoManifest.volume}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(cargoManifest, setCargoManifest, 'volume', parseFloat(e.target.value), defaultCargoManifest)}
                            />
                        </div>
                        <div className="flex justify-end">
                            <Button size="sm" type="submit">Salvează</Button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CargoModal;
