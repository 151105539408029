import React, { useState, useCallback, useEffect } from 'react';
import { defaultContainer } from '../../../interfaces/Booking';
import { Button, Modal, ToggleSwitch } from 'flowbite-react';
import CustomSelect from '../../../components/CustomSelect';
import { RecommendationResp } from '../../../interfaces/Recommandation';
import { errorMessage, handleOptions } from '../../../functions/generalHelper';
import Input from '../../../components/Input';
import { handleInputChange, handleSelectChange } from '../../../functions/selectHelper';
import { UpdateBookingContainerBooking } from '../../../validations/booking/CreateBookingContainerBooking';
import { BookingService } from '../../../services/BookingService';

interface ModalContainersProps {
    bookingId: number;
    recommendations: RecommendationResp[];
    openModal: boolean,
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    submitFunction: () => void;
    options: {
        loadingPlace: string;
        commodity: string;
    };
}

const ModalContainers: React.FC<ModalContainersProps> = ({ bookingId, recommendations, openModal, setOpenModal, submitFunction, options }) => {
    const [container, setContainer] = useState<UpdateBookingContainerBooking>(defaultContainer as UpdateBookingContainerBooking);
    const [cantitate, setCantitate] = useState<number>(1);
    const typeOptions = handleOptions(recommendations, 'container-type');
    const sizeOptions = handleOptions(recommendations, 'container-size');
    const bookingService = new BookingService();

    useEffect(() => {
        if(container.type === 'DC' && container.size === '40') {
            setContainer(prev => ({...prev, iso: '42G1'}));
        }
        if(container.type === 'HQ' && container.size === '40') {
            setContainer(prev => ({...prev, iso: '45G1'}));
        }
        if(container.type === 'DC' && container.size === '20') {
            setContainer(prev => ({...prev, iso: '22G1'}));
        }
        if(container.type === 'HQ' && container.size === '45') {
            setContainer(prev => ({...prev, iso: 'L5G1'}));
        }
        if(container.type === 'RF' && container.size === '20') {
            setContainer(prev => ({...prev, iso: '22R1'}));
        }
        if(container.type === 'RF' && container.size === '40') {
            setContainer(prev => ({...prev, iso: '42R1'}));
        }
        if(container.type === 'RQ' && container.size === '20') {
            setContainer(prev => ({...prev, iso: '22R1'}));
        }
        if(container.type === 'RQ' && container.size === '40') {
            setContainer(prev => ({...prev, iso: '42R1'}));
        }
    }, [container.type, container.size]);

    const handleSubmit = useCallback(async () => {
        if(cantitate < 1) {
            errorMessage('Cantitatea trebuie să fie mai mare decât 0!');
            return;
        }

        const newContainers : any[] = [];
        for (let index = 0; index < cantitate; index++) {
            newContainers.push({...defaultContainer, ...container, loadingPlace: options.loadingPlace, commodity: options.commodity});
        }
        await bookingService.createMultipleContainers(newContainers, bookingId)
        submitFunction();
        setContainer(defaultContainer as UpdateBookingContainerBooking);
        setOpenModal(false);
    }, [container, cantitate, defaultContainer]);


    return (
        <Modal show={openModal} size="lg" popup onClose={() => setOpenModal(false)}>
            <Modal.Header>
                Adaugă containere automat
            </Modal.Header>
            <Modal.Body className="overflow-visible">
                <div className="space-y-2 pt-5">
                    <div className="gap-4">
                        <div className="mb-5">
                            <Input 
                                id="cantitate"
                                label="Cantitate"
                                type="number"
                                step="1"
                                value={cantitate}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCantitate(parseInt(e.target.value))}
                            />
                        </div>
                        <div className="mb-5">
                            <CustomSelect
                                value={sizeOptions.find(option => option.value === container.size)}
                                onChange={(data) => handleSelectChange(container, setContainer, 'size', data)}
                                options={sizeOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Dimensiune"
                            />
                        </div>
                        <div className="mb-5">
                            <CustomSelect
                                value={typeOptions.find(option => option.value === container.type)}
                                onChange={(data) => handleSelectChange(container, setContainer, 'type', data)}
                                options={typeOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Tip"
                            />
                        </div>
                        <div className="mb-5">
                            <Input 
                                id="iso"
                                label="ISO"
                                value={container.iso}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(container, setContainer, 'iso', e.target.value, defaultContainer as UpdateBookingContainerBooking)}
                            />
                        </div>
                        <div className="mb-5">
                            <Input 
                                id="freeDays"
                                label="Zile libere"
                                value={container.freeDays}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(container, setContainer, 'freeDays', parseInt(e.target.value), defaultContainer as UpdateBookingContainerBooking)}
                            />
                        </div>
                        <div className="flex flex-row items-center mb-5">
                            <ToggleSwitch color="primary" checked={container.dangerCargo!} label="Este IMO?" onChange={() => {setContainer(prev => ({ ...prev, dangerCargo: !container.dangerCargo! }))}} />
                        </div>
                        <div className="flex flex-row items-center mb-5">
                            <ToggleSwitch color="primary" checked={container.soc!} label="Este SOC?" onChange={() => {setContainer(prev => ({ ...prev, soc: !container.soc! }))}} />
                        </div>
                        <div className="flex flex-row items-center mb-5">
                            <ToggleSwitch color="primary" checked={container.isRail!} label="Rail?" onChange={() => {setContainer(prev => ({ ...prev, isRail: !container.isRail! }))}} />
                        </div>

                        <div className="col-span-2 flex justify-end">
                            <Button size="sm" onClick={handleSubmit}>Adaugă containere</Button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalContainers;