import React from 'react';
import { User } from '../../interfaces/User';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { ColDef } from 'ag-grid-community';
import Actions from '../../components/Actions';
import { AG_GRID_LOCALE_RO } from '../../functions/langHelper';
import "ag-grid-charts-enterprise";
import Tooltip from '../../components/Tooltip';
import { MdOutlineSecurity } from "react-icons/md";
import { Role } from '../../interfaces/Role';
import CheckPermission from '../../components/CheckPermission';

interface UserProps {
  users: User[];
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
  onRole: (user: User) => void;
}

const Table: React.FC<UserProps> = ({ users, onEdit, onDelete, onRole }) => {
  const pagination = true;
  const paginationPageSize = 20;
  const paginationPageSizeSelector = [10, 20, 50, 100];
  const gridOptions = {rowHeight: 35};
  const columns: ColDef<User>[] = [
    { headerName: 'Id', field: 'id', sortable: true, filter: true },
    { headerName: 'Nume', field: 'name', sortable: true, filter: true },
    { headerName: 'Email', field: 'email', sortable: true, filter: true },
    { headerName: 'Function', field: 'function', sortable: true, filter: true },
    { headerName: 'Department', field: 'department', sortable: true, filter: true },
    { headerName: 'Roluri', field: 'roles', valueFormatter: (params) => (params.value as Role[]).map(role => role.name).join(', '), sortable: true, filter: true },
    {
      headerName: 'Actions',
      field: 'id',
      cellRenderer: (params: any) => (
        <div>
          <Actions 
            id={params.data.id} 
            onEdit={() => onEdit(params.data.id)} 
            onDelete={() => onDelete(params.data.id)} 
            moduleName="utilizator" 
            deletePermissions={['USER_DELETE']}
            editPermissions={['USER_UPDATE']}
          >
            <Tooltip content="Atribuie rol">
              <CheckPermission permissions={['USER_UPDATE', 'ROLE_VIEW_ALL']} logic="AND">
                <button type="button" className="text-yellow-500 hover:text-yellow-700 h-[20px] mt-1 flex items-center mx-1" aria-label="Info" onClick={() => {onRole(params.data)}}>
                  <MdOutlineSecurity className="w-[17px] h-[17px] hover:scale-110 duration-75"/>
                </button> 
              </CheckPermission>
            </Tooltip>
          </Actions>
        </div>
      )
    }
  ];

  return (
    <>
      <AgGridReact 
        localeText={AG_GRID_LOCALE_RO}
        columnDefs={columns} 
        rowData={users}
        defaultColDef={{ flex: 1, minWidth: 20 }}
        domLayout='autoHeight'
        pagination={pagination}
        paginationPageSize={paginationPageSize}
        paginationPageSizeSelector={paginationPageSizeSelector}
        gridOptions={gridOptions}
        enableCharts={true}
        enableRangeSelection={true}
      />
    </>
  );
};

export default Table;
