import type { CustomFlowbiteTheme } from "flowbite-react";

const customTheme: CustomFlowbiteTheme = {
    button:{
      "color": {
        "dark": "border border-transparent bg-gray-800 text-white focus:ring-4 focus:ring-gray-300 enabled:hover:bg-gray-900 dark:border-gray-700 dark:bg-gray-800 dark:focus:ring-gray-800 dark:enabled:hover:bg-gray-700",
        "failure": "border border-transparent bg-red-700 text-white focus:ring-4 focus:ring-red-300 enabled:hover:bg-red-800 dark:bg-red-600 dark:focus:ring-red-900 dark:enabled:hover:bg-red-700",
        // gray
        "gray": "focus:ring-softprimary border border-gray-200 bg-white text-gray-900 focus:text-darkprimary focus:ring-4 enabled:hover:bg-gray-100 enabled:hover:text-darkprimary dark:border-gray-600 dark:bg-transparent dark:text-gray-400 dark:enabled:hover:bg-gray-700 dark:enabled:hover:text-white",
        // default
        "info": "border border-transparent bg-primary text-main focus:ring-4 focus:ring-softprimary enabled:hover:bg-darkprimary dark:bg-primary dark:focus:ring-darkprimary dark:enabled:hover:bg-darkprimary hover:text-white",
        "light": "border border-gray-300 bg-white text-gray-900 focus:ring-4 focus:ring-cyan-300 enabled:hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-600 dark:text-white dark:focus:ring-gray-700 dark:enabled:hover:border-gray-700 dark:enabled:hover:bg-gray-700",
        "purple": "border border-transparent bg-purple-700 text-white focus:ring-4 focus:ring-purple-300 enabled:hover:bg-purple-800 dark:bg-purple-600 dark:focus:ring-purple-900 dark:enabled:hover:bg-purple-700",
        "success": "border border-transparent bg-green-700 text-white focus:ring-4 focus:ring-green-300 enabled:hover:bg-green-800 dark:bg-green-600 dark:focus:ring-green-800 dark:enabled:hover:bg-green-700",
        "warning": "border border-transparent bg-yellow-400 text-white focus:ring-4 focus:ring-yellow-300 enabled:hover:bg-yellow-500 dark:focus:ring-yellow-900",
        "blue": "border border-transparent bg-blue-700 text-white focus:ring-4 focus:ring-blue-300 enabled:hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800",
        "cyan": "border border-cyan-300 bg-white text-cyan-900 focus:ring-4 focus:ring-cyan-300 enabled:hover:bg-cyan-100 dark:border-cyan-600 dark:bg-cyan-600 dark:text-white dark:focus:ring-cyan-700 dark:enabled:hover:border-cyan-700 dark:enabled:hover:bg-cyan-700",
        "green": "border border-green-300 bg-white text-green-900 focus:ring-4 focus:ring-green-300 enabled:hover:bg-green-100 dark:border-green-600 dark:bg-green-600 dark:text-white dark:focus:ring-green-700 dark:enabled:hover:border-green-700 dark:enabled:hover:bg-green-700",
        "indigo": "border border-indigo-300 bg-white text-indigo-900 focus:ring-4 focus:ring-indigo-300 enabled:hover:bg-indigo-100 dark:border-indigo-600 dark:bg-indigo-600 dark:text-white dark:focus:ring-indigo-700 dark:enabled:hover:border-indigo-700 dark:enabled:hover:bg-indigo-700",
        "lime": "border border-lime-300 bg-white text-lime-900 focus:ring-4 focus:ring-lime-300 enabled:hover:bg-lime-100 dark:border-lime-600 dark:bg-lime-600 dark:text-white dark:focus:ring-lime-700 dark:enabled:hover:border-lime-700 dark:enabled:hover:bg-lime-700",
        "pink": "border border-pink-300 bg-white text-pink-900 focus:ring-4 focus:ring-pink-300 enabled:hover:bg-pink-100 dark:border-pink-600 dark:bg-pink-600 dark:text-white dark:focus:ring-pink-700 dark:enabled:hover:border-pink-700 dark:enabled:hover:bg-pink-700",
        "red": "border border-red-300 bg-white text-red-900 focus:ring-4 focus:ring-red-300 enabled:hover:bg-red-100 dark:border-red-600 dark:bg-red-600 dark:text-white dark:focus:ring-red-700 dark:enabled:hover:border-red-700 dark:enabled:hover:bg-red-700",
        "teal": "border border-teal-300 bg-white text-teal-900 focus:ring-4 focus:ring-teal-300 enabled:hover:bg-teal-100 dark:border-teal-600 dark:bg-teal-600 dark:text-white dark:focus:ring-teal-700 dark:enabled:hover:border-teal-700 dark:enabled:hover:bg-teal-700",
        "yellow": "border border-yellow-300 bg-white text-yellow-900 focus:ring-4 focus:ring-yellow-300 enabled:hover:bg-yellow-100 dark:border-yellow-600 dark:bg-yellow-600 dark:text-white dark:focus:ring-yellow-700 dark:enabled:hover:border-yellow-700 dark:enabled:hover:bg-yellow-700"
      },
    },
    tabs: {
        "base": "flex flex-col gap-2",
        "tablist": {
            "base": "flex text-center",
            "tabitem": {
                "base": "text-primary flex items-center justify-center active:ring-2 active:ring-softprimary rounded-t-lg py-2 px-4 text-sm font-medium first:ml-0 focus:outline-none disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500",
                styles: {
                    "default": {
                        "base": "rounded-t-lg",
                        "active": {
                            "on": "bg-softprimary font-semibold text-main dark:bg-gray-800",
                            "off": "flex items-center text-sm font-semibold text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white hover:bg-softprimary hover:text-gray-600"
                        }
                    },
                },
                icon: "mr-2 h-5 w-5 text-gray-600"
            }
        },
    },
    toggleSwitch: {
      "root": {
        "base": "group flex rounded-lg focus:outline-none",
        "active": {
          "on": "cursor-pointer",
          "off": "cursor-not-allowed opacity-50"
        },
        "label": "ms-3 mt-0.5 text-start text-sm font-medium text-gray-900 dark:text-gray-300"
      },
      "toggle": {
        "base": "relative rounded-full border after:absolute after:rounded-full after:bg-white after:transition-all group-focus:ring-4 group-focus:ring-softprimary",
        "checked": {
          "on": "after:translate-x-full after:border-white rtl:after:-translate-x-full",
          "off": "border-gray-200 bg-gray-200 dark:border-gray-600 dark:bg-gray-700",
          "color": {
            "blue": "border-cyan-700 bg-cyan-700",
            "dark": "bg-dark-700 border-dark-900",
            "failure": "border-red-900 bg-red-700",
            "gray": "border-gray-600 bg-gray-500",
            "green": "border-green-700 bg-green-600",
            "light": "bg-light-700 border-light-900",
            "red": "border-red-900 bg-red-700",
            "purple": "border-purple-900 bg-purple-700",
            "success": "border-green-500 bg-green-500",
            "yellow": "border-yellow-400 bg-yellow-400",
            "warning": "border-yellow-600 bg-yellow-600",
            "cyan": "border-cyan-500 bg-cyan-500",
            "primary": "border-primary bg-primary",
            "lime": "border-lime-400 bg-lime-400",
            "indigo": "border-indigo-400 bg-indigo-400",
            "teal": "bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4",
            "info": "border-cyan-600 bg-cyan-600",
            "pink": "border-pink-600 bg-pink-600"
          }
        },
        "sizes": {
          "sm": "h-5 w-9 min-w-9 after:left-px after:top-px after:h-4 after:w-4 rtl:after:right-px",
          "md": "h-6 w-11 min-w-11 after:left-px after:top-px after:h-5 after:w-5 rtl:after:right-px",
          "lg": "h-7 w-14 min-w-14 after:left-1 after:top-0.5 after:h-6 after:w-6 rtl:after:right-1"
        }
      }
    },
    checkbox: {
      "root": {
        "base": "h-4 w-4 rounded border border-gray-300 bg-gray-100 focus:ring-2 dark:border-gray-600 dark:bg-gray-700 cursor-pointer",
        "color": {
          "default": "text-primary focus:ring-primary dark:ring-primary dark:focus:ring-primary",
        }
      }
    },
    textInput: {
      "base": "flex",
      "addon": "inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-white px-3 text-sm text-gray-900 dark:border-gray-600 dark:bg-gray-600 dark:text-gray-400",
      "field": {
        "base": "relative w-full",
        "icon": {
          "base": "pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3",
          "svg": "h-5 w-5 text-gray-500 dark:text-gray-400"
        },
        "rightIcon": {
          "base": "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3",
          "svg": "h-5 w-5 text-gray-500 dark:text-gray-400"
        },
        "input": {
          "base": "block w-full border disabled:cursor-not-allowed disabled:opacity-50",
          "sizes": {
            "sm": "p-2 sm:text-xs",
            "md": "p-2.5 text-sm",
            "lg": "p-4 sm:text-base"
          },
          "colors": {
            "gray": "border-gray-300 bg-white-50 text-gray-900 focus:border-primary focus:ring-primary focus:ring-0 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500",
            "info": "border-cyan-500 bg-cyan-50 text-cyan-900 placeholder-cyan-700 focus:border-cyan-500 focus:ring-cyan-500 dark:border-cyan-400 dark:bg-cyan-100 dark:focus:border-cyan-500 dark:focus:ring-cyan-500",
            "failure": "border-red-500 bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500 dark:border-red-400 dark:bg-red-100 dark:focus:border-red-500 dark:focus:ring-red-500",
            "warning": "border-yellow-500 bg-yellow-50 text-yellow-900 placeholder-yellow-700 focus:border-yellow-500 focus:ring-yellow-500 dark:border-yellow-400 dark:bg-yellow-100 dark:focus:border-yellow-500 dark:focus:ring-yellow-500",
            "success": "border-green-500 bg-green-50 text-green-900 placeholder-green-700 focus:border-green-500 focus:ring-green-500 dark:border-green-400 dark:bg-green-100 dark:focus:border-green-500 dark:focus:ring-green-500"
          },
          "withRightIcon": {
            "on": "pr-10",
            "off": ""
          },
          "withIcon": {
            "on": "pl-10",
            "off": ""
          },
          "withAddon": {
            "on": "rounded-r-lg",
            "off": "rounded-lg"
          },
          "withShadow": {
            "on": "shadow-sm dark:shadow-sm-light",
            "off": ""
          }
        }
      }
    },
    modal: {
      "root": {
        "sizes": {
          "sm": "max-w-sm",
          "md": "max-w-md",
          "lg": "max-w-lg",
          "xl": "max-w-xl",
          "2xl": "max-w-2xl",
          "3xl": "max-w-3xl",
          "4xl": "max-w-4xl",
          "5xl": "max-w-5xl",
          "6xl": "max-w-6xl",
          "7xl": "max-w-7xl",
          "semiFull": "max-w-[80vw]",
          "full": "max-w-[90vw]",
        },
      },
      "header": {
        "base": "flex items-start justify-between rounded-t border-b p-5 dark:border-gray-600",
        "popup": "border-b-0 p-2 pt-4 border-b px-6",
        "title": "text-lg font-bold text-gray-900 dark:text-white",
        "close": {
          "base": "ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white",
          "icon": "h-5 w-5"
        }
      },
    }
};  

export default customTheme;