import React, { useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { AG_GRID_LOCALE_RO } from '../../../functions/langHelper';
import { ColDef, ValueSetterParams } from 'ag-grid-community';
import { ContainerSetting } from '../../../interfaces/Booking';
import { CellRenderer } from '../../../functions/agGridHelper';
import { successMessage } from '../../../functions/generalHelper';
import { ContainerService } from '../../../services/ContainerService';

interface TableProps {
    containers: ContainerSetting[];
    setContainers: React.Dispatch<React.SetStateAction<ContainerSetting[]>>;
}

const Table: React.FC<TableProps> = ({ containers, setContainers }) => {
  const pagination = true;
  const paginationPageSize = 20;
  const paginationPageSizeSelector = [10, 20, 50, 100];
  const gridOptions = { 
    rowHeight: 35,
    defaultColDef: {
      enableRowGroup: true,
    },
  };
  const containerService = useMemo(() => new ContainerService(), []);
  
  const columns = useMemo<ColDef<ContainerSetting>[]>(() => [
    { 
        headerName: 'Id',
        field: 'id', 
        sortable: true, 
        filter: 'agNumberColumnFilter', 
        floatingFilter: true, 
        minWidth: 100
    },
    { 
      headerName: 'Număr', 
      field: 'number', 
      sortable: true, 
      filter: 'agTextColumnFilter', 
      floatingFilter: true,
    },
    { 
      headerName: 'Tip', 
      field: 'type', 
      sortable: true, 
      filter: 'agTextColumnFilter', 
      floatingFilter: true,
      editable: true,
      cellClass: 'group',
      enableRowGroup: true,
      cellRenderer: CellRenderer, 
      valueSetter: (params : ValueSetterParams) => {
        modificaCelula(params, 'type');
        return true; 
      },
    },
    { 
      headerName: 'Dimensiune', 
      field: 'size', 
      sortable: true, 
      filter: 'agTextColumnFilter', 
      floatingFilter: true,
      editable: true,
      cellClass: 'group',
      enableRowGroup: true,
      cellRenderer: CellRenderer, 
      valueSetter: (params : ValueSetterParams) => {
        modificaCelula(params, 'size');
        return true; 
      },
    },
    { 
      headerName: 'ISO', 
      field: 'iso', 
      sortable: true, 
      filter: 'agTextColumnFilter', 
      floatingFilter: true,
      editable: true,
      cellClass: 'group',
      enableRowGroup: true,
      cellRenderer: CellRenderer, 
      valueSetter: (params : ValueSetterParams) => {
        modificaCelula(params, 'iso');
        return true; 
      },
    },
  ], []);

    const modificaCelula = async (params : any, coloana : string) => {
        const newVal = params.newValue ? params.newValue : '';
        params.value = newVal;

        const result = await containerService.updateContainer({id: params.data.id, [coloana]: newVal});
        setContainers(prev => 
            prev.map((container) => 
                container.id === params.data.id
                ? { 
                    ...container, 
                    [coloana]: params.newValue ? params.newValue : '',
                    iso: coloana === 'size' || coloana === 'type' ? result.iso : container.iso
                  }
                : container
            )
        );

        successMessage('Container editat cu succes!');
    }
  
  return (
    <>
        <AgGridReact 
          localeText={AG_GRID_LOCALE_RO}
          columnDefs={columns} 
          rowData={containers}
          defaultColDef={{ flex: 1, minWidth: 20 }}
          domLayout='autoHeight'
          pagination={pagination}
          paginationPageSize={paginationPageSize}
          paginationPageSizeSelector={paginationPageSizeSelector}
          gridOptions={gridOptions}
          enableCharts={true}
          enableRangeSelection={true}
          sideBar={true}
          rowGroupPanelShow="always"
          groupDisplayType="groupRows"
        />
    </>
  );
};

export default Table;