import { useCallback, useEffect, useMemo, useState } from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { DateField } from '@mui/x-date-pickers';
import isBetween from "dayjs/plugin/isBetween";

import type {
  IDoesFilterPassParams,
} from "ag-grid-community";
import type { CustomFilterProps } from "ag-grid-react";
import { useGridFilter } from "ag-grid-react";
import { Button } from "flowbite-react";

export default (props: CustomFilterProps) => {
    const { model, onModelChange, colDef } = props;
    const [startDate, setStartDate] = useState<string|null>(null);
    const [endDate, setEndDate] = useState<string|null>(null);
    const [comparisonType, setComparisonType] = useState<string>('equals');
    const field = colDef?.filterParams?.field; // Extragem field corect
    const typesOptions = useMemo(() => ([
        { value: 'equals', label: 'Egal' },
        { value: 'between', label: 'Între' },
        { value: 'after', label: 'După' },
        { value: 'before', label: 'Înainte' },
        { value: 'empty', label: 'Gol' }]), 
    [colDef]);

    const doesFilterPass = (params: IDoesFilterPassParams) => {
        const value = params.data[field];
        if (!value) return comparisonType === "empty"; // Dacă nu există valoare, verificăm empty
        
        const date = dayjs(value).startOf("day"); // Normalizăm data fără ore
        const start = startDate ? dayjs(startDate).startOf("day") : null;
        const end = endDate ? dayjs(endDate).endOf("day") : null; // Setăm ultima secundă a zilei

        switch (comparisonType) {
            case "equals":
                return start ? date.isSame(start) : false;
            case "before":
                return start ? date.isBefore(start) : false;
            case "after":
                return start ? date.isAfter(start) : false;
            case "between":
                return start && end ? date.isBetween(start, end, "day", "[]") : false;
            case "empty":
                return !value;
            default:
                return false;
        }}
    // }, [field, startDate, endDate, comparisonType]);

  useGridFilter({
    doesFilterPass,
    // afterGuiAttached,
  });

  const handleChange = 
    (newDate: Dayjs | null, type: 0|1) => { // 0 - start, 1 - end
      if (newDate) {
          if(newDate.isValid()){
            if(type === 0){
                setStartDate(newDate.toISOString());
            } else {
                setEndDate(newDate.toISOString());
            }
            onModelChange(newDate.toISOString());
        }
      } else {
        onModelChange(null);
        if(type === 0){
            setStartDate(null);
        } else {
            setEndDate(null);
        }
      }
    }
//     [onModelChange]
//   );

    useEffect(() => {
        if(comparisonType !== 'empty'){
            if(!startDate){
                onModelChange(null);
            }
        }
    }, [comparisonType, startDate]);

    const resetFilters = () => {
        setComparisonType('equals');
        setStartDate(null);
        setEndDate(null);
        onModelChange(null);
    }

  return (
    <div className="p-2">
    <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        onChange={(e) => setComparisonType(e.target.value)}
    >
        {
            typesOptions.map((option) => (
                <option key={option.value} value={option.value}>{option.label}</option>
            ))
        }
    </select>
        {
            comparisonType !== 'empty' && (
                <>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div className='pt-2 floating-label-timepicker'>
                            <DateField
                                value={startDate ? dayjs(startDate) : null}
                                onChange={(date) => handleChange(date, 0)}
                                format="DD.MM.YYYY"
                                autoFocus={true}
                            />
                        </div>
                    </LocalizationProvider>
                    {
                        comparisonType === 'between' && (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div className='pt-2 floating-label-timepicker'>
                                    <DateField
                                    // end date
                                        value={endDate ? dayjs(endDate) : null}
                                        onChange={(date) => handleChange(date, 1)}
                                        format="DD.MM.YYYY"
                                        autoFocus={false}
                                    />
                                </div>
                            </LocalizationProvider>
                        )
                    }
                    <Button className="mx-auto block mt-2" size="sm" color="gray" onClick={resetFilters}>Sterge filtrul</Button>
                </>
            )
        }
    </div>
  );
};