import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useUser } from '../../components/UserContext';
import Breadcrumb from '../../components/BreadCrumb';
import ContainersNavBar from '../../components/ContainersNavBar';
import Index from './Index';
import CheckPermission from '../../components/CheckPermission';

const Containers: React.FC = () => {
    const { user } = useUser();

    return (
      <>
        <ContainersNavBar />
        <Breadcrumb />
        <CheckPermission permissions={['BOOKINGS_VIEW_ALL']}>
          <Routes>
            {
                <Route path="index" element={<Index />} /> 
            }
            <Route path="/" element={<Navigate to="/containers/index" replace />} />
          </Routes>
        </CheckPermission>
      </>
    );
};

export default Containers;