import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from './Table';
import { ContainerSetting } from '../../../interfaces/Booking';
import { ContainerService } from '../../../services/ContainerService';
import renderSkeleton from '../../../components/SkeletonLoader';

const Index: React.FC = () => {
  const [containers, setContainers] = useState<ContainerSetting[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const containerService = new ContainerService();
  const navigate = useNavigate();

  useEffect(() => {
    fetchContainers();
  }, []);

  const fetchContainers = useCallback(
    async () => {
      setLoading(true);
      containerService.getContainersForConfiguration().then((data) => { setContainers(data); setLoading(false)}).catch(() => {setLoading(false)});
  },[])

  return (
    <div className="ag-theme-quartz px-4">
      <div className="flex justify-between items-center py-3">
      <div className="text-lg font-bold hidden md:block">
        Configurare Containere
      </div>
    </div>
      {loading ? ( renderSkeleton() ) : (
        <>
          <Table containers={containers} setContainers={setContainers} />
        </>
      )}
    </div>
  );
};

export default Index;