import React, { useCallback, useEffect, useState } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { useAuth } from '../../../functions/useAuth';
import { NavLink } from 'react-router-dom';
import { useUser } from '../../../components/UserContext';
import unknownUser from '../../../assets/unknownUser.jpg';
  
const UserMenu: React.FC = () => {
  const { logout, user } = useUser();
  const [ascundeButoane, setAscundeButoane] = useState(false);

  useEffect(() => {
    if(localStorage.getItem('ascundeButoane')){
      setAscundeButoane(true);
    }
  }, []);

  useEffect(() => {
    if(ascundeButoane){
      localStorage.setItem('ascundeButoane', 'true');
    }else{
      localStorage.removeItem('ascundeButoane');
    }
  }, [ascundeButoane]);

  const stergeCacheTabele = useCallback(() => {
    Object.keys(localStorage)
      .filter(key => key.startsWith('agGrid-'))
      .forEach(key => localStorage.removeItem(key));
  }, []);

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton>
            <a href="#" className="group block flex-shrink-0">
                <div className="flex items-center">
                    <div>
                      <img alt={user?.name} src={unknownUser} className="inline-block h-7 w-7 rounded-full"/>
                    </div>
                    <div className="ml-3">
                        <p className="text-xs font-semibold text-gray-700 group-hover:text-gray-900 text-left">{ user?.name }</p>
                        <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700 text-left">{ user?.email }</p>
                    </div>
                </div>
            </a>
        </MenuButton>
      </div>

      <MenuItems
        transition
        className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="px-4 py-3">
          <p className="text-sm">Ești conectat cu</p>
          <p className="truncate text-sm font-medium text-gray-900">{ user?.email }</p>
        </div>
        <div className="py-1">
          <MenuItem>
            <NavLink to="/settings/profile" className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900">Setări profil</NavLink>
          </MenuItem>
          <MenuItem>
            <a href="#" className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900">
              Vezi profil
            </a>
          </MenuItem>
          <MenuItem>
            <a href={`${process.env.REACT_APP_BACKEND_URI}/gmail/auth-url`} className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900">
              Conecteaza mail
            </a>
          </MenuItem>
          <MenuItem>
            <a
              onClick={() => setAscundeButoane(!ascundeButoane)}
              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 cursor-pointer"
            >
              { ascundeButoane ? 'Arata butoane' : 'Ascunde butoane' }
            </a>
          </MenuItem>
          <MenuItem>
            <a
              onClick={stergeCacheTabele}
              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 cursor-pointer"
            >
              Sterge cache tabele
            </a>
          </MenuItem>
        </div>
        <div className="py-1">
            <MenuItem>
              <button onClick={logout} type="button" className="block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900" >
                Log out
              </button>
            </MenuItem>
        </div>
      </MenuItems>
    </Menu>
  )
}

export default UserMenu;