import { Contact, defaultContact } from "./Contact";
import { Financial } from "./Financial";
import { Offer } from "./Offer";
import { User } from "./User";

export interface Client {
    id: number | null;
    company: string;
    email: string | null;
    bookingEmail: string | null;
    phone: string | null;
    tradeId: string | null;
    gstNumber: string | null;
    vat: string | null;
    userId: number | null;
    priority: string | null;
    type: string | null;
    location: string | null;
    activityType: string[];
    relationStatus: string | null;
    level: string[];
    potential: string[];
    isTransporting: boolean;
    street: string | null;
    building: string | null;
    staircase: string | null;
    city: string | null;
    floor: string | null;
    number: string | null;
    apartment: string | null;
    postalCode: string | null;
    state: string | null;
    region: string | null;
    country: string | null;
    website: string | null;
    transportUsed: string[];
    transportDirection: string[];
    trade: string[];
    equipmentUsed: string[];
    dryTransportMode: string[];
    cargoCategory: string[];
    cargoPrice: string[];
    cargoWeight: string[];
    loadingCountry: string[];
    loadingRegion: string[];
    loadingState: string[];
    loadingPort: string[];
    loadingLocation: string[];
    contacts: Contact[];
    financials: Financial[];
    minimalAddress?: string | null;
    partialAddress?: string | null;
    fullAddress?: string | null;
    offers?: Offer[];
    user?: Partial<User>|null;
    actualizat?: Date|null;
};

export interface FullClient extends Client {
    user?: Partial<User> | null;
}

export const defaultClient: Client = {
    id: null,
    company: '',
    email: null,
    bookingEmail: null,
    phone: null,
    gstNumber: null,
    tradeId: null,
    vat: null,
    userId: null,
    priority: null,
    type: 'Persoană juridică',
    location: 'Intern',
    activityType: [],
    relationStatus: null,
    level: [],
    potential: [],
    isTransporting: true,
    street: null,
    building: null,
    staircase: null,
    city: null,
    floor: null,
    number: null,
    apartment: null,
    postalCode: null,
    state: null,
    region: null,
    country: null,
    website: null,
    transportUsed: [],
    transportDirection: [],
    trade: [],
    equipmentUsed: [],
    dryTransportMode: [],
    cargoCategory: [],
    cargoPrice: [],
    cargoWeight: [],
    loadingCountry: [],
    loadingRegion: [],
    loadingState: [],
    loadingPort: [],
    loadingLocation: [],
    contacts: [],
    financials: [],
};
