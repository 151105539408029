import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'flowbite-react';
import { defaultVesselForm, VesselForm, VesselServiceLoop } from '../../../interfaces/Vessel';
import { VesselService } from '../../../services/VesselService';
import { errorMessage, successMessage } from '../../../functions/generalHelper';
import CustomDatePicker from '../../../components/CustomDatePicker';
import { getDateFormat } from '../../../functions/dateHelper';
import Loader from '../../../components/CustomLoader';
import Input from '../../../components/Input';
import CustomSelect from '../../../components/CustomSelect';

interface ModalSyncProps {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    onSave: () => void;
}

const ModalSync: React.FC<ModalSyncProps> = ({ showModal, setShowModal, onSave }) => {
    // const [formData, setFormData] = useState<VesselForm>(defaultVesselForm);
    const [service, setService] = useState<VesselServiceLoop>();
    const [direction, setDirection] = useState<string>();
    const [services, setServices] = useState<VesselServiceLoop[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const vesselService = new VesselService();

    useEffect(() => {
        if (showModal) {
            vesselService.getServiceLoops().then((data) => {
                setServices(data);
            });
        }
    }, [showModal]);

    // cand modifici service ul sa se selecteze automat boundul care este S sau E, sau daca nu exista niciun astfel de bound, sa se selecteze primul
    const handleServiceChange = (service: VesselServiceLoop) => {
        setService(service);
        if (service.firstBound === 'S') {
            setDirection('S');
        } else if (service.firstBound === 'E') {
            setDirection('E');
        } else if (service.secondBound === 'S') {
            setDirection('S');
        } else if (service.secondBound === 'E') {
            setDirection('E');
        } else {
            setDirection(service.firstBound);
        }
    }

    const handleSubmit = async (e: React.FormEvent) => {
        if(!service || !direction) {
            errorMessage('Toate campurile sunt obligatorii');
            return;
        }
        setLoading(true);
        e.preventDefault();
        try {
            const response = await vesselService.syncVessels(service?.name, direction!);
            if(response.status === 'error') {
                throw new Error(response.message);
            }
            onSave();
            successMessage(response.message);
            setShowModal(false);
        } catch (error) {
            errorMessage((error as Error).message);
        }
        setLoading(false);
    };

    return (
        <>
            <Modal show={showModal} size="xl" popup onClose={() => setShowModal(false)}>
                <Modal.Header>
                    Sincronizează nave
                </Modal.Header>
                <Modal.Body className="overflow-visible">
                    <Loader isLoading={loading} />
                    <div className="space-y-2 pt-5">
                        <form onSubmit={handleSubmit}>
                            <div className="gap-4 grid grid-cols-1 mb-2">
                                <CustomSelect
                                    value={{ value: service?.id, label: service?.name }}
                                    onChange={(data) => {setService(services.find((service) => service.id === data.value)); handleServiceChange(services.find((service) => service.id === data.value)!)}}
                                    options={services.map((service) => ({ value: service.id, label: service.name }))}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Serviciu"
                                />

                                <CustomSelect
                                    value={{ label: direction, value: direction }}
                                    onChange={(data) => setDirection(data.value)}
                                    options={service ? [{ label: service.firstBound, value: service.firstBound }, { label: service.secondBound, value: service.secondBound }] : []}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Directie"
                                />
                                    
                                {/* <Input 
                                    id="service" 
                                    label="Serviciu"
                                    value={service}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {setService(e.target.value)}}
                                /> */}
                                {/* <div className="mb-1">
                                    <CustomDatePicker
                                        selected={formData.startDate}
                                        onChange={(date) => setFormData({ ...formData, startDate: date! })}
                                        dateFormat={getDateFormat()}
                                        locale="ro"
                                        isClearable
                                        placeholderText="Data de început"
                                        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                                <div className="mb-1">
                                    <CustomDatePicker
                                        selected={formData.endDate}
                                        onChange={(date) => setFormData({ ...formData, endDate: date! })}
                                        dateFormat={getDateFormat()}
                                        locale="ro"
                                        isClearable
                                        placeholderText="Data de sfârșit"
                                        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div> */}
                            </div>
                            <div className="flex justify-end">
                                <Button size="sm" type="submit">Salvează</Button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ModalSync;