import React, { useState } from 'react';
import { Button } from 'flowbite-react';
import { CargoManifest, ViewBooking } from '../../../interfaces/Booking';
import CargoManifestTable from './components/CargoManifestTable';
import CargoModal from '../components/CargoModal';
import { BookingService } from '../../../services/BookingService';
import DeleteModal from '../../../components/DeleteModal';
import { successMessage } from '../../../functions/generalHelper';

interface ManifestProps {
  booking: ViewBooking;
  setBooking: React.Dispatch<React.SetStateAction<ViewBooking>>;
}

const Manifest: React.FC<ManifestProps> = ({ booking, setBooking }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<number>(0);
  const bookingService = new BookingService();

  const handleAddCargo = () => {
    setShowModal(true);
  };

  const onSave = (cargoManifest : CargoManifest, create : boolean) => {
    if(create){
      setBooking(prev => ({...prev, cargoManifests: [...prev.cargoManifests, cargoManifest]}));
    }else{
      setBooking(prev => ({...prev, cargoManifests: prev.cargoManifests.map(c => c.id === cargoManifest.id ? cargoManifest : c)}));
    }
  };
  
  const handleDelete = (id : number) => {
    setIdToDelete(id);
    setShowModalDelete(true);
  };
  
  const handleEdit = (id : number) => {
    setIdToDelete(id);
    setShowModal(true);
  };

  const confirmDelete = () => {
    bookingService.removeCargo(idToDelete).then(() => {
      setBooking(prev => ({...prev, cargoManifests: prev.cargoManifests.filter(b => b.id !== idToDelete)}));
      setIdToDelete(0);
      successMessage('Marfă stearsă cu succes!');
    });
    setShowModalDelete(false);
  }

  return (
    <div>
      <div className="flex justify-end mb-2 -mt-3">
        <Button size="sm" onClick={handleAddCargo}>Adaugă marfă</Button>
      </div>
      <div className="rounded-lg border border-gray-200 bg-white p-5 relative mb-6">
        <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">Cargo Manifest</h4>
        <CargoManifestTable booking={booking} setBooking={setBooking} onDelete={handleDelete} onEdit={handleEdit}/>
        <CargoModal booking={booking} id={idToDelete} onSave={onSave} setShowModal={setShowModal} showModal={showModal} />
        <DeleteModal isOpen={showModalDelete} onClose={() => {setShowModalDelete(false)}} onConfirm={confirmDelete} deleteText='Sunteți sigur că doriți să eliminați această marfă?' />
      </div>
    </div>
  );
};

export default Manifest;