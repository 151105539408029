import React, {useState} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { ViewBooking, ViewBookingBill } from '../../../../interfaces/Booking';
import { CellRenderer } from '../../../../functions/agGridHelper';
import { AG_GRID_LOCALE_RO } from '../../../../functions/langHelper';
import Actions from '../../../../components/Actions';
import ViewBillModal from '../../components/ViewBillModal';
import { successMessage } from '../../../../functions/generalHelper';
import { BookingService } from '../../../../services/BookingService';
import { formatDate, formatDateTime } from '../../../../functions/dateHelper';
import DateEditor from '../../../../components/AgGrid/DateEditor';

interface BillsTableProps {
  booking: ViewBooking;
  setBooking: React.Dispatch<React.SetStateAction<ViewBooking>>;
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
}

const BillsTable: React.FC<BillsTableProps> = ({ booking, setBooking, onEdit, onDelete }) => {
  const [viewId, setViewId] = useState<number>(0);
  const [showViewModal, setShowViewModal] = useState<boolean>(false);
  const bookingService = new BookingService();

  const modificaCelula = async (params : any, coloana : string) => {
    const updatedBill = {
      ...booking.bookingBills.find(bill => bill.id! === params.data.id!)!,
      [coloana!]: params.newValue
    };

    // Actualizăm containerul în backend
    const savedBill = await bookingService.createOrUpdateBookingBill(updatedBill as ViewBookingBill);
    
    // Actualizăm state-ul local cu noul container
    setBooking(prev => ({
      ...prev,
      bookingBills: prev.bookingBills.map(bill => bill.id === params.data.id ? savedBill : bill)
    }));

    successMessage('B/L editat cu succes!');
  }

  const columns: ColDef<ViewBookingBill>[] = [
    { 
      headerName: 'Număr', 
      field: 'code',
      minWidth: 160,
      cellRenderer: (params: any) => (
        <a onClick={() => {setViewId(params.data.id); setShowViewModal(true); }} className="font-medium cursor-pointer text-blue-600 dark:text-blue-500 hover:underline">{params.value}</a>
      )
    },
    { 
      headerName: 'Shipper', 
      field: 'shipperName', 
      editable: true,
      cellClass: 'group', 
      minWidth: 160,
      cellRenderer: CellRenderer,
      valueSetter: (params) => {
        modificaCelula(params, 'shipperName');
        return true;
      }
    },
    { 
      headerName: 'Shipper Address', 
      field: 'shipperAddress', 
      editable: true,
      cellClass: 'group', 
      minWidth: 160,
      cellRenderer: CellRenderer,
      valueSetter: (params) => {
        modificaCelula(params, 'shipperAddress');
        return true;
      }
    },
    { 
      headerName: 'Consignee', 
      field: 'consigneeName', 
      editable: true,
      cellClass: 'group', 
      minWidth: 160,
      cellRenderer: CellRenderer,
      valueSetter: (params) => {
        modificaCelula(params, 'consigneeName');
        return true;
      }
    },
    { 
      headerName: 'Consignee Address', 
      field: 'consigneeAddress', 
      editable: true,
      cellClass: 'group', 
      minWidth: 160,
      cellRenderer: CellRenderer,
      valueSetter: (params) => {
        modificaCelula(params, 'consigneeAddress');
        return true;
      }
    },
    { 
      headerName: 'Notify', 
      field: 'notifyName', 
      editable: true,
      cellClass: 'group', 
      minWidth: 160,
      cellRenderer: CellRenderer,
      valueSetter: (params) => {
        modificaCelula(params, 'notifyName');
        return true;
      }
    },
    { 
      headerName: 'Notify Address', 
      field: 'notifyAddress', 
      editable: true,
      cellClass: 'group', 
      minWidth: 160,
      cellRenderer: CellRenderer,
      valueSetter: (params) => {
        modificaCelula(params, 'notifyAddress');
        return true;
      }
    },
    { 
      headerName: 'Marfă', 
      field: 'commodity', 
      editable: true,
      cellClass: 'group', 
      minWidth: 160,
      cellRenderer: CellRenderer,
      valueSetter: (params) => {
        modificaCelula(params, 'commodity');
        return true;
      }
    },
    { 
      headerName: 'Terminal', 
      field: 'terminal', 
      editable: true,
      cellClass: 'group', 
      minWidth: 160,
      cellRenderer: CellRenderer,
      valueSetter: (params) => {
        modificaCelula(params, 'terminal');
        return true;
      }
    },
    { 
      headerName: 'Tip eliberare', 
      field: 'releaseType', 
      editable: true,
      cellClass: 'group', 
      minWidth: 160,
      cellRenderer: CellRenderer,
      valueSetter: (params) => {
        modificaCelula(params, 'releaseType');
        return true;
      }
    },
    { 
      headerName: 'HS Code', 
      field: 'hsCode', 
      editable: true,
      cellClass: 'group', 
      minWidth: 160,
      cellRenderer: CellRenderer,
      valueSetter: (params) => {
        modificaCelula(params, 'hsCode');
        return true;
      }
    },
    { 
      headerName: 'Loc incarcare', 
      field: 'loadingPlace', 
      editable: true,
      cellClass: 'group', 
      minWidth: 160,
      cellRenderer: CellRenderer,
      valueSetter: (params) => {
        modificaCelula(params, 'loadingPlace');
        return true;
      }
    },
    {
      headerName: 'Dată',
      field: 'date',
      sortable: false,
      filter: 'agDateColumnFilter',
      filterParams: {
          inRangeInclusive: true,
          defaultOption: 'inRange'
      },
      valueFormatter: (params : any) => formatDate(params.value!)!,
      editable: true,
      minWidth: 170,
      cellEditor: DateEditor,
      valueSetter: (params) => {
        modificaCelula(params, 'date');
        return true;
      }
    },
    // date
    {
      headerName: 'Acțiuni',
      minWidth: 150, 
      field: 'id',
      cellRenderer: (params: any) => (
        <div>
          <Actions 
            id={params.data.id} 
            onEdit={() => onEdit(params.data.id)} 
            onDelete={() => onDelete(params.data.id)} 
            moduleName="B/L"
          />
        </div>
      )
    }
  ];

  return (
    <div className="ag-theme-quartz">
      <ViewBillModal id={viewId} showModal={showViewModal} setShowModal={setShowViewModal} />
      <AgGridReact 
        localeText={AG_GRID_LOCALE_RO}
        columnDefs={columns} 
        rowData={booking.bookingBills}
        defaultColDef={{ flex: 1, minWidth: 20 }}
        domLayout='autoHeight'
        pagination={false}
        gridOptions={{rowHeight: 35}}
      />
    </div>
  );
};

export default BillsTable;