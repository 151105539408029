import React from 'react';
import { NavLink } from 'react-router-dom';
import CheckPermission from './CheckPermission';
import { PiShippingContainerBold } from "react-icons/pi";
import { AiOutlineProfile } from 'react-icons/ai';

const ContainersNavBar: React.FC = () => {
  return (
    <div className="hidden bg-softprimary md:flex items-center px-5 text-black text-[0.95rem] font-medium h-9 ps-[155px]">
      <CheckPermission permissions={['BOOKINGS_VIEW_ALL']}>
        <NavLink to="/containers/index" className={({ isActive }) => (isActive ? 'flex items-center space-x-2 h-full font-bold text-darkprimary px-3 hover:text-main rounded-md' : 'flex items-center space-x-2 h-full hover:text-darkprimary px-3 rounded-md')}>
          <PiShippingContainerBold className='h-4 w-4' />
          <span className="mr-2">Containere export</span>
        </NavLink>
        <NavLink to="/settings/containers/index" className={({ isActive }) => (isActive ? 'flex items-center space-x-2 h-full font-bold text-darkprimary px-3 hover:text-main rounded-md' : 'flex items-center space-x-2 h-full hover:text-darkprimary px-3 rounded-md')}>
          <AiOutlineProfile className='h-4 w-4' />
          <span className="mr-2">Configurari containere</span>
        </NavLink>
      </CheckPermission>
    </div>
  );
};

export default ContainersNavBar;