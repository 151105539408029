import { IsNotEmpty, IsString, MaxLength, IsInt, IsDate, IsOptional, IsArray, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';
import 'reflect-metadata';
import { CreateRoute, UpdateRoute } from './CreateRoute';
import { CreateBookingBillBooking, UpdateBookingBillBooking } from './CreateBookingBillBooking';
import { CreateBookingContainerBooking, UpdateBookingContainerBooking } from './CreateBookingContainerBooking';

export class CreateBooking {
    @IsNotEmpty({ message: 'Numărul booking-ului este obligatoriu.' })
    @IsString()
    @MaxLength(20, { message: 'Numărul booking-ului trebuie să conțină maxim 20 de caractere.' })
    number: string;
    
    @IsNotEmpty()
    @IsString()
    @MaxLength(255)
    status: string;
  
    @IsOptional()
    @IsString()
    @MaxLength(255)
    bookingEmail?: string|null;

    @IsOptional()
    @IsString()
    @MaxLength(255)
    shippingType?: string;
  
    @IsOptional()
    @IsString()
    @MaxLength(255)
    loadType?: string;
  
    @IsOptional()
    @IsString()
    @MaxLength(255)
    bookingType?: string;
  
    @IsOptional()
    @IsString()
    @MaxLength(255)
    shippingLineName?: string;
  
    @IsNotEmpty()
    @IsDate()
    @Type(() => Date)
    creationDate?: Date|null;
  
    @IsOptional()
    @IsString()
    @MaxLength(255)
    cancelReason?: string;
  
    @IsOptional()
    @IsDate()
    @Type(() => Date)
    cancelledDate?: Date|null;
  
    @IsOptional()
    @IsString()
    @MaxLength(255)
    blockStatus?: string;
  
    @IsOptional()
    @IsString()
    @MaxLength(255)
    waiting?: string;
  
    @IsOptional()
    @IsString()
    @MaxLength(255)
    loadingPlace?: string;
  
    @IsOptional()
    @IsString()
    @MaxLength(255)
    pol?: string|null;
  
    @IsOptional()
    @IsString()
    @MaxLength(255)
    pod?: string|null;
  
    @IsOptional()
    @IsString()
    @MaxLength(255)
    hsCode?: string;
  
    @IsOptional()
    @IsString()
    @MaxLength(255)
    commodity?: string;
  
    @IsOptional()
    @IsString()
    @MaxLength(255)
    note?: string;
  
    @IsOptional()
    @IsString()
    @MaxLength(255)
    svcTermOrigin?: string;
  
    @IsOptional()
    @IsString()
    @MaxLength(255)
    svcTermDestination?: string;
  
    @IsOptional()
    @IsString()
    @MaxLength(255)
    terminal?: string;
  
    @IsOptional()
    @IsString()
    @MaxLength(255)
    shipperName?: string;
  
    @IsOptional()
    @IsString()
    @MaxLength(255)
    shipperAddress?: string;
  
    @IsOptional()
    @IsString()
    @MaxLength(255)
    consigneeName?: string;
  
    @IsOptional()
    @IsString()
    @MaxLength(255)
    consigneeAddress?: string;
  
    @IsInt()
    @IsOptional()
    clientId?: number;
  
    @IsInt()
    @IsOptional()
    offerId?: number;
  
    @IsInt()
    @IsOptional()
    createdBy?: number;

    @IsArray()
    @IsOptional()
    @ValidateNested({ each: true })
    @Type(() => CreateRoute)
    routes: CreateRoute[] = [];

    @IsArray()
    @IsOptional()
    @ValidateNested({ each: true })
    @Type(() => CreateBookingBillBooking)
    bookingBills: CreateBookingBillBooking[];
  
    @IsArray()
    @IsOptional()
    @ValidateNested({ each: true })
    @Type(() => CreateBookingContainerBooking)
    containers: CreateBookingContainerBooking[];
}

export type BookingCreateForm = InstanceType<typeof CreateBooking>;

export class UpdateBooking extends CreateBooking {
    @IsInt()
    @IsNotEmpty()
    id: number;

    @IsArray()
    @IsOptional()
    @ValidateNested({ each: true })
    @Type(() => UpdateRoute)
    routes: UpdateRoute[] = [];

    @IsArray()
    @IsOptional()
    @ValidateNested({ each: true })
    @Type(() => UpdateBookingBillBooking)
    bookingBills: UpdateBookingBillBooking[] = [];

    @IsArray()
    @IsOptional()
    @ValidateNested({ each: true })
    @Type(() => UpdateBookingContainerBooking)
    containers: UpdateBookingContainerBooking[] = [];
}

export type BookingUpdateForm = InstanceType<typeof UpdateBooking>;
