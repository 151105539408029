import React, { useMemo, useState, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { ColDef, GridReadyEvent, RowSelectionOptions } from 'ag-grid-community';
import Actions from '../../components/Actions';
import { AG_GRID_LOCALE_RO } from '../../functions/langHelper';
import "ag-grid-charts-enterprise";
import { Booking } from '../../interfaces/Booking';
import { NavLink } from 'react-router-dom';
import { Badge, Button } from 'flowbite-react';
import useGridState from '../../functions/useGridState';
import CustomDateComponent from '../../components/AgGrid/dateFilter/DateFilter';
import DateFloatingFilter from '../../components/AgGrid/dateFilter/DateFloatingFilter';
import { formatDate } from '../../functions/dateHelper';

interface BookingTableProps {
  bookings: Partial<Booking>[];
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
  onExportNotice: (id: number, bookingEmail: string|null, number: string) => void;
}

const Table: React.FC<BookingTableProps> = ({ bookings, onEdit, onDelete, onExportNotice }) => {
  const pagination = true;
  const paginationPageSize = 20;
  const paginationPageSizeSelector = [10, 20, 50, 100];
  const gridOptions = {
    rowHeight: 39,
    defaultColDef: {
      enableRowGroup: true,
    },
    alwaysMultiSort: true,
    rowSelection: {
      mode: 'multiRow',
      selectAll: 'filtered',
      enableClickSelection: false,
    } as RowSelectionOptions
  };

  const components = useMemo<{ [p: string]: any }>(
    () => ({ agDateInput: CustomDateComponent }),
    [],
  );

  const { saveState, restoreState } = useGridState('bookings-grid');
  const onGridReady = useCallback((event: GridReadyEvent) => {
    const api = event.api;
    event.api.closeToolPanel();
    
    // Restaurăm starea salvată
    const savedState = localStorage.getItem(`agGrid-bookings-grid`);
    if (savedState) {
      // Dacă există state salvat, îl restaurăm
      restoreState(api);
    } else {
        // Configurarea implicita a gridului
    }
    // Adăugăm event listeners pentru salvarea stării
    const saveCurrentState = () => saveState(api);
    
    api.addEventListener('filterChanged', saveCurrentState);
    api.addEventListener('sortChanged', saveCurrentState);
    api.addEventListener('columnMoved', saveCurrentState);
    api.addEventListener('columnResized', saveCurrentState);
    api.addEventListener('paginationChanged', saveCurrentState);
    
    // Optional: Cleanup function
    return () => {
      api.removeEventListener('filterChanged', saveCurrentState);
      api.removeEventListener('sortChanged', saveCurrentState);
      api.removeEventListener('columnMoved', saveCurrentState);
      api.removeEventListener('columnResized', saveCurrentState);
      api.removeEventListener('paginationChanged', saveCurrentState);
    };
  }, [saveState, restoreState]);

  const columns = useMemo<ColDef<Partial<Booking>>[]>(() => [
    { 
        headerName: 'Id', 
        field: 'id', 
        sortable: true, 
        filter: 'agNumberColumnFilter', 
        floatingFilter: true, 
        minWidth: 100,
        hide: true
    },
    { 
      headerName: 'Număr', 
      field: 'number', 
      sortable: true, 
      filter: 'agTextColumnFilter', 
      floatingFilter: true,
      pinned: 'left',
      minWidth: 170,
      cellRenderer: (params: any) => (
        <NavLink to={`/bookings/view/${params.data.id}`} className="font-medium cursor-pointer text-blue-600 dark:text-blue-500 hover:underline">{params.value}</NavLink>
      )
    },
    { 
      headerName: 'Status', 
      field: 'status', 
      sortable: true, 
      filter: 'agSetColumnFilter', 
      floatingFilter: true,
      minWidth: 170,
      enableRowGroup: true,
      cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      cellRenderer: (params: any) => (
        <Badge color={getBadgeColor(params.value, 'status')}>
          {params.value}
        </Badge>
      )
    },
    { 
      headerName: 'Teuri', 
      field: 'teuCount', 
      sortable: true, 
      filter: 'agNumberColumnFilter', 
      floatingFilter: true, 
      minWidth: 100,
      enableRowGroup: true,
    },
    { 
      headerName: '20\'', 
      field: 'twentyContainersCount', 
      sortable: true, 
      filter: 'agNumberColumnFilter', 
      floatingFilter: true, 
      minWidth: 80,
      enableRowGroup: true,
    },
    { 
      headerName: '40\'', 
      field: 'fourtyContainersCount', 
      sortable: true, 
      filter: 'agNumberColumnFilter', 
      floatingFilter: true, 
      minWidth: 80,
      enableRowGroup: true,
    },
    { 
      headerName: '45\'', 
      field: 'fourtyFiveContainersCount', 
      sortable: true, 
      filter: 'agNumberColumnFilter', 
      floatingFilter: true, 
      minWidth: 80,
      enableRowGroup: true,
    },
    { 
      headerName: `B/L's`, 
      field: 'bookingBills', 
      sortable: true, 
      filter: 'agTextColumnFilter', 
      floatingFilter: true,
      minWidth: 170,
      valueFormatter: (params: any) => (params.data.bookingBills.map((bl : any) => bl.code).join(', ')),
      getQuickFilterText: (params: any) => {
        return params.data.bookingBills.map((bl: any) => bl.code).join(', ');
      },
      filterValueGetter: (params: any) => {
        return params.data.bookingBills.map((bl: any) => bl.code).join(', ');
      }
    },
    { 
      headerName: 'F/V', 
      field: 'lastVesselCode', 
      sortable: true, 
      filter: 'agTextColumnFilter', 
      floatingFilter: true,
      enableRowGroup: true,
      minWidth: 170
    },
    { 
      headerName: 'M/V', 
      field: 'motherVesselCode', 
      sortable: true, 
      filter: 'agTextColumnFilter', 
      floatingFilter: true,
      enableRowGroup: true,
      minWidth: 170
    },
    { 
        headerName: 'Tip transport', 
        field: 'shippingType', 
        sortable: true, 
        filter: 'agSetColumnFilter', 
        floatingFilter: true,
        minWidth: 170,
        enableRowGroup: true,
        cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
        cellRenderer: (params: any) => (
            <Badge color={getBadgeColor(params.value, 'shippingType')}>
              {params.value}
            </Badge>
        )
    },
    { 
        headerName: 'Tip încărcare', 
        field: 'loadType', 
        sortable: true, 
        filter: 'agSetColumnFilter', 
        floatingFilter: true,
        minWidth: 170,
        enableRowGroup: true,
        cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
        cellRenderer: (params: any) => (
            <Badge color={getBadgeColor(params.value, 'loadType')}>
              {params.value}
            </Badge>
        )
    },
    { 
        headerName: 'Tip', 
        field: 'bookingType', 
        sortable: true, 
        filter: 'agSetColumnFilter', 
        floatingFilter: true,
        minWidth: 170,
        enableRowGroup: true,
        cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
        cellRenderer: (params: any) => (
            <Badge color={getBadgeColor(params.value, 'bookingType')}>
              {params.value}
            </Badge>
        )
      },
      { 
        headerName: 'Linie', 
        field: 'shippingLineName', 
        sortable: true, 
        filter: 'agSetColumnFilter', 
        floatingFilter: true,
        minWidth: 170,
        hide: true
      },
      {
        headerName: 'Dată',
        field: 'creationDate',
        minWidth: 170,
        filter: CustomDateComponent,
        floatingFilterComponent: DateFloatingFilter,
        filterParams: {
          field: 'creationDate',
          browserDatePicker: true,
        },
        floatingFilter: true,
        sortable: true,
        enableRowGroup: true,
        cellRenderer: (params: any) => (
          <div>{params.value ? formatDate(params.value) : ''}</div>
        )
      },
      { 
          headerName: 'Sales', 
          field: 'user.name', 
          sortable: true, 
          filter: 'agSetColumnFilter', 
          floatingFilter: true,
          enableRowGroup: true,
          minWidth: 170
      },
      { 
          headerName: 'Client', 
          field: 'client.company', 
          sortable: true, 
          filter: 'agSetColumnFilter', 
          floatingFilter: true,
          enableRowGroup: true,
          minWidth: 170
      },
      { 
          headerName: 'TradeID', 
          field: 'tradeId', 
          sortable: true, 
          filter: 'agTextColumnFilter', 
          floatingFilter: true,
          enableRowGroup: true,
          minWidth: 140
      },
      {
          headerName: 'Avizare exp.', 
          field: 'avizareExport', 
          sortable: true, 
          filter: 'agSetColumnFilter', 
          floatingFilter: true,
          enableRowGroup: true,
          minWidth: 170,
          cellRenderer: (params: any) => {
            // Pentru bookingurile care nu sunt de export, afișează string gol
            if (!params.data.shippingType?.includes('Export')) {
              return '';
            }
            if(params.value === 'Trimis'){
              return <Button size="sm" className="mx-auto bg-green-500" onClick={() => onExportNotice(params.data.id, params.data.bookingEmail, params.data.number)}>{params.value}</Button>
            }
            return <Button size="sm" className="mx-auto" onClick={() => onExportNotice(params.data.id, params.data.bookingEmail, params.data.number)}>Trimite avizare</Button>
          }
        },
      {
          headerName: 'Actiuni',
          field: 'id',
          minWidth: 130,
          cellRenderer: (params: any) => (
                <div>
                    <Actions id={params.data.id} onEdit={() => onEdit(params.data.id)} onDelete={() => onDelete(params.data.id)} moduleName="booking" />
                </div>
              )
      } 
    ], []);

  return (
    <>
      <AgGridReact 
        className="tabel-agGrid"
        localeText={AG_GRID_LOCALE_RO}
        columnDefs={columns} 
        rowData={bookings}
        defaultColDef={{ flex: 1, minWidth: 20 }}
        domLayout='autoHeight'
        pagination={pagination}
        paginationPageSize={paginationPageSize}
        paginationPageSizeSelector={paginationPageSizeSelector}
        gridOptions={gridOptions}
        enableCharts={true}
        enableRangeSelection={true}
        sideBar={true}
        onGridReady={onGridReady}
        rowGroupPanelShow="always"
        groupDisplayType="groupRows"
        rowSelection={'multiple'}
        components={components}
      />
    </>
  );
};

export default Table;

const getBadgeColor = (value: string, column: string): string => {
    const colorMap: { [key: string]: { [key: string]: string } } = {
      status: {
        'Anulat': 'gray',
        'Confirmat': 'yellow',
        'În tranzit(mother vessel)': 'cyan',
        'În tranzit(marea neagră)': 'blue',
        'Preavizat': 'indigo',
        'Avizat': 'purple',
        'Descărcat': 'green',
        'Eliberat de vamă': 'pink',
        'În livrare': 'warning',
        'Finalizat': 'green',
        // export
        'Creat': 'yellow',
        'Introducere VGM': 'indigo',
        'Încărcat': 'pink',
      },
      shippingType: {
        'Export CIF': 'purple',
        'Export FOB': 'warning',
        'Import FOB': 'info',
        'Import CIF': 'success'
      },
      loadType: {
        'FCL': 'info',
        'LCL': 'warning'
      },
      bookingType: {
        'Forwarding': 'purple',
        'Linie': 'success'
      }
    };
  
    return colorMap[column][value] || 'default';
  };
  