import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'flowbite-react';
import { errorMessage, successMessage } from '../../../functions/generalHelper';
import Input from '../../../components/Input';
import { defaultBookingBill, ViewBookingBill } from '../../../interfaces/Booking';
import { handleInputChange } from '../../../functions/selectHelper';
import { BookingService } from '../../../services/BookingService';
import CustomDatePicker from '../../../components/CustomDatePicker';
import { getDateFormat } from '../../../functions/dateHelper';
import TextArea from '../../../components/Textarea';

interface BillModalProps {
    bookingBill: ViewBookingBill;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    onSave: (bill : ViewBookingBill, create : boolean) => void;
    bookingId: number;
}

const BillModal: React.FC<BillModalProps> = ({ bookingBill, showModal, setShowModal, onSave, bookingId }) => {
    const [bill, setBill] = useState<ViewBookingBill>(defaultBookingBill as ViewBookingBill);
    const bookingService = new BookingService();

    useEffect(() => {
        if (!showModal) {
            setBill(defaultBookingBill as ViewBookingBill);
        }else{
            if(bookingBill.id){
                setBill(bookingBill);
            }
        }
    }, [showModal, bookingBill]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const newBill = await bookingService.createOrUpdateBookingBill({...bill, bookingId: bookingId});
            onSave(newBill, bill.id ? false : true);
            setShowModal(false);
            successMessage('B/L salvat cu succes!');
        } catch (error) {
            errorMessage('A aparut o eroare!');
        }
    };

    return (
        <Modal show={showModal} size="5xl" popup onClose={() => setShowModal(false)}>
            <Modal.Header>Adaugă B/L</Modal.Header>
            <Modal.Body className="overflow-auto">
                <div className="space-y-4 pt-5">
                    <form onSubmit={handleSubmit}>
                        {/* Basic Information Section */}
                        <div className="mb-6">
                            <h3 className="text-lg font-medium text-gray-900 mb-4">Informații de bază</h3>
                            <div className="gap-4 grid grid-cols-4">
                                <Input 
                                    id="code"
                                    label="Număr B/L"
                                    value={bill.code}
                                    onChange={(e) => handleInputChange(bill, setBill, 'code', e.target.value, defaultBookingBill as ViewBookingBill)}
                                />
                                <div>
                                    <CustomDatePicker
                                        selected={bill.date ? new Date(bill.date) : null}
                                        onChange={(date) => setBill(prev => ({ ...prev, date: date }))}
                                        dateFormat={getDateFormat()}
                                        locale="ro"
                                        isClearable
                                        placeholderText="Data"
                                        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                                <Input 
                                    id="terminal"
                                    label="Terminal"
                                    value={bill.terminal}
                                    onChange={(e) => handleInputChange(bill, setBill, 'terminal', e.target.value, defaultBookingBill as ViewBookingBill)}
                                />
                                <Input 
                                    id="releaseType"
                                    label="Tip eliberare"
                                    value={bill.releaseType}
                                    onChange={(e) => handleInputChange(bill, setBill, 'releaseType', e.target.value, defaultBookingBill as ViewBookingBill)}
                                />
                            </div>
                        </div>

                        {/* Cargo Information Section */}
                        <div className="mb-6">
                            <h3 className="text-lg font-medium text-gray-900 mb-4">Informații marfă</h3>
                            <div className="gap-4 grid grid-cols-3 mb-4">
                                <TextArea 
                                    id="commodity"
                                    label="Marfă B/L"
                                    value={bill.commodity}
                                    rows={3}
                                    onChange={(e) => handleInputChange(bill, setBill, 'commodity', e.target.value, defaultBookingBill as ViewBookingBill)}
                                />
                                <TextArea 
                                    id="shortCommodity"
                                    label="Marfă prescurtată"
                                    value={bill.shortCommodity}
                                    rows={3}
                                    onChange={(e) => handleInputChange(bill, setBill, 'shortCommodity', e.target.value, defaultBookingBill as ViewBookingBill)}
                                />
                                <TextArea 
                                    id="translatedCommodity"
                                    label="Marfă tradusă"
                                    value={bill.translatedCommodity}
                                    rows={3}
                                    onChange={(e) => handleInputChange(bill, setBill, 'translatedCommodity', e.target.value, defaultBookingBill as ViewBookingBill)}
                                />
                            </div>
                            <div className="gap-4 grid grid-cols-2">
                                <Input 
                                    id="hsCode"
                                    label="Cod HS"
                                    value={bill.hsCode}
                                    onChange={(e) => handleInputChange(bill, setBill, 'hsCode', e.target.value, defaultBookingBill as ViewBookingBill)}
                                />
                                <Input 
                                    id="loadingPlace"
                                    label="Loc încărcare"
                                    value={bill.loadingPlace}
                                    onChange={(e) => handleInputChange(bill, setBill, 'loadingPlace', e.target.value, defaultBookingBill as ViewBookingBill)}
                                />
                            </div>
                        </div>

                        {/* Shipper Information Section */}
                        <div className="mb-6">
                            <h3 className="text-lg font-medium text-gray-900 mb-4">Informații shipper</h3>
                            <div className="gap-4 grid grid-cols-2">
                                <div className="space-y-4">
                                    <Input 
                                        id="shipperName"
                                        label="Nume shipper"
                                        value={bill.shipperName}
                                        onChange={(e) => handleInputChange(bill, setBill, 'shipperName', e.target.value, defaultBookingBill as ViewBookingBill)}
                                    />
                                    <Input 
                                        id="shipperAddress"
                                        label="Adresă shipper"
                                        value={bill.shipperAddress}
                                        onChange={(e) => handleInputChange(bill, setBill, 'shipperAddress', e.target.value, defaultBookingBill as ViewBookingBill)}
                                    />
                                </div>
                                <div className="space-y-4">
                                    <Input 
                                        id="shipperPhone"
                                        label="Telefon shipper"
                                        value={bill.shipperPhone}
                                        onChange={(e) => handleInputChange(bill, setBill, 'shipperPhone', e.target.value, defaultBookingBill as ViewBookingBill)}
                                    />
                                    <Input 
                                        id="shipperEmail"
                                        label="Email shipper"
                                        value={bill.shipperEmail}
                                        onChange={(e) => handleInputChange(bill, setBill, 'shipperEmail', e.target.value, defaultBookingBill as ViewBookingBill)}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Consignee Information Section */}
                        <div className="mb-6">
                            <h3 className="text-lg font-medium text-gray-900 mb-4">Informații consignee</h3>
                            <div className="gap-4 grid grid-cols-2">
                                <div className="space-y-4">
                                    <Input 
                                        id="consigneeName"
                                        label="Nume consignee"
                                        value={bill.consigneeName}
                                        onChange={(e) => handleInputChange(bill, setBill, 'consigneeName', e.target.value, defaultBookingBill as ViewBookingBill)}
                                    />
                                    <Input 
                                        id="consigneeAddress"
                                        label="Adresă consignee"
                                        value={bill.consigneeAddress}
                                        onChange={(e) => handleInputChange(bill, setBill, 'consigneeAddress', e.target.value, defaultBookingBill as ViewBookingBill)}
                                    />
                                </div>
                                <div className="space-y-4">
                                    <Input 
                                        id="consigneePhone"
                                        label="Telefon consignee"
                                        value={bill.consigneePhone}
                                        onChange={(e) => handleInputChange(bill, setBill, 'consigneePhone', e.target.value, defaultBookingBill as ViewBookingBill)}
                                    />
                                    <Input 
                                        id="consigneeEmail"
                                        label="Email consignee"
                                        value={bill.consigneeEmail}
                                        onChange={(e) => handleInputChange(bill, setBill, 'consigneeEmail', e.target.value, defaultBookingBill as ViewBookingBill)}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Notify Information Section */}
                        <div className="mb-6">
                            <h3 className="text-lg font-medium text-gray-900 mb-4">Informații notify</h3>
                            <div className="gap-4 grid grid-cols-2">
                                <div className="space-y-4">
                                    <Input 
                                        id="notifyName"
                                        label="Nume notify"
                                        value={bill.notifyName}
                                        onChange={(e) => handleInputChange(bill, setBill, 'notifyName', e.target.value, defaultBookingBill as ViewBookingBill)}
                                    />
                                    <Input 
                                        id="notifyAddress"
                                        label="Adresă notify"
                                        value={bill.notifyAddress}
                                        onChange={(e) => handleInputChange(bill, setBill, 'notifyAddress', e.target.value, defaultBookingBill as ViewBookingBill)}
                                    />
                                </div>
                                <div className="space-y-4">
                                    <Input 
                                        id="notifyPhone"
                                        label="Telefon notify"
                                        value={bill.notifyPhone}
                                        onChange={(e) => handleInputChange(bill, setBill, 'notifyPhone', e.target.value, defaultBookingBill as ViewBookingBill)}
                                    />
                                    <Input 
                                        id="notifyEmail"
                                        label="Email notify"
                                        value={bill.notifyEmail}
                                        onChange={(e) => handleInputChange(bill, setBill, 'notifyEmail', e.target.value, defaultBookingBill as ViewBookingBill)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-end">
                            <Button size="sm" type="submit">Salvează</Button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default BillModal;
