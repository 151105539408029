import React, { useState, useCallback, memo } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField as TimeField } from '@mui/x-date-pickers/DateField';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ro';

interface DateFieldProps {
  selected: Date | null;
  onChange: (date: Date | null) => void;
  label?: string;
  className?: string;
}

const DateField: React.FC<DateFieldProps> = memo(({
  selected,
  onChange,
  label="Data",
  className,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = useCallback(
    (newDateTime: Dayjs | null) => {
      onChange(newDateTime?.toDate() ?? null);
    },
    [onChange]
  );

  const handleFocus = useCallback(() => setIsFocused(true), []);
  const handleBlur = useCallback(() => setIsFocused(false), []);

  const isFilled = !!selected;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ro">
      <div className={`floating-label-timepicker ${className}`}>
        <TimeField
          value={selected ? dayjs(selected) : null}
          onChange={handleChange}
          format="DD/MM/YYYY"
          onFocus={handleFocus}
          onBlur={handleBlur}
          sx={{ width: '100%' }}
          size="small"
          {...rest}
        />
        <label className={`floating-label ${isFilled ? 'filled' : ''} ${isFocused ? 'focused' : ''}`}>
          {label}
        </label>
      </div>
    </LocalizationProvider>
  );
});

DateField.displayName = 'DateField';

export default DateField;