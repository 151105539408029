import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'flowbite-react';
import { NavLink, useNavigate } from 'react-router-dom';
import { BookingBill, defaultBookingBill } from '../../../interfaces/Booking';
import { BookingService } from '../../../services/BookingService';
import { formatDate, getDateFormat } from '../../../functions/dateHelper';
import ArrivalNoticeModal from './ArrivalNoticeModal';
import Loader from '../../../components/CustomLoader';
import { Package2, Building2, Truck, Box, FileText, Container } from 'lucide-react';

interface ViewBillProps {
    id: number;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ViewBill: React.FC<ViewBillProps> = ({ id, showModal, setShowModal }) => {
    const [bill, setBill] = useState<BookingBill>(defaultBookingBill as BookingBill);
    const [loading, setLoading] = useState<boolean>(false);
    const bookingService = new BookingService();
    const [showArrivalNoticeModal, setShowArrivalNoticeModal] = useState<boolean>(false);
    const [documentType, setDocumentType] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        if (showModal) {
            setLoading(true);
            bookingService.getBookingBillById(id).then((data) => {
                setBill(data);
                setLoading(false);
            });
        }
    }, [showModal, id]);

    // @ts-ignore
    const Card = ({ title, icon: Icon, children, className = "", contentPadding = "p-5" }) => (
        <div className={`bg-white rounded-lg border border-gray-200 ${className}`}>
            <div className="px-5 py-3 border-b border-gray-200">
                <div className="flex items-center gap-2">
                    <Icon className="w-4 h-4 text-gray-500" />
                    <h2 className="font-medium text-gray-900">{title}</h2>
                </div>
            </div>
            <div className={contentPadding}>
                {children}
            </div>
        </div>
    );

    const InfoItem = ({ label, value }: { label: string; value?: any }) => (
        <div className="space-y-1">
            <p className="text-xs font-medium text-gray-500">{label}</p>
            <p className="text-sm text-gray-900">{value || '-'}</p>
        </div>
    );

    const ActionButtons = () => (
        <div className="flex">
            <div className="flex justify-start items-center gap-2 w-full">
                <Button size="sm" onClick={() => {setShowArrivalNoticeModal(true); setDocumentType('arrivalNotice')}}>
                    Preavizare
                </Button>
                <Button size="sm" onClick={() => {setShowArrivalNoticeModal(true); setDocumentType('arrivalUpdate')}}>
                    Avizare
                </Button>
                <Button size="sm" onClick={() => {setShowArrivalNoticeModal(true); setDocumentType('deliveryOrder')}}>
                    Liber
                </Button>
            </div>
        </div>
    );

    return (
        <>
            <Loader isLoading={loading} />
            <ArrivalNoticeModal 
                setDocumentType={setDocumentType} 
                documentType={documentType} 
                type="bill" 
                ids={id ? [id] : undefined} 
                showModal={showArrivalNoticeModal} 
                setShowModal={setShowArrivalNoticeModal} 
            />
            <Modal show={showModal} size="full" popup dismissible onClose={() => setShowModal(false)}>
                <Modal.Header>B/L Details - {bill.code}</Modal.Header>
                <Modal.Body>
                    <div className="space-y-6 p-2">
                        {/* Top Grid - 4 columns */}
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                            {/* Basic Info */}
                            <Card title="B/L Information" icon={FileText}>
                                <div className="grid grid-cols-2 gap-4">
                                    <InfoItem label="B/L Number" value={bill.code} />
                                    {
                                        bill.booking ? <>
                                            <InfoItem 
                                                label="Booking Number" 
                                                value={
                                                    <NavLink 
                                                        to={`/bookings/view/${bill.booking.id}`} 
                                                        className="font-medium cursor-pointer text-blue-600 hover:underline"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setShowModal(false);
                                                            // Folosim un timeout mic pentru a ne asigura că modal-ul s-a închis
                                                            setTimeout(() => {
                                                                navigate(`/bookings/view/${bill.booking!.id}`);
                                                            }, 50);
                                                        }}
                                                    >
                                                        {bill.booking.number}
                                                    </NavLink>
                                                }
                                            />
                                        </> : <></>
                                    }
                                    <InfoItem label="Terminal" value={bill.terminal} />
                                    <InfoItem label="Date" value={bill.date ? formatDate(new Date(bill.date), getDateFormat()) : '-'} />
                                    <div className="col-span-2">
                                        <ActionButtons />
                                    </div>
                                </div>
                            </Card>

                            {/* Shipper Info */}
                            <Card title="Shipper Details" icon={Building2}>
                                <div className="space-y-4">
                                    <InfoItem label="Name" value={bill.shipperName} />
                                    <InfoItem label="Address" value={bill.shipperAddress} />
                                    <InfoItem label="Phone" value={bill.shipperPhone} />
                                    <InfoItem label="Email" value={bill.shipperEmail} />
                                </div>
                            </Card>

                            {/* Consignee Info */}
                            <Card title="Consignee Details" icon={Building2}>
                                <div className="space-y-4">
                                    <InfoItem label="Name" value={bill.consigneeName} />
                                    <InfoItem label="Address" value={bill.consigneeAddress} />
                                    <InfoItem label="Phone" value={bill.consigneePhone} />
                                    <InfoItem label="Email" value={bill.consigneeEmail} />
                                </div>
                            </Card>

                            {/* Notify Info */}
                            <Card title="Notify Details" icon={Building2}>
                                <div className="space-y-4">
                                    <InfoItem label="Name" value={bill.notifyName} />
                                    <InfoItem label="Address" value={bill.notifyAddress} />
                                    <InfoItem label="Phone" value={bill.notifyPhone} />
                                    <InfoItem label="Email" value={bill.notifyEmail} />
                                </div>
                            </Card>
                        </div>

                        {/* Commodity Info */}
                        <Card title="Commodity Information" icon={Box}>
                            <div className="grid grid-cols-3 gap-6">
                                <InfoItem label="B/L Commodity" value={bill.commodity} />
                                <InfoItem label="Short Commodity" value={bill.shortCommodity} />
                                <InfoItem label="Translated Commodity" value={bill.translatedCommodity} />
                            </div>
                        </Card>

                        {/* Containers Table */}
                        <Card title="Containers" icon={Container} contentPadding="p-0">
                            <div className="overflow-x-auto">
                                <table className="w-full">
                                    <thead>
                                        <tr className="bg-gray-50 border-b border-gray-200">
                                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500">Container</th>
                                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500">Size</th>
                                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500">Type</th>
                                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500">Seal</th>
                                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500">Weight</th>
                                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500">Volume</th>
                                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500">Free Days</th>
                                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500">Danger</th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                        {bill.containers?.map((container, index) => (
                                            <tr key={index} className="hover:bg-gray-50">
                                                <td className="px-4 py-3 text-sm">{container.containerNumber}</td>
                                                <td className="px-4 py-3 text-sm">{container.size}</td>
                                                <td className="px-4 py-3 text-sm">{container.type}</td>
                                                <td className="px-4 py-3 text-sm">{container.sealNumber}</td>
                                                <td className="px-4 py-3 text-sm">{container.weight}</td>
                                                <td className="px-4 py-3 text-sm">{container.volume}</td>
                                                <td className="px-4 py-3 text-sm">{container.freeDays}</td>
                                                <td className="px-4 py-3">
                                                    <span className={`px-2 py-1 rounded text-xs font-medium ${
                                                        container.dangerCargo ? 'bg-red-100 text-red-600' : 'bg-green-100 text-green-600'
                                                    }`}>
                                                        {container.dangerCargo ? 'Da' : 'Nu'}
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Card>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ViewBill;