import { Client } from '../interfaces/Client';
import { formatDateForBackend } from '../functions/dateHelper';
import client from '../apollo-client';
import { gql } from '@apollo/client';
import { ArrivalNotice, BillCheck, Booking, BookingBill, BookingBillTable, BookingHistory, CargoManifest, EmailQueue, ViewBooking, ViewBookingBill, ViewContainer } from '../interfaces/Booking';
import { errorMessage, removeTypename } from '../functions/generalHelper';
import { FileService } from './FileService';
import { plainToClass } from 'class-transformer';
import { BookingCreateForm, BookingUpdateForm, CreateBooking } from '../validations/booking/CreateBooking';
import { validateOrReject } from 'class-validator';
import 'reflect-metadata';
import { getAllErrorMessages } from '../functions/validationHelper';
import { CreateBookingContainerBooking, UpdateBookingContainerBooking } from '../validations/booking/CreateBookingContainerBooking';

export class BookingService {

    private async validateBooking(booking: Partial<BookingCreateForm>): Promise<void> {
        const bookingValidation = plainToClass(CreateBooking, booking);
        try {
          await validateOrReject(bookingValidation);
        } catch (errors) {
          if (Array.isArray(errors)) {
              const messages = getAllErrorMessages(errors);
              throw new Error(messages.map(msg => `• ${msg}`).join('\n'));
          }
          throw errors;
        }
    }
  
    async getClients(): Promise<Client[]> {
        const response = await client.query({
            query: gql`
                query {
                    clients {
                        id
                        company
                        email
                        tradeId
                        bookingEmail
                        userId
                        contacts {
                          email
                        }
                    }
                }
            `,
        });
        return response.data.clients.map((client: Client) => ({
            id: client.id,
            company: client.company,
            tradeId: client.tradeId,
            userId : client.userId,
            email: client.bookingEmail ? client.bookingEmail : client.email || (client.contacts && client.contacts.length > 0 ? client.contacts[0].email : '')
        }));
    }

    async getClientsWithData(): Promise<Partial<Client>[]> {
        const response = await client.query({
            query: gql`
                query {
                    clients {
                        id
                        company
                        gstNumber
                        country
                        state
                        city
                        street
                        minimalAddress
                        fullAddress
                        postalCode
                        phone
                        email
                        contacts {
                            email
                        }
                    }
                }
            `,
        });
        return response.data.clients.map((client: Client) => ({
            id: client.id,
            company: client.company,
            gstNumber: client.gstNumber,
            country: client.country,
            state: client.state,
            city: client.city,
            street: client.street,
            minimalAddress: client.minimalAddress,
            postalCode: client.postalCode,
            phone: client.phone,
            email: client.email || (client.contacts && client.contacts.length > 0 ? client.contacts[0].email : '')
        }));
    }

    async getBookings(): Promise<Partial<Booking>[]>{
      const response = await client.query({
          query: gql`
              query {
                  bookings {
                      id
                      number
                      status
                      shippingType
                      loadType
                      bookingType
                      shippingLineName
                      forwarderName
                      creationDate
                      teuCount
                      lastVesselCode
                      motherVesselCode
                      tradeId
                      terminal
                      twentyContainersCount
                      fourtyContainersCount
                      fourtyFiveContainersCount
                      avizareExport
                      bookingEmail
                      client {
                        id
                        company
                      }
                      pod
                      pol
                      user {
                        id
                        name
                      }
                      bookingBills {
                        id
                        code
                      }
                  }
              }
          `,
      });
      return response.data.bookings.map((booking: Booking) => ({
          ...booking,
          creationDate: booking.creationDate ? new Date(booking.creationDate).toISOString() : null
          // creationDate: booking.creationDate ? new Date(booking.creationDate) : null
      }));
    }

    async create(booking: Partial<BookingCreateForm>): Promise<{status: string; message: string;}> {
      try {
        await this.validateBooking(booking);
        const response = await client.mutate({
          mutation: gql`
            mutation createBooking($input: CreateBookingDto!){
                createBooking(input: $input){
                  status
                  message
                }
            }
          `,
          variables: {
            input: removeTypename(booking)
          }
        });
        return response.data.createBooking;
      } catch (error) {
        if (error instanceof Error) {
          errorMessage(error.message);
        } else {
          errorMessage('A apărut o eroare neașteptată!');
        }
        throw error;
      }
    }

    async updateExport(booking: Partial<BookingUpdateForm>): Promise<{status: string; message: string;}> {
      try {
        if(!booking.shippingType?.includes('Export')){
          return { status: 'error', message: 'Momentan functia de salvare este accesibila numai pentru bookingurile de export!' };
        }
        await this.validateBooking(booking);
        const { routes, bookingBills, containers, ...bookingData } = booking;
        const response = await client.mutate({
          mutation: gql`
            mutation updateExport($input: UpdateBookingDto!){
                updateExport(input: $input){
                  status
                  message
                }
            }
          `,
          variables: {
            input: {
              ...removeTypename(bookingData),
              routes: routes!.map((route) => removeTypename(route)),
              bookingBills: bookingBills!.map((bookingBill) => removeTypename(bookingBill)),
              containers: containers!.map((container) => removeTypename(container)),
            }
          }
        });
        return response.data.updateExport;
      } catch (error) {
        if (error instanceof Error) {
          errorMessage(error.message);
        } else {
          errorMessage('A apărut o eroare neașteptată!');
        }
        throw error;
      }
    }

    async update(booking: Booking): Promise<Booking> {
        const response = await client.mutate({
          mutation: gql`
            mutation updateBooking($input: UpdateBookingDto!){
                updateBooking(input: $input){
                  id
                }
            }
          `,
          variables: {
            input: {
              id: booking.id,
              number: booking.number,
              status: booking.status,
              offerId: booking.offerId,
              clientId: booking.clientId,
              shippingType: booking.shippingType,
              loadType: booking.loadType,
              bookingType: booking.bookingType,
              shippingLineName: booking.shippingLineName,
              svcTermOrigin: booking.svcTermOrigin,
              svcTermDestination: booking.svcTermDestination,
              creationDate: booking.creationDate,
              createdBy: booking.createdBy,
              blockStatus: booking.blockStatus,
              waiting: booking.waiting,
              cancelReason: booking.cancelReason,
              cancelledDate: booking.cancelledDate,
              blockStowage: booking.blockStowage,
              forwarderName: booking.forwarderName,
              forwarderAddress: booking.forwarderAddress,
              forwarderPhone: booking.forwarderPhone,
              forwarderEmail: booking.forwarderEmail,
              bookingEmail: booking.bookingEmail,
            }
          }
        });
        return response.data.updateBooking;
    }
    
    async deleteBooking(id: number): Promise<boolean> {
      const response = await client.mutate({
        mutation: gql`
          mutation {
            deleteBooking(id: ${id})
          }
        `,
      });
      return response.data.deleteBooking;
    }
    
    async getBookingById(id: number): Promise<ViewBooking> {
        const response = await client.query({
          query: gql`
            query {
              booking(id: ${id}) {
                id
                number
                status
                shippingType
                loadType
                bookingType
                shippingLineName
                creationDate
                cancelReason
                cancelledDate
                blockStatus
                waiting
                loadingPlace
                pol
                pod
                hsCode
                commodity
                note
                svcTermOrigin
                svcTermDestination
                shipperName
                shipperAddress
                consigneeName
                consigneeAddress
                createdBy
                clientId
                offerId
                createdAt
                updatedAt
                terminal
                bookingEmail

                client {
                  id
                  company
                }

                routes {
                  id
                  from
                  to
                  vesselCode
                  vesselName
                  etd
                  atd
                  eta
                  ata
                }

                bookingBills{
                  id
                  bookingId
                  code
                  shipperName
                  shipperAddress
                  shipperPhone
                  shipperEmail
                  consigneeName
                  consigneeAddress
                  consigneePhone
                  consigneeEmail
                  notifyName
                  notifyAddress
                  notifyPhone
                  notifyEmail
                  date
                  terminal
                  commodity
                  translatedCommodity
                  shortCommodity
                  releaseType
                  hsCode
                  loadingPlace
                  
                  cargos {
                    id
                    bookingContainerId
                    bookingBillId
                    hsCode
                    packageNumber
                    packageType
                    weight
                    volume
                  }
                }

                movements{
                  id
                  movement
                  port
                  containerCode
                  vesselCode
                  vesselName
                  date
                  action
                }
                
                containers {
                  id
                  containerNumber
                  size
                  type
                  iso
                  freeDays
                  weight
                  vgmNumber
                  volume
                  sealNumber
                  packageNumber
                  mrnNumber
                  riNumber
                  customsNumber
                  arrivalCarNumber
                  departureCarNumber
                  reeferTemp
                  imoDetails
                  oogDims
                  remarks
                  dangerCargo
                  soc
                  bookingId
                  estVgm
                  shipperName
                  consigneeName
                  loadingPlace
                  note
                  commodity
                  hsCode
                  noticeStatus
                  isRail
                  fullIn
                  pDate
                  aDate
                  detention
                  shippingCompany
                  car
                }
                user {
                  id
                  name
                  email
                }
              }
            }
          `,
        });
    
        const bookingData = response.data.booking;

        const { cargoManifests, bookingBills } = bookingData.bookingBills.reduce((acc : any, bill : any) => {
          const { cargos, ...billData } = bill;
          acc.cargoManifests.push(...cargos);
          acc.bookingBills.push(billData);
          return acc;
        }, { cargoManifests: [], bookingBills: [] });

        // Construct the final Booking object
        const booking: ViewBooking = {
          ...bookingData,
          // bls: bookingData.bookingBills.map((bl : any) => bl.code),
          creationDate: bookingData.creationDate ? new Date(bookingData.creationDate) : null,
          containers: bookingData.containers,
          bookingBills : bookingBills.map((bill : any) => ({
            ...bill,
            date: bill.date ? new Date(bill.date) : null
          })),
          cargoManifests,
          client: bookingData.client,
        };
        return booking;
    }
    
    async getBookingForEdit(id: number) : Promise<BookingUpdateForm>{
      const response = await client.query({
        query: gql`
          query {
            findOneForEdit(id: ${id}) {
              id
              number
              status
              shippingType
              loadType
              bookingType
              shippingLineName
              creationDate
              cancelReason
              cancelledDate
              blockStatus
              waiting
              loadingPlace
              pol
              pod
              hsCode
              commodity
              note
              svcTermOrigin
              svcTermDestination
              shipperName
              shipperAddress
              consigneeName
              consigneeAddress
              clientId
              offerId
              createdBy
              terminal
              bookingEmail
              routes {
                id
                from
                to
                vesselCode
                vesselName
                etd
                atd
                eta
                ata
              }
              
              bookingBills {
                id
                code
                date
                terminal
                releaseType
                shipperName
                shipperAddress
                consigneeName
                consigneeAddress
                hsCode
                loadingPlace
                commodity
              }
              
              containers {
                id
                containerNumber
                size
                type
                iso
                freeDays
                weight
                vgmNumber
                volume
                sealNumber
                packageNumber
                mrnNumber
                riNumber
                customsNumber
                arrivalCarNumber
                departureCarNumber
                reeferTemp
                imoDetails
                oogDims
                remarks
                dangerCargo
                soc
                bookingId
                estVgm
                shipperName
                consigneeName
                loadingPlace
                note
                commodity
                hsCode
                noticeStatus
                isRail
                fullIn
                pDate
                aDate
                detention
                shippingCompany
                car
              }
            }
          }
        `,
      });
      return {...response.data.findOneForEdit,
        creationDate: response.data.findOneForEdit.creationDate ? new Date(response.data.findOneForEdit.creationDate) : null,
        cancelledDate: response.data.findOneForEdit.cancelledDate ? new Date(response.data.findOneForEdit.cancelledDate) : null,
        routes: response.data.findOneForEdit.routes.map((route : any) => ({
          ...route,
          etd: route.etd ? new Date(route.etd) : null,
          atd: route.atd ? new Date(route.atd) : null,
          eta: route.eta ? new Date(route.eta) : null,
          ata: route.ata ? new Date(route.ata) : null
        })),
        bookingBills: response.data.findOneForEdit.bookingBills.map((bill : any) => ({
          ...bill,
          date: bill.date ? new Date(bill.date) : null
        })),
        containers: response.data.findOneForEdit.containers.map((container : any) => ({
          ...container,
          pDate: container.pDate ? new Date(container.pDate) : null,
          aDate: container.aDate ? new Date(container.aDate) : null
        }))
      };
    }

    async createMultipleContainers(containers: UpdateBookingContainerBooking[], bookingId: number): Promise<ViewContainer[]> {
      const newContainers = containers.map(container => Object.fromEntries(
        Object.entries(container).filter(([key]) => new CreateBookingContainerBooking().hasOwnProperty(key))
      ));
      const response = await client.mutate({
        mutation: gql`
          mutation createMultipleContainers($input: [CreateBookingContainerBookingDto!]!, $bookingId: Int!) {
            createMultipleContainers(input: $input, bookingId: $bookingId){
              status
              message
            }
          }
        `,
        variables: {
          input: newContainers.map(removeTypename),
          bookingId
        }
      });
      return response.data.createMultipleContainers;
    }

    async createBookingContainer(container: UpdateBookingContainerBooking, bookingId : number): Promise<ViewContainer> {
      // daca containerul are campuri suplimentare fata de UpdateBookingContainerBooking, scoate-le
      const newContainer = Object.fromEntries(
        Object.entries(container).filter(([key]) => new CreateBookingContainerBooking().hasOwnProperty(key))
      );
      const response = await client.mutate({
        mutation: gql`
          mutation createExport($input: CreateBookingContainerBookingDto!, $bookingId: Int!) {
            createExport(input: $input, bookingId: $bookingId){
              id
              bookingId
              containerNumber
              size
              type
              bls
              iso
              freeDays
              tare
              weight
              vgmNumber
              volume
              sealNumber
              packageNumber
              mrnNumber
              riNumber
              customsNumber
              arrivalCarNumber
              departureCarNumber
              reeferTemp
              imoDetails
              oogDims
              remarks
              dangerCargo
              soc
            }
          }
        `,
        variables: {
          input: removeTypename(newContainer),
          bookingId
        }
      });
      return response.data.createExport;
    }

    async updateBookingContainer(container: ViewContainer): Promise<ViewContainer> {
        const response = await client.mutate({
          mutation: gql`
            mutation updateBookingContainerExport($input: UpdateBookingContainerBookingDto!) {
              updateBookingContainerExport(input: $input) {
                id
                containerNumber
                size
                type
                iso
                freeDays
                weight
                vgmNumber
                volume
                sealNumber
                packageNumber
                mrnNumber
                riNumber
                customsNumber
                arrivalCarNumber
                departureCarNumber
                reeferTemp
                imoDetails
                oogDims
                remarks
                dangerCargo
                soc
                bookingId
                estVgm
                shipperName
                consigneeName
                loadingPlace
                note
                commodity
                hsCode
                noticeStatus
                isRail
                fullIn
                pDate
                aDate
                detention
                shippingCompany
                car
              }
            }
          `,
          variables: {
            input: {
              id: container.id !== 0 ? container.id : null,
              bookingId: container.bookingId,
              containerNumber: container.containerNumber,
              size: container.size ? `${container.size}` : null,
              type: container.type ? `${container.type}` : null,
              iso: container.iso ? `${container.iso}` : null,
              freeDays: container.freeDays ? +container.freeDays : null,
              weight: container.weight ? +container.weight : null,
              vgmNumber: container.vgmNumber ? +container.vgmNumber : null,
              volume: container.volume ? +container.volume : null,
              sealNumber: container.sealNumber ? `${container.sealNumber}` : null,
              packageNumber: container.packageNumber ? +container.packageNumber : null,
              mrnNumber: container.mrnNumber ? `${container.mrnNumber}` : null,
              riNumber: container.riNumber ? `${container.riNumber}` : null,
              customsNumber: container.customsNumber ? `${container.customsNumber}` : null,
              arrivalCarNumber: container.arrivalCarNumber ? `${container.arrivalCarNumber}` : null,
              departureCarNumber: container.departureCarNumber ? `${container.departureCarNumber}` : null,
              reeferTemp: container.reeferTemp ? `${container.reeferTemp}` : null,
              imoDetails: container.imoDetails ? `${container.imoDetails}` : null,
              oogDims: container.oogDims ? `${container.oogDims}` : null,
              remarks: container.remarks ? `${container.remarks}` : null,
              dangerCargo: container.dangerCargo ? true : false,
              soc: container.soc ? true : false,
            }
          }
        });
        return response.data.updateBookingContainerExport;
    }

    async createBookingCargo(cargo: CargoManifest): Promise<CargoManifest> {
        const response = await client.mutate({
          mutation: gql`
            mutation createBookingCargo($input: CreateCargoDto!) {
                createBookingCargo(input: $input){
                  id
                  bookingContainerId
                  bookingBillId
                  hsCode
                  packageNumber
                  packageType
                  weight
                  volume
                }
            }
          `,
          variables: {
            input: {
              ...removeTypename(cargo),
              id: cargo.id !== 0 ? cargo.id : null,
            }
          }
        });
        return response.data.createBookingCargo;
    }

    async changeBookingStatus(bookingId: number, newStatus: string): Promise<void> {
        const response = await client.mutate({
          mutation: gql`
            mutation {
                changeBookingStatus(
                  bookingId: ${bookingId},
                  newStatus: "${newStatus}",
                ){
                  status
                }
            }
          `,
        });
      return response.data.changeBookingStatus;
    }

    async sendArrivalNotice(arrivalNotice : ArrivalNotice): Promise<{errors: string[]; emails: {id: number; email: string|null; name: string|null; booking: string|null;}[];}> {
      const response = await client.mutate({
        mutation: gql`
          mutation sendArrivalNotice($input: CreateArrivalNoticeDto!){
              sendArrivalNotice(input: $input){
                errors
                emails{
                  id
                  email
                  name
                  booking
                }
              }
          }
        `,
        variables: {
          input: arrivalNotice
        }
      });
    return response.data.sendArrivalNotice;
    }

    async sendArrivalNoticeEmail(formData : { id: number; email: string | null; name: string | null; booking: string | null; }[]) : Promise<{status: string; message: string;}>{
      const cleanData = formData.map(({ id, email, name, booking }) => ({
        id,
        email,
        name,
        booking
      }));
    
      const response = await client.mutate({
        mutation: gql`
          mutation sendArrivalNoticeEmail($input: [SendArrivalNoticeDto!]!){
              sendArrivalNoticeEmail(input: $input){
                status
                message
              }
          }
        `,
        variables: {
          input: cleanData
        }
      });
      return response.data.sendArrivalNoticeEmail;
    }

    async getBookingBillById(id : number) : Promise<ViewBookingBill> {
      const response = await client.query({
        query: gql`
          query {
            bookingBill(id: ${id}) {
              id
              code
              shipperName
              shipperAddress
              shipperPhone
              shipperEmail
              consigneeName
              consigneeAddress
              consigneePhone
              consigneeEmail
              notifyName
              notifyAddress
              notifyPhone
              notifyEmail
              date
              terminal
              commodity
              translatedCommodity
              shortCommodity
              bookingContainers {
                containerNumber
                size
                type
                sealNumber
                weight
                volume
                freeDays
                dangerCargo
              }
              booking {
                id
                number
              }
            }
          }
        `,
      });
      return {
        ...response.data.bookingBill, 
        date: response.data.bookingBill.date ? new Date(response.data.bookingBill.date) : null,
        containers: response.data.bookingBill.bookingContainers
      };
    }

    async createOrUpdateBookingBill(bill : BookingBill) : Promise<ViewBookingBill>{
      const response = await client.mutate({
        mutation: gql`
          mutation createBookingBill($input: CreateBookingBillDto!){
              createBookingBill(input: $input){
                id
                bookingId
                code
                shipperName
                shipperAddress
                shipperPhone
                shipperEmail
                consigneeName
                consigneeAddress
                consigneePhone
                consigneeEmail
                notifyName
                notifyAddress
                notifyPhone
                notifyEmail
                date
                terminal
                commodity
                translatedCommodity
                shortCommodity
                releaseType
                hsCode
                loadingPlace
              }
          }
        `,
        variables: {
          input: {
            id: bill.id !== 0 ? bill.id : null,
            bookingId: bill.bookingId,
            date: bill.date ? formatDateForBackend(bill.date) : null,
            code: bill.code ? bill.code : null,
            shipperName: bill.shipperName ? bill.shipperName : null,
            shipperAddress: bill.shipperAddress ? bill.shipperAddress : null,
            shipperPhone: bill.shipperPhone ? bill.shipperPhone : null,
            shipperEmail: bill.shipperEmail ? bill.shipperEmail : null,
            consigneeName: bill.consigneeName ? bill.consigneeName : null,
            consigneeAddress: bill.consigneeAddress ? bill.consigneeAddress : null,
            consigneePhone: bill.consigneePhone ? bill.consigneePhone : null,
            consigneeEmail: bill.consigneeEmail ? bill.consigneeEmail : null,
            notifyName: bill.notifyName ? bill.notifyName : null,
            notifyAddress: bill.notifyAddress ? bill.notifyAddress : null,
            notifyPhone: bill.notifyPhone ? bill.notifyPhone : null,
            notifyEmail: bill.notifyEmail ? bill.notifyEmail : null,
            terminal: bill.terminal ? bill.terminal : null,
            commodity: bill.commodity ? bill.commodity : null,
            translatedCommodity: bill.translatedCommodity ? bill.translatedCommodity : null,
            shortCommodity: bill.shortCommodity ? bill.shortCommodity : null,
            releaseType: bill.releaseType ? bill.releaseType : null,
            hsCode: bill.hsCode ? bill.hsCode : null,
            loadingPlace: bill.loadingPlace ? bill.loadingPlace : null,
          }
        },
      });
      return {...response.data.createBookingBill, date: response.data.createBookingBill.date ? new Date(response.data.createBookingBill.date) : null};
    }

    async removeBookingBill(id: number): Promise<boolean> {
      const response = await client.mutate({
        mutation: gql`
          mutation {
            removeBookingBill(id: ${id})
          }
        `,
      });
      return response.data.removeBookingBill;
    }

    async removeBookingContainer(id: number): Promise<boolean> {
      const response = await client.mutate({
        mutation: gql`
          mutation {
            removeBookingContainer(id: ${id})
          }
        `,
      });
      return response.data.removeBookingContainer;
    }
  
    async removeCargo(id: number): Promise<boolean> {
      const response = await client.mutate({
        mutation: gql`
          mutation {
            removeCargo(id: ${id})
          }
        `,
      });
      return response.data.removeCargo;
    }

    async getBills(): Promise<Partial<BookingBillTable>[]>{
      const response = await client.query({
          query: gql`
              query {
                bookingBills {
                  id
                  code
                  consigneeName
                  shipperName
                  notifyName
                  terminal
                  date
                  bookingContainers {
                    id
                    containerNumber
                  }
                  booking {
                    id
                    number
                    lastVesselCode
                    motherVesselCode
                    createdBy
                    user {
                      id
                      name
                    }
                  }
                }
              }
          `,
      });
      return response.data.bookingBills;
    } 

    async getBillChecks(): Promise<Partial<BillCheck>[]>{
      const response = await client.query({
          query: gql`
              query {
                billChecks {
                  id
                  billNumber
                  vesselName
                  vesselCode
                  initialVesselCode
                  status
                  info
                }
              }
          `,
      });
      return response.data.billChecks;
    } 

    async deleteBillCheck(id: number): Promise<boolean> {
      const response = await client.mutate({
        mutation: gql`
          mutation {
            removeBillCheck(id: ${id})
          }
        `,
      });
      return response.data.removeBillCheck;
    }

    async updateBillCheck(billCheck: Partial<BillCheck>): Promise<BillCheck> {
      const response = await client.mutate({
        mutation: gql`
          mutation updateBillCheck($input: UpdateBillCheckDto!){
              updateBillCheck(input: $input){
                id
              }
          }
        `,
        variables: {
          input: {
            id: billCheck.id,
            status: billCheck.status,
          }
        }
      });
      return response.data.updateBillCheck;
    }

    async createBillCheck(billCheck: Partial<BillCheck>): Promise<{status:string;message:string;}> {
      const response = await client.mutate({
        mutation: gql`
          mutation createBillCheck($input: CreateBillCheckDto!){
              createBillCheck(input: $input){
                status
                message
              }
          }
        `,
        variables: {
          input: {
            billNumber: billCheck.billNumber,
          }
        }
      });
      return response.data.createBillCheck;
    }

    async importBookings(file: File, fileType: string): Promise<{status: string; message: string;}> {
      const fileService = new FileService();
      const fileResponse = await fileService.uploadFile(file);
      if(fileResponse.status === 'success'){
          try {
              const response = await client.mutate({
                  mutation: gql`
                      mutation {
                          importBookings(filename: "${fileResponse.filename}", type: "${fileType}"){
                              status
                              message
                          }
                      }
                  `,        
              });
              return response.data.importBookings;
          } catch (error) {
              return { status: 'error', message: 'Ceva nu a functionat va rugam incercati din nou.' };
          }
      }else{
          return { status: 'error', message: 'Din pacate am intampinat o eroare la incarcarea fisierului.' };
      }
    }
    
    async getEmailQueue(): Promise<Partial<EmailQueue>[]>{
      const response = await client.query({
          query: gql`
              query {
                  getEmailQueue {
                    id
                    subject
                    body
                    recipients
                    cc
                    bcc
                    replyTo
                    from
                    fromName
                    priority
                    status
                    scheduledFor
                    retryCount
                    lastRetryAt
                    errorMessage
                    createdAt
                    updatedAt
                    sentAt
                    isActive
                    maxRetries
                    reference
                    type
                  }
              }
          `,
      });
      return response.data.getEmailQueue;
    }

    async getBookingHistory(bookingId: number): Promise<BookingHistory[]>{
      const response = await client.query({
          query: gql`
              query {
                  bookingHistoriess(bookingId: ${bookingId}) {
                    id
                    bookingId
                    userId
                    date
                    description
                    title
                    type
                    user{
                      id
                      name
                    }
                  }
              }
          `,
      });

      return response.data.bookingHistoriess.map((history: BookingHistory) => ({...history, date: new Date(history.date)}));
    }
    
}