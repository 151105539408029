import React from 'react';
import { NavLink } from 'react-router-dom';

interface BookingLinkProps {
  data: {
    booking: {
      id: number;
    };
  };
  value: string;
}

export const BookingLinkRenderer: React.FC<BookingLinkProps> = ({ data, value }) => (
  <NavLink 
    to={`/bookings/view/${data.booking.id}`} 
    className="font-medium cursor-pointer text-blue-600 dark:text-blue-500 hover:underline"
  >
    {value}
  </NavLink>
);
