import { formatDateForBackend } from '../functions/dateHelper';
import client from '../apollo-client';
import { gql } from '@apollo/client';
import { Manifest } from '../interfaces/Booking';

export class ManifestService {

    async getManifests(): Promise<Partial<Manifest>[]>{
        const response = await client.query({
            query: gql`
                query {
                    manifests {
                        id
                        date
                        vesselName
                        vesselCode
                        terminal
                        filepath
                        translatedFilepath
                        status
                    }
                }
            `,
        });
        return response.data.manifests;
    }

    async getManifest(id: number): Promise<Partial<Manifest>>{
        const response = await client.query({
            query: gql`
                query {
                    manifest(id: ${id}) {
                        id
                        date
                        vesselName
                        vesselCode
                        terminal
                        filepath
                        translatedFilepath
                        status
                        content
                    }
                }
            `,
        });
        return response.data.manifest;
    }

    async createManifest(manifest: Partial<Manifest>, filename: string): Promise<{ status: string; message: string }> {
        const response = await client.mutate({
          mutation: gql`
            mutation createManifest($input: CreateManifestDto!){
                createManifest(input: $input){
                  status
                  message
                }
            }
          `,
          variables: {
            input: {
                vesselName: manifest.vesselName,
                vesselCode: manifest.vesselCode,
                terminal: manifest.terminal,
                content: manifest.content,
                pol: manifest.pol,
                date: manifest.date ? formatDateForBackend(manifest.date) : null,
                filename: filename,
            }
          }
        });
        return response.data.createManifest;
    }
    
    async updateTranslation(id: number, billId: number, translation: string): Promise<{ status: string; message: string }> {
        const response = await client.mutate({
          mutation: gql`
            mutation updateTranslation($id: Int!, $billId: Int!, $translation: String!){
                updateTranslation(id: $id, billId: $billId, translation: $translation){
                  status
                  message
                }
            }
          `,
          variables: {
            id: id,
            billId: billId,
            translation: translation,
          }
        });
        return response.data.updateTranslation;
    }

    async updateShortManifest(id: number, billId: number, text: string): Promise<{ status: string; message: string }> {
        const response = await client.mutate({
          mutation: gql`
            mutation updateShortManifest($id: Int!, $billId: Int!, $text: String!){
                updateShortManifest(id: $id, billId: $billId, text: $text){
                  status
                  message
                }
            }
          `,
          variables: {
            id: id,
            billId: billId,
            text: text,
          }
        });
        return response.data.updateShortManifest;
    }
    
    async translateManifest(id: number): Promise<{ status: string; message: string }> {
        const response = await client.mutate({
          mutation: gql`
            mutation translateManifest($id: Int!){
                translateManifest(id: $id){
                  status
                  message
                }
            }
          `,
          variables: {
            id: id
          }
        });
        return response.data.translateManifest;
    }
    
    async shortManifest(id: number): Promise<{ status: string; message: string }> {
      const response = await client.mutate({
        mutation: gql`
          mutation shortManifest($id: Int!){
              shortManifest(id: $id){
                status
                message
              }
          }
        `,
        variables: {
          id: id
        }
      });
      return response.data.shortManifest;
    }
    
    async generateTranslatedPdf(id: number): Promise<{ status: string; message: string }> {
      const response = await client.mutate({
        mutation: gql`
          mutation generateTranslatedPdf($id: Int!){
              generateTranslatedPdf(id: $id){
                status
                message
              }
          }
        `,
        variables: {
          id: id
        }
      });
      return response.data.generateTranslatedPdf;
    }
    
    async generateExcel(id: number): Promise<{ status: string; message: string }> {
      const response = await client.mutate({
        mutation: gql`
          mutation generateTranslatedExcel($id: Int!){
              generateTranslatedExcel(id: $id){
                status
                message
              }
          }
        `,
        variables: {
          id: id
        }
      });
      return response.data.generateTranslatedExcel;
    }
    
    async importManifestData(id: number): Promise<{ status: string; message: string }> {
      const response = await client.mutate({
        mutation: gql`
          mutation importManifestData($id: Int!){
              importManifestData(id: $id){
                status
                message
              }
          }
        `,
        variables: {
          id: id
        }
      });
      return response.data.importManifestData;
    }
    
}