import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Table from './Table';
import { ActivityService } from '../../services/ActivityService';
import { Activity, defaultActivity } from '../../interfaces/Activity';
import { Button, Tooltip } from "flowbite-react";
import ActivityModal from '../../pages/activities/ActivityModal'; // Import the ActivityModal component
import 'react-loading-skeleton/dist/skeleton.css';
import renderSkeleton from '../../components/SkeletonLoader';
import DeleteModal from '../../components/DeleteModal';
import NewActivityModal from './NewActivityModal';
import CloseTaskModal from './CloseTaskModal';
import CheckPermission from '../../components/CheckPermission';

interface IndexProps {
    clientId?: number | null | undefined;
}

const Index: React.FC<IndexProps> = ({ clientId }) => {
    const [activities, setActivities] = useState<Activity[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [modalEdit, setModalEdit] = useState<boolean>(false);
    const [modalClose, setModalClose] = useState<boolean>(false);
    const [modalNewActivity, setModalNewActivity] = useState<boolean>(false);
    const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
    const [activityToDelete, setActivityToDelete] = useState<number>(0);
    const [currentTask, setCurrentTask] = useState<Activity|null>(null);
    const [openModalCreate, setOpenModalCreate] = useState<boolean>(false);
    const activityService = new ActivityService();
    const navigate = useNavigate();

    useEffect(() => {
        fetchActivities(1);
    }, []);

    const fetchActivities = async (type: number) => { // 0 => ale mele, 1 => toate
        setLoading(true);
        const data = await activityService.getActivities(clientId, type);
        setActivities(data);
        setLoading(false);
    };

    const handleEdit = (task : Activity) => {
        setModalEdit(true);
        setCurrentTask(task);
    }

    const handleSaveActivity = async (activity: Activity, update: boolean) => {
        if (update) {
            setActivities([...activities.filter((tempAct) => tempAct.id != activity.id), activity]);
        } else {
            setActivities([...activities, activity]);
        }
    }

    const handleUpdate = (activity : Activity) => {
        setActivities([...activities.filter((tempAct) => tempAct.id != activity.id), activity]);
    }

    const handleDelete = (id: number) => {
        setActivityToDelete(id);
        setShowModalDelete(true);
    };

    const confirmDelete = async () => {
        if (activityToDelete !== null) {
          await activityService.deleteActivity(activityToDelete);
          setShowModalDelete(false);
          setActivities(activities.filter(activity => activity.id !== activityToDelete));
          setActivityToDelete(0);
        }
    };

    const handleNewContactActivity = (task : Activity) => {
        setCurrentTask(task);
        setModalNewActivity(true);
    }

    const handleCreateNewActivity = (activity: Activity, prevActivity: Activity) => {
        setActivities(
            [
                ...activities.filter((tempAct) => tempAct.id != activity.id),
                activity,
                prevActivity
            ]);
        setModalNewActivity(false);
        setCurrentTask(null);
    }

    const handleCloseActivity = (task : Activity) => {
        setCurrentTask(task);
        setModalClose(true);
    }

    return (
        <div className="ag-theme-quartz px-4 activities">
            <div className="flex justify-between items-center py-2">
                <div className="text-lg font-bold">
                    Activitate
                </div>
                <Tooltip content="Adaugă activitate">
                    <Button size="sm" onClick={() => {setOpenModalCreate(true)}}>Adaugă activitate</Button>
                </Tooltip>
            </div>
            {loading ? (
                renderSkeleton()
            ) : (
                <>
                    <Table refreshActivities={fetchActivities} clientId={clientId} activities={activities} onDelete={handleDelete} handleEdit={handleEdit} handleNewContactActivity={handleNewContactActivity} handleCloseActivity={handleCloseActivity}/>
                    <CheckPermission permissions={['ACTIVITY_UPDATE']}>
                        <ActivityModal
                            onSave={handleSaveActivity}
                            id={currentTask ? currentTask.id : null}
                            openModal={modalEdit}
                            setOpenModal={setModalEdit}
                            clientId={currentTask ? currentTask.clientId : 0}
                        />
                    </CheckPermission>
                    <CheckPermission permissions={['ACTIVITY_CREATE']}>
                        <ActivityModal
                            onSave={handleSaveActivity}
                            clientId={clientId ? clientId : 0}
                            openModal={openModalCreate}
                            setOpenModal={setOpenModalCreate}
                        /> 
                    </CheckPermission>
                    <CheckPermission permissions={['ACTIVITY_DELETE']}>
                        <DeleteModal isOpen={showModalDelete} onClose={() => {setShowModalDelete(false)}} onConfirm={confirmDelete} deleteText='Sunteți sigur că doriți să eliminați taskul?' />
                    </CheckPermission>
                    <CheckPermission permissions={['ACTIVITY_CLOSE', 'ACTIVITY_CREATE']}>
                        <NewActivityModal 
                            activityId={currentTask ? currentTask.id : null}
                            openModal={modalNewActivity}
                            setOpenModal={setModalNewActivity}
                            onCreated={handleCreateNewActivity}
                        />
                    </CheckPermission>
                    <CheckPermission permissions={['ACTIVITY_CLOSE']}>
                        <CloseTaskModal
                            activity={currentTask}
                            openModal={modalClose}
                            setOpenModal={setModalClose}
                            onClosed={handleUpdate}
                        />
                    </CheckPermission>
                </>
            )}
        </div>
    );
};

export default Index;
