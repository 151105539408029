import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'flowbite-react';
import { errorMessage, successMessage } from '../../../functions/generalHelper';
import Input from '../../../components/Input';
import { defaultBookingBill, ViewBookingBill } from '../../../interfaces/Booking';
import { handleInputChange, handleSelectChange } from '../../../functions/selectHelper';
import { BookingService } from '../../../services/BookingService';
import CustomDatePicker from '../../../components/CustomDatePicker';
import { getDateFormat } from '../../../functions/dateHelper';
import TextArea from '../../../components/Textarea';
import DateField from '../../../components/DateField';
import dayjs from 'dayjs';
import { RecommandationService } from '../../../services/RecommandationService';
import { RecommendationResp } from '../../../interfaces/Recommandation';
import CustomSelect from '../../../components/CustomSelect';

interface ExportBillModalProps {
    bookingBill: ViewBookingBill;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    onSave: (bill : ViewBookingBill, create : boolean) => void;
    bookingId: number;
}

const ExportBillModal: React.FC<ExportBillModalProps> = ({ bookingBill, showModal, setShowModal, onSave, bookingId }) => {
    const [bill, setBill] = useState<ViewBookingBill>(defaultBookingBill as ViewBookingBill);
    const bookingService = new BookingService();
    const [recommendations, setRecommendations] = useState<RecommendationResp[]>([]);
    const recommendationsService = new RecommandationService();

    // GET ALL OPTION VALUES AND FIELD RECOMMENDATIONS
    useEffect(() => {
      recommendationsService.recommendationsByCategorySlug('bill-releaseType').then(data => setRecommendations(data));
    }, []);

    useEffect(() => {
        if (!showModal) {
            setBill(defaultBookingBill as ViewBookingBill);
        }else{
            if(bookingBill.id){
                setBill(bookingBill);
            }
        }
    }, [showModal, bookingBill]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const newBill = await bookingService.createOrUpdateBookingBill({...bill, bookingId: bookingId});
            onSave(newBill, bill.id ? false : true);
            setShowModal(false);
            successMessage('B/L salvat cu succes!');
        } catch (error) {
            errorMessage('A aparut o eroare!');
        }
    };

    return (
        <Modal show={showModal} size="lg" popup onClose={() => setShowModal(false)}>
            <Modal.Header>Adaugă B/L</Modal.Header>
            <Modal.Body className="overflow-auto min-h-[400px]">
                <div className="space-y-4 pt-5">
                    <form onSubmit={handleSubmit}>
                        {/* Basic Information Section */}
                        <div className='flex flex-col gap-4'>
                            <Input 
                                id="code"
                                label="Număr B/L"
                                value={bill.code}
                                onChange={(e) => handleInputChange(bill, setBill, 'code', e.target.value, defaultBookingBill as ViewBookingBill)}
                            />
                            <div>
                                <DateField
                                    label="Data eliberării"
                                    selected={bill.date}
                                    onChange={(date) => setBill(prev => ({ ...prev, date: date }))}
                                />
                                {/* <CustomDatePicker
                                    selected={bill.date ? new Date(bill.date) : null}
                                    onChange={(date) => setBill(prev => ({ ...prev, date: date }))}
                                    dateFormat={getDateFormat()}
                                    locale="ro"
                                    isClearable
                                    placeholderText="Data eliberarii"
                                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                /> */}
                            </div>
                            <div>
                                <CustomSelect
                                    value={recommendations.map(rec => ({label: rec.label, value: rec.value})).find(releaseType => releaseType.value === bill.releaseType)}
                                    onChange={(data) => handleSelectChange(bill, setBill, 'releaseType', data)}
                                    options={recommendations.map(rec => ({label: rec.label, value: rec.value}))}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Tip eliberare"
                                />
                            </div>
                        </div>
                        <div className="flex justify-end mt-4">
                            <Button size="sm" type="submit">Salvează</Button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ExportBillModal;
