import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import MainNavBar from './components/MainNavBar';
import Clients from './pages/clients/Clients';
import Users from './pages/users/Users';
import Roles from './pages/roles/Roles';
import { ApolloProvider } from '@apollo/client';
import './App.css';
import client from './apollo-client';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Flowbite } from 'flowbite-react';
import customTheme from './functions/customTheme';
import Offers from './pages/offers/Offers';
import Login from './pages/auth/Login';
import Unauthorized from './pages/auth/Unauthorized';
import NotFoundPage from './pages/auth/NotFoundPage';
import ProfileSettings from './pages/auth/ProfileSettings';
import Recommandations from './pages/recommandations/Recommandations';
import { UserProvider } from './components/UserContext';
import Bookings from './pages/bookings/Bookings';
import Movements from './pages/movements/Movements';
import MovementsFile from './pages/movements/file/MovementsFile';
import Vessels from './pages/bookings/vessels/Vessels';
import Emails from './pages/bookings/emails/Emails';
import Manifests from './pages/bookings/manifests/Manifests';
import Bills from './pages/bookings/bills/Bills';
import { MenuProvider } from './components/MenuContext';
import BillChecks from './pages/bookings/billChecks/BillChecks';
import MobileMenu from './components/MobileMenu';
import ContactButtons from './components/ContactButtons';
import Containers from './pages/containers/Containers';
import NoticeSettings from './pages/settings/NoticeSettings';
import ContainerSettings from './pages/settings/containers/ContainerSettings';

const PrivateRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isAuthenticated = !!localStorage.getItem('token');
  return isAuthenticated ? <>{children}</> : <Navigate to="/login" />;
};

const AppLayout: React.FC<{ children: React.ReactNode, guest?: boolean }> = ({ children, guest = false }) => (
  <>
    { guest ? (<></>) : (<><MainNavBar /><MobileMenu /></>)}
    <ToastContainer limit={2} />
    <Flowbite theme={{ theme: customTheme }}>
      <main style={{ height: 'calc(100vh - 120px)' }}>
        {children}
      </main>
    </Flowbite>
  </>
);

const App: React.FC = () => {

  return (
    <ApolloProvider client={client}>
      <MenuProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<AppLayout guest={true}><Login /></AppLayout>} />
            <Route
              path="/*"
              element={
                <PrivateRoute>
                  <UserProvider>
                    <AppLayout>
                      <Routes>
                        <Route path="/crm/clients/*" element={<Clients />} />
                        <Route path="/crm/offers/*" element={<Offers />} />
                        <Route path="/hr/users/*" element={<Users />} />
                        <Route path="/hr/roles/*" element={<Roles />} />
                        <Route path="/bookings/manifests/*" element={<Manifests />} />
                        <Route path="/bookings/movements/*" element={<Movements />} />
                        <Route path="/bookings/vessels/*" element={<Vessels />} />
                        <Route path="/bookings/emails/*" element={<Emails />} />
                        <Route path="/bookings/billChecks/*" element={<BillChecks />} />
                        <Route path="/bookings/movementsConfiguration/*" element={<MovementsFile />} />
                        <Route path="/bookings/bills/*" element={<Bills />} />
                        <Route path="/bookings/*" element={<Bookings />} />
                        <Route path="/containers/*" element={<Containers />} />
                        <Route path="/settings/profile" element={<ProfileSettings />} />
                        <Route path="/settings/notice/*" element={<NoticeSettings />} />
                        <Route path="/settings/containers/*" element={<ContainerSettings />} />
                        <Route path="/settings/recommandations/*" element={<Recommandations />} />
                        <Route path="/crm" element={<Navigate to="/crm/clients/index" replace />} />
                        <Route path="/hr" element={<Navigate to="/hr/users/index" replace />} />
                        <Route path="/settings" element={<Navigate to="/settings/profile" replace />} />
                        <Route path="/unauthorized" element={<Unauthorized />} />
                        <Route path="/" element={<Navigate to="/crm/clients/index" replace />} />
                        <Route path="*" element={<NotFoundPage />} />
                      </Routes>
                    </AppLayout>
                  </UserProvider>
                </PrivateRoute>
              }
            />
          </Routes>
        </Router>
        <ContactButtons />
      </MenuProvider>
    </ApolloProvider>
  );
};

export default App;