import React, { useState } from 'react';
import { Button } from 'flowbite-react';
import { ViewBooking, ViewContainer } from '../../../interfaces/Booking';
import ContainersTable from './components/ContainersTable';
import ContainerModal from '../components/ContainerModal';
import { BookingService } from '../../../services/BookingService';
import { successMessage } from '../../../functions/generalHelper';
import DeleteModal from '../../../components/DeleteModal';

interface ContainersProps {
  booking: ViewBooking;
  setBooking: React.Dispatch<React.SetStateAction<ViewBooking>>;
}

const Containers: React.FC<ContainersProps> = ({ booking, setBooking }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<number>(0);
  const bookingService = new BookingService();

  const handleAddContainer = () => {
    setShowModal(true);
  };

  const onSave = (container : ViewContainer, create : boolean) => {
    if(create){
      setBooking(prev => ({...prev, containers: [...prev.containers, container]}));
    }else{
      setBooking(prev => ({...prev, containers: prev.containers.map(c => c.id === container.id ? container : c)}));
    }
  };
  
  const handleDelete = (id : number) => {
    setIdToDelete(id);
    setShowModalDelete(true);
  };
  
  const handleEdit = (id : number) => {
    setIdToDelete(id);
    setShowModal(true);
  };

  const confirmDelete = () => {
    bookingService.removeBookingContainer(idToDelete).then(() => {
      setBooking(prev => ({...prev, containers: prev.containers.filter(b => b.id !== idToDelete)}));
      setIdToDelete(0);
      successMessage('Container sters cu succes!');
    });
    setShowModalDelete(false);
  }

  return (
    <div>
      <div className="flex justify-end space-x-4 mb-4">
        <Button size="sm" onClick={handleAddContainer}>Adaugă container</Button>
      </div>
      <div className="rounded-lg border border-gray-200 bg-white p-5 relative mb-6">
        <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">Containere</h4>
        <ContainersTable booking={booking} onDelete={handleDelete} onEdit={handleEdit} setBooking={setBooking}/>
        <ContainerModal id={idToDelete} booking={booking} onSave={onSave} setShowModal={setShowModal} showModal={showModal} />
        <DeleteModal isOpen={showModalDelete} onClose={() => {setShowModalDelete(false)}} onConfirm={confirmDelete} deleteText='Sunteți sigur că doriți să eliminați acest container?' />
      </div>
    </div>
  );
};


export default Containers;