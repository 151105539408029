import React, { useCallback, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { CellSelectionOptions, GridReadyEvent, RowSelectionOptions, SelectionChangedEvent } from 'ag-grid-community';
import { AG_GRID_LOCALE_RO } from '../../functions/langHelper';
import "ag-grid-charts-enterprise";
import { ViewContainerWithBookingData } from '../../interfaces/Booking';
import useGridState from '../../functions/useGridState';
import { columns } from './constants/columns';
import { errorMessage, successMessage } from '../../functions/generalHelper';
import { ContainerService } from '../../services/ContainerService';
import { Button } from 'flowbite-react';
import LoadingListModal from './LoadingListModal';
import Loader from '../../components/CustomLoader';
import ExportNoticeModal, { NoticeBooking } from '../bookings/viewTabs/components/ExportNoticeModal';
import MoveContainerModal from './MoveContainerModal';

interface ContainersTableProps {
  containers: Partial<ViewContainerWithBookingData>[];
  setContainers: React.Dispatch<React.SetStateAction<Partial<ViewContainerWithBookingData>[]>>;
}

const Table: React.FC<ContainersTableProps> = ({ containers, setContainers }) => {
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showModalMove, setShowModalMove] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [bookings, setBookings] = useState<NoticeBooking[]>([]);
    const [showArrivalNoticeModal, setShowArrivalNoticeModal] = useState<boolean>(false);
    const containerService = useMemo(() => new ContainerService(), []);
    const pagination = true;
    const paginationPageSize = 20;
    const paginationPageSizeSelector = [10, 20, 50, 100, 1000];

    const onSelectionChanged = useCallback((event: SelectionChangedEvent) => {
        var selectedNodes = event.api.getSelectedNodes();
        const selectedIds = selectedNodes.map(node => node.data.id);
        setSelectedRows(selectedIds);
        const bookingsMap = new Map<number, NoticeBooking>();
        selectedNodes.forEach(node => {
            const container = node.data;
            const booking = container.booking;
            if (!booking || !booking.id) return;
            
            // Dacă booking-ul există deja în Map, adăugăm doar containerul
            if (bookingsMap.has(booking.id)) {
                bookingsMap.get(booking.id)!.containers.push({
                    number: container.containerNumber || '',
                    id: container.id,
                    iso: container.iso,
                    departureCarNumber: container.departureCarNumber,
                    car: container.car,
                    shipperName: container.loadingPlace,
                    isRail: container.isRail
                });
            } else {
                // Altfel, creăm un nou entry pentru booking
                bookingsMap.set(booking.id, {
                    id: booking.id,
                    number: booking.number || '',
                    email: booking.bookingEmail || '',
                    containers: [{
                        number: container.containerNumber || '',
                        id: container.id,
                        iso: container.iso,
                        departureCarNumber: container.departureCarNumber,
                        car: container.car,
                        shipperName: container.loadingPlace,
                        isRail: container.isRail
                    }]
                });
            }
        });
        
        // Convertim Map-ul în array
        setBookings(Array.from(bookingsMap.values()));
    }, []);

    const gridOptions = useMemo(() => 
        ({
            rowHeight: 35,
            defaultColDef: {
                enableRowGroup: true,
            },
            alwaysMultiSort: true,
            rowSelection: {
                mode: 'multiRow',
                selectAll: 'filtered',
                enableClickSelection: false,
            } as RowSelectionOptions,
            onSelectionChanged
        }), []);

    const cellSelection : CellSelectionOptions = useMemo(() => { 
        return {
            handle: {
                mode: 'fill',
                direction: 'y'
            }
        };
    }, []);

    const { saveState, restoreState } = useGridState('exportContainers-grid');
    const onGridReady = useCallback((event: GridReadyEvent) => {
        const api = event.api;
        event.api.closeToolPanel();
        
        // Restaurăm starea salvată
        const savedState = localStorage.getItem(`agGrid-exportContainers-grid`);
        if (savedState) {
            // Dacă există state salvat, îl restaurăm
            restoreState(api);
        } else {
            // Configurarea implicita a gridului
        }
        // Adăugăm event listeners pentru salvarea stării
        const saveCurrentState = () => saveState(api);
        
        api.addEventListener('filterChanged', saveCurrentState);
        api.addEventListener('sortChanged', saveCurrentState);
        api.addEventListener('columnMoved', saveCurrentState);
        api.addEventListener('columnResized', saveCurrentState);
        api.addEventListener('paginationChanged', saveCurrentState);
        // Optional: Cleanup function
        return () => {
            api.removeEventListener('filterChanged', saveCurrentState);
            api.removeEventListener('sortChanged', saveCurrentState);
            api.removeEventListener('columnMoved', saveCurrentState);
            api.removeEventListener('columnResized', saveCurrentState);
            api.removeEventListener('paginationChanged', saveCurrentState);
        };
    }, [saveState, restoreState]);

    const modificaCelula = async (params : any, coloana : string) => {
        if(coloana === 'lastVesselCode'){
            try {
                const updatedBookingRocndVessel = {
                    vesselCode: params.newValue,
                    bookingId: params.data.bookingId
                }
                const savedBooking = await containerService.updateBookingVessel(updatedBookingRocndVessel);
                setContainers(prev => (prev.map(cont => params.data.bookingId === cont.bookingId ? {...cont, booking: {...cont.booking!, lastVesselCode: params.newValue}} : cont)));
                successMessage('Nava a fost editata cu succes!');
            } catch (error : any) {
                errorMessage(error.message || 'A aparut o eroare la editarea navei!');
            }
        } else if(coloana === 'motherVesselCode'){
            try {
                const updatedBookingRocndVessel = {
                    vesselCode: params.newValue,
                    bookingId: params.data.bookingId
                }
                const savedBooking = await containerService.updateBookingMotherVessel(updatedBookingRocndVessel);
                setContainers(prev => (prev.map(cont => params.data.bookingId === cont.bookingId ? {...cont, booking: {...cont.booking!, motherVesselCode: params.newValue}} : cont)));
                successMessage('Nava a fost editata cu succes!');
            } catch (error : any) {
                errorMessage(error.message || 'A aparut o eroare la editarea navei!');
            }
        } else if(coloana === 'terminal'){
            try {
                const updatedBookingTerminal = {
                    terminal: params.newValue,
                    bookingId: params.data.bookingId
                }
                const savedBooking = await containerService.updateBookingTerminal(updatedBookingTerminal);
                setContainers(prev => (prev.map(cont => params.data.bookingId === cont.bookingId ? {...cont, booking: {...cont.booking!, terminal: params.newValue}} : cont)));
                successMessage('Terminalul a fost actualizat cu succes!');
            } catch (error : any) {
                errorMessage(error.message || 'A aparut o eroare la editarea navei!');
            }
        }else{
            try {
                const newValueUnchanged = params.newValue;
                if(coloana === "vgmNumber" || coloana === "weight" || coloana === "volume" || coloana === "estVgm"){
                    if(params.newValue === null || params.newValue === undefined || params.newValue === ""){
                        params.newValue = null;
                    }else{
                        params.newValue = parseFloat(params.newValue);
                    }
                }
                if(coloana === "freeDays" || coloana === "packageNumber" || coloana === "detention"){
                    if(params.newValue === null || params.newValue === undefined || params.newValue === ""){
                        params.newValue = null;
                    }else{
                        params.newValue = parseInt(params.newValue);
                    }
                }
                if(Number.isNaN(params.newValue)){
                    errorMessage(`Valoarea ${newValueUnchanged} nu este un numar valid!`);
                    return;
                }

                // ne asiguram ca nu exista acelasi container in booking
                if(coloana === "containerNumber" && params.newValue !== "TBA"){
                    const isDuplicateContainer = containers.some(
                        container => 
                            container.containerNumber === params.newValue && 
                            container.bookingId === params.data.bookingId && 
                            container.id !== params.data.id
                    );

                    if (isDuplicateContainer) {
                        errorMessage('Containerul există deja în booking!');
                        return;
                    }
                }
                
                const updatedContainer = {
                  [coloana!]: params.newValue,
                  id: params.data.id,
                  bookingId: params.data.bookingId
                };
                // Actualizăm containerul în backend
                try {
                    const savedContainer = await containerService.updateBookingContainer(updatedContainer);
                    const toUpdate = {[coloana!]: params.newValue};
                    if(coloana === 'containerNumber') {
                      toUpdate['size'] = savedContainer.size;
                      toUpdate['type'] = savedContainer.type;
                      toUpdate['iso'] = savedContainer.iso;
                    }
                    // Actualizăm state-ul local cu noul container
                    setContainers(prev => (prev.map(cont => savedContainer.id === cont.id ? {...cont, ...toUpdate} : cont)));
                    // params.node.setData(savedContainer);
                    successMessage('Container editat cu succes!');
                }catch(error : any){
                    errorMessage(error.message || 'A aparut o eroare la editarea containerului!');
                    return;
                }
            } catch (error : any) {
                errorMessage(error.message || 'A aparut o eroare la editarea containerului!');
            }
        }
    }
    
    const generateLoadingListExcel = useCallback(async (port : string, rows: number[]) => {
        setLoading(true);
        containerService.generateLoadingListExcel(rows, port).then((response) => {
            if(response.status === "success"){
                window.open(`${process.env.REACT_APP_BACKEND_URI}/file/${response.message}`, '_blank');
            }else{
                errorMessage(response.message);
            }
            setLoading(false);
        });
    }, [selectedRows, containerService]);

    const createNoticeModalDate = () => {
        setShowArrivalNoticeModal(true);
    }

    const clearNotice = () => {
        // fetchBooking();
        setShowArrivalNoticeModal(false);
    }
      
    return (
        <>
            <Loader isLoading={loading} />
            <div className="flex items-end flex-row justify-end space-x-2 butonCuMargineMinusContainerInContainere">
                { selectedRows.length ? 
                    <>
                        <Button size="sm" onClick={() => {setShowModal(true)}}>Lista incarcare</Button>
                        <Button size="sm" onClick={createNoticeModalDate}>Trimite avizare</Button>
                    </>
                : 
                    <>
                        <Button size="sm" onClick={() => setShowModalMove(true)}>Mută container</Button>
                    </>
                }
            </div>
            <AgGridReact 
                className="tabel-agGrid tabelContainere"
                localeText={AG_GRID_LOCALE_RO}
                columnDefs={columns(modificaCelula, 'containere')} 
                rowData={containers}
                defaultColDef={{ flex: 1, minWidth: 20 }}
                domLayout='autoHeight'
                pagination={pagination}
                paginationPageSize={paginationPageSize}
                paginationPageSizeSelector={paginationPageSizeSelector}
                gridOptions={gridOptions}
                enableCharts={true}
                sideBar={true}
                onGridReady={onGridReady}
                rowGroupPanelShow="always"
                groupDisplayType="groupRows"
                cellSelection={cellSelection}
            />
            <ExportNoticeModal 
                bookings={bookings} 
                openModal={showArrivalNoticeModal} 
                setOpenModal={setShowArrivalNoticeModal} 
                onSendNotice={clearNotice} 
            />
            <MoveContainerModal 
                containers={containers.map(cont => ({number: cont.containerNumber!, bookingId: cont.bookingId!, bookingNumber: cont.booking!.number!}))} 
                openModal={showModalMove}
                setOpenModal={setShowModalMove}
                setContainers={setContainers}
            />
            <LoadingListModal rows={selectedRows} openModal={showModal} setOpenModal={setShowModal} submit={generateLoadingListExcel} />
        </>
    );
};

export default Table;