import React, { useState, useEffect, useCallback } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Booking } from '../../interfaces/Booking';
import { Button, Dropdown, Tooltip } from "flowbite-react";
import { BookingService } from '../../services/BookingService';
import renderSkeleton from '../../components/SkeletonLoader';
import Table from './Table';
import DeleteModal from '../../components/DeleteModal';
import ArrivalNoticeModal from './components/ArrivalNoticeModal';
import { errorMessage, successMessage } from '../../functions/generalHelper';
import ExportNoticeModal from './components/ExportNoticeModal';

const Index: React.FC = () => {
  const [bookings, setBookings] = useState<Partial<Booking>[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [bookingToDelete, setBookingToDelete] = useState<number>(0);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showArrivalNoticeModal, setShowArrivalNoticeModal] = useState(false);
  const [showArrivalExportNoticeModal, setShowArrivalExportNoticeModal] = useState(false);
  const [exportNoticeModalData, setExportNoticeModalData] = useState<{id: number; bookingEmail: string|null; number: string;}>({} as {id: number; bookingEmail: string|null; number: string;});
  const [documentType, setDocumentType] = useState<string>('');
  const bookingService = new BookingService();
  const navigate = useNavigate();

  useEffect(() => {
    fetchBookings();
  }, []);

  const fetchBookings = useCallback(
    async () => {
        setLoading(true);
        bookingService.getBookings().then((data) => { setBookings(data); setLoading(false)}).catch(() => {setLoading(false)});
  },[])

  const confirmDelete = async () => {
    if (bookingToDelete !== null) {
      try{
        await bookingService.deleteBooking(bookingToDelete);
        setShowModalDelete(false);
        setBookings(bookings.filter(booking => booking.id !== bookingToDelete));
        setBookingToDelete(0);
        successMessage('Bookingul a fost șters cu succes!');
      }catch(e){  
        errorMessage('Bookingul nu a putut fi șters!');
      }
    }
  };

  const handleEdit = (id: number) => {
    navigate('/bookings/form/' + id);
  };

  const handleDelete = (id: number) => {
    setBookingToDelete(id);
    setShowModalDelete(true);
  };

  const onExportNotice = useCallback((id: number, bookingEmail: string|null, number: string) => {
    setShowArrivalExportNoticeModal(true);
    setExportNoticeModalData({id, bookingEmail, number});
  },[exportNoticeModalData, showArrivalExportNoticeModal]);

  return (
    <div className="ag-theme-quartz px-4">
      <div className="flex justify-between items-center py-2">
      <div className="text-lg font-bold hidden md:block">
        Bookinguri
      </div>
    
      <div className="flex gap-2">
        <Tooltip content="Adaugă booking">
          <NavLink to="/bookings/form">
            <Button size="sm">Adaugă booking</Button>
          </NavLink>
        </Tooltip>
        <Dropdown label="Trimite documente" size="sm">
          <Dropdown.Item onClick={() => {setShowArrivalNoticeModal(true); setDocumentType('arrivalNotice')}}>Preavizare</Dropdown.Item>
          <Dropdown.Item onClick={() => {setShowArrivalNoticeModal(true); setDocumentType('arrivalUpdate')}}>Avizare</Dropdown.Item>
        </Dropdown>
      </div>

    </div>
      {loading ? ( renderSkeleton() ) : (
        <>
          <Table bookings={bookings} onEdit={handleEdit} onDelete={handleDelete} onExportNotice={onExportNotice} />
          {/* <CheckPermission permissions={['BOOKING_DELETE']}> */}
            <DeleteModal isOpen={showModalDelete} onClose={() => {setShowModalDelete(false)}} onConfirm={confirmDelete} deleteText='Sunteți sigur că doriți să eliminați acest booking?' />
          {/* </CheckPermission> */}
          <ArrivalNoticeModal setDocumentType={setDocumentType} documentType={documentType} type="vessel" showModal={showArrivalNoticeModal} setShowModal={setShowArrivalNoticeModal} />
          <ExportNoticeModal bookingId={exportNoticeModalData.id} bookingEmail={exportNoticeModalData.bookingEmail} bookingNumber={exportNoticeModalData.number} showModal={showArrivalExportNoticeModal} setShowModal={setShowArrivalExportNoticeModal} onAllContainersNotified={fetchBookings} />
        </>
      )}
    </div>
  );
};

export default Index;