import React, { useState, useEffect } from 'react';
import { Modal, Button, Checkbox, Label } from 'flowbite-react';
import { errorMessage, successMessage } from '../../../functions/generalHelper';
import Input from '../../../components/Input';
import { ContainerService } from '../../../services/ContainerService';
import Loader from '../../../components/CustomLoader';

interface ExportNoticeModalProps {
  bookingId: number;
  bookingEmail: string|null;
  bookingNumber: string;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  onAllContainersNotified: () => void;
}

interface ContainerData {
  id: number;
  containerNumber: string;
  noticeStatus: string;
  isSelected: boolean;
  iso: string|null;
  departureCarNumber: string|null;
  isRail: boolean;
  car: string|null;
  loadingPlace: string|null;
}

const ExportNoticeModal: React.FC<ExportNoticeModalProps> = ({ bookingId, bookingEmail, bookingNumber, showModal, setShowModal, onAllContainersNotified }) => {
  const [containers, setContainers] = useState<ContainerData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const containerService = new ContainerService();
  const [errors, setErrors] = useState<{[key: number]: string}>({});

  useEffect(() => {
    if (showModal && bookingId) {
      fetchContainers();
      if(bookingEmail) setEmail(bookingEmail);
    }
  }, [showModal, bookingId]);

  const fetchContainers = async () => {
    try {
      setLoading(true);
      const fetchedContainers = await containerService.getContainersByBookingId(bookingId);
      addErrors(fetchedContainers);
      setContainers(fetchedContainers.map(container => ({
        ...container,
        isSelected: false
      })));
    } catch (error) {
      errorMessage('Eroare la incarcarea containerelor. Va rugam incercati din nou!');
    } finally {
      setLoading(false);
    }
  };

  const addErrors = (containers: ContainerData[]) => {
    containers.forEach(container => {
      let errors = "";
      if(container.containerNumber === undefined || container.containerNumber === null || container.containerNumber.includes('TBA')){
          errors += `"Număr container nu este avizat" `;
      }
      if(container.iso === undefined || container.iso === null || container.iso === ''){
        errors += `"ISO" `;
      }
      if(container.departureCarNumber === undefined || container.departureCarNumber === null || container.departureCarNumber === ''){
          errors += `"Număr mașina/CFR plecare" `;
      }
      if(!container.isRail){
        if(container.car === undefined || container.car === null || container.car === ''){
            errors += `"Telefon sofer" `;
        }
        if(container.loadingPlace === undefined || container.loadingPlace === null || container.loadingPlace === ''){
            errors += `"Loc de incarcare" `;
        }
      }
      setErrors(prev => ({
        ...prev,
        [container.id]: errors
      }));
    });
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    
    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailValue === '' || emailRegex.test(emailValue));
  };

  const handleContainerSelection = (containerId: number) => {
    setContainers(prevContainers => 
      prevContainers.map(container => 
        container.id === containerId 
          ? { ...container, isSelected: !container.isSelected } 
          : container
      )
    );
  };

  const handleSubmit = async () => {
    if (!email) {
      errorMessage('Adresa de email este obligatorie!');
      return;
    }

    if (!isEmailValid) {
      errorMessage('Adresa de email nu este valid!');
      return;
    }

    const selectedContainers = containers.filter(container => container.isSelected);
    if (selectedContainers.length === 0) {
      errorMessage('Selectati cel putin un container!');
      return;
    }

    try {
      setLoading(true);
      
      const containerIds = selectedContainers.map(container => container.id);
      const payload = [{
        bookingId,
        email,
        containers: containerIds
      }];

      if(containerIds.length === unnotifiedContainers.length){
        onAllContainersNotified();
      }

      await containerService.sendExportNotice(payload);
      successMessage('Avizare trimisa cu succes!');
      setShowModal(false);
      
      // Reset form
      setEmail('');
      setContainers(prevContainers => 
        prevContainers.map(container => ({ ...container, isSelected: false }))
      );
    } catch (error) {
      errorMessage('Eroare la trimiterea avizarii. Va rugam incercati din nou!');
    } finally {
      setLoading(false);
    }
  };

  // Group containers by notice status
  const notifiedContainers = containers.filter(container => container.noticeStatus === 'Trimis');
  const pendingContainers = containers.filter(container => container.noticeStatus === 'pending');
  const unnotifiedContainers = containers.filter(container => 
    container.noticeStatus !== 'Trimis' && container.noticeStatus !== 'pending');

  const renderContainerCheckbox = (container: ContainerData) => {
    return (
      <div key={container.id} className="flex items-center space-x-3 py-2 px-3 border-b border-gray-200 hover:bg-gray-50">
        <Checkbox
          id={`container-${container.id}`}
          checked={container.isSelected}
          onChange={() => handleContainerSelection(container.id)}
          disabled={container.noticeStatus === 'Trimis'}
          className="focus:ring-primary h-5 w-5 text-primary border-gray-300 rounded"
        />
        <Label
          htmlFor={`container-${container.id}`}
          className="ml-2 block text-sm text-gray-900 cursor-pointer"
        >
          {container.containerNumber}
        </Label>
        {container.noticeStatus === 'pending' && (
          <span className="ml-auto text-sm text-yellow-600 font-medium">In asteptare</span>
        )}
      </div>
    );
  };

  return (
    <>
      <Loader isLoading={loading} />
      <Modal show={showModal} size="xl" popup onClose={() => setShowModal(false)}>
        <Modal.Header>Avizare Export - {bookingNumber}</Modal.Header>
        <Modal.Body className="overflow-auto">
          <div className="space-y-4 pt-5">
            <div className="mb-4">
              <div className="mb-2">
                <Input 
                    id="email"
                    label="Email*"
                    value={email}
                    onChange={handleEmailChange}
                    required
                />
                {!isEmailValid && (
                  <p className="text-red-500 text-sm mt-1">Adresa de email nu este valida</p>
                )}
              </div>
            </div>

            <div className="mb-4">
              <h3 className="text-lg font-medium mb-2">Containere disponibile pentru avizare:</h3>
              <div className="border border-gray-200 rounded-md divide-y">
                {unnotifiedContainers.length === 0 && pendingContainers.length === 0 ? (
                  <div className="py-4 text-center text-gray-500">Nu exista containere disponibile pentru avizare</div>
                ) : (
                  <>
                    {unnotifiedContainers.map(renderContainerCheckbox)}
                    {pendingContainers.map(renderContainerCheckbox)}
                  </>
                )}
              </div>
            </div>

            {notifiedContainers.length > 0 && (
              <div className="mb-4">
                <h3 className="text-lg font-medium mb-2">Containere deja avizate:</h3>
                <div className="border border-gray-200 rounded-md divide-y bg-gray-50">
                  {notifiedContainers.map(container => (
                    <div key={container.id} className="flex items-center space-x-3 py-2 px-3">
                      <span className="text-sm font-medium">{container.containerNumber}</span>
                      <span className="ml-auto text-sm text-green-600 font-medium">Avizat</span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {containers.some(container => container.isSelected && errors[container.id]) && (
              <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-md">
                <h3 className="text-lg font-medium text-red-600 mb-2">Atentie! Containerele selectate au date lipsa:</h3>
                <ul className="list-disc pl-5 space-y-1">
                  {containers
                    .filter(container => container.isSelected && errors[container.id])
                    .map(container => (
                      <li key={container.id} className="text-sm text-red-700">
                        <span className="font-medium">{container.containerNumber}</span>: {errors[container.id]}
                      </li>
                    ))}
                </ul>
              </div>
            )}

            <div className="flex justify-end gap-2">
              <Button color="gray" onClick={() => setShowModal(false)}>
                Inchide
              </Button>
              <Button size="sm" onClick={handleSubmit}>
                Trimite avizare
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ExportNoticeModal;