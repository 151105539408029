import React from 'react';
import { Package2, Ship, Calendar, MapPin, User, FileText, Building2, ClipboardList, Info, Boxes, AlertCircle } from 'lucide-react';
import { getNextStatusName, ViewBooking } from '../../../interfaces/Booking';
import { formatDate } from '../../../functions/dateHelper';
import BookingProgress from '../viewTabs/components/BookingProgress';
import { Button, Dropdown } from 'flowbite-react';
import Tooltip from '../../../components/Tooltip';
import { BookingService } from '../../../services/BookingService';
import { errorMessage } from '../../../functions/generalHelper';
import { NavLink } from 'react-router-dom';

interface CardProps {
  title: string;
  icon: any;
  padding?: string;
  children: React.ReactNode;
}

interface ViewBookingProps {
  booking: ViewBooking;
  onSave: () => void;
}

const GeneralExport: React.FC<ViewBookingProps> = ({ booking, onSave }) => {
  const statusViitor = getNextStatusName(booking.status, booking.shippingType!);
  const bookingService = new BookingService();
  
  const changeStatus = (status : string|null) => {
    try {
      bookingService.changeBookingStatus(booking.id!, status!).then(() => {onSave()});
    } catch (error) {
      errorMessage('Statusul bookingului nu a putut fi actualizat!');
    }
  }
  
  const Card = ({ title, icon, children, padding='p-5' } : CardProps) => (
    <div className="bg-white rounded-lg border border-gray-200">
      <div className="px-5 py-3 border-b border-gray-200">
        <div className="flex items-center gap-2">
          {React.createElement(icon, { className: "w-4 h-4 text-gray-500" })}
          <h2 className="font-medium text-gray-900">{title}</h2>
        </div>
      </div>
      <div className={padding}>
        {children}
      </div>
    </div>
  );

  // @ts-ignore
  const InfoItem = ({ label, value, className = "" }) => (
    <div className={className}>
      <p className="text-xs font-medium text-gray-500 mb-1">{label}</p>
      <p className="text-sm text-gray-900 whitespace-pre-line">{value || '-'}</p>
    </div>
  );

  return (
    <div className="">
      <div className="space-y-6">
        {/* Top Grid - 4 columns */}
        <div className="grid lg:grid-cols-4 gap-6 sm:grid-cols-2 grid-cols-1">
          <Card title="Booking Info" icon={AlertCircle}>
            <div className="grid grid-cols-2 gap-4">
              <InfoItem label="Booking Number" value={booking.number} />
              <InfoItem label="Status" value={booking.status} />
              <InfoItem label="Creation Date" value={formatDate(booking.creationDate ? booking.creationDate : null)} />
              <InfoItem label="BKG. Type" value={`${booking.shippingType}(${booking.loadType})`} />
              <InfoItem label="Client" value={booking.client?.company} />
              <InfoItem label="Sales" value={booking.user?.name} />
            </div>
          </Card>

          <Card title="Route Info" icon={MapPin}>
            <div className="grid grid-cols-2 gap-4">
              <InfoItem label="Port of Loading" value={booking.pol} />
              <InfoItem label="Port of Discharge" value={booking.pod} />
              <InfoItem label="Loading Place" value={booking.loadingPlace} />
              <InfoItem label="Terminal" value={booking.terminal} />
              <Tooltip content="Editează booking">
                <NavLink to={`/bookings/form/${booking.id}`}>
                  <Button size="sm">Editează booking</Button>
                </NavLink>
              </Tooltip>
              {
                statusViitor ? <>
                  <div>
                    <Tooltip content="Modifică status">
                      <Button onClick={() => {changeStatus(statusViitor)}} size="sm">{ statusViitor }</Button>
                    </Tooltip>
                  </div>
                </> : <></>
              }
            </div>
          </Card>

          <Card title="Cargo Details" icon={Boxes}>
            <div className="space-y-4">
              <InfoItem label="HS Code" value={booking.hsCode} />
              <InfoItem label="Commodity" value={booking.commodity} />
              <InfoItem label="Notă" value={booking.note} />
            </div>
          </Card>

          <Card title="Partners" icon={Building2}>
            <div className="space-y-4">
              <div>
                <p className="text-xs font-medium text-gray-500 mb-1">Shipper</p>
                <p className="text-sm font-medium text-gray-900">{booking.shipperName}</p>
                <p className="text-sm font-medium text-gray-900 italic">{booking.shipperAddress}</p>
              </div>
              <div>
                <p className="text-xs font-medium text-gray-500 mb-1">Consignee</p>
                <p className="text-sm font-medium text-gray-900">{booking.consigneeName}</p>
                <p className="text-sm font-medium text-gray-900 italic">{booking.consigneeAddress}</p>
              </div>
              <div>
                <p className="text-xs font-medium text-gray-500 mb-1">Email avizare</p>
                <p className="text-sm font-medium text-gray-900">{booking.bookingEmail}</p>
              </div>
            </div>
          </Card>
        </div>

        {/* Bottom Routes Table */}
        <Card title="Routes" icon={Ship} padding="p-0">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-50">
                  <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 border-b border-gray-200">From</th>
                  <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 border-b border-gray-200">To</th>
                  <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 border-b border-gray-200">Vessel</th>
                  <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 border-b border-gray-200">ETD</th>
                  <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 border-b border-gray-200">ATD</th>
                  <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 border-b border-gray-200">ETA</th>
                  <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 border-b border-gray-200">ATA</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {booking.routes.map((route) => (
                  <tr key={route.id} className="hover:bg-gray-50">
                    <td className="px-2 py-2 text-sm">{route.from}</td>
                    <td className="px-2 py-2 text-sm">{route.to}</td>
                    <td className="px-2 py-2">
                      <p className="text-sm font-medium">{route.vesselName}</p>
                      <p className="text-xs text-gray-500">{route.vesselCode}</p>
                    </td>
                    <td className="px-2 py-2 text-sm">{formatDate(route.etd ? route.etd : null)}</td>
                    <td className="px-2 py-2 text-sm">{formatDate(route.atd ? route.atd : null)}</td>
                    <td className="px-2 py-2 text-sm">{formatDate(route.eta ? route.eta : null)}</td>
                    <td className="px-2 py-2 text-sm">{formatDate(route.ata ? route.ata : null)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card>

        <div className="">
          <BookingProgress currentStatus={booking.status} isExport={booking.shippingType!.includes('Export') ? true : false} />
        </div>
      </div>
    </div>
  );
};

export default GeneralExport;