import React, { useEffect, useMemo, useState } from 'react';
import { ViewContainerWithBookingData } from '../../interfaces/Booking';
import { Button, Modal } from 'flowbite-react';
import CustomSelect from '../../components/CustomSelect';
import { errorMessage, successMessage } from '../../functions/generalHelper';
import { ContainerService } from '../../services/ContainerService';
import Loader from '../../components/CustomLoader';

interface MoveContainerModal {
    containers: { number: string; bookingId: number; bookingNumber: string; }[];
    setContainers: React.Dispatch<React.SetStateAction<Partial<ViewContainerWithBookingData>[]>>;
    openModal: boolean,
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const MoveContainerModal: React.FC<MoveContainerModal> = ({ containers, setContainers, openModal, setOpenModal }) => {
    const [containerIndex, setContainerIndex] = useState<number | null>(null);
    const [bookings, setBookings] = useState<{ value: number, label: string }[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [newBooking, setNewBooking] = useState<number | null>(null);
    const containerService = useMemo(() => new ContainerService(), []);

    useEffect(() => {
        if (openModal) {
            setLoading(true);
            setContainerIndex(null);
            containerService.getExportBookings().then((data) => setBookings(data.map((item : any) => ({label: item.number, value: item.id})))).catch(() => {}).finally(() => setLoading(false));
        }
    }, [openModal]);

    const handleSubmit = () => {
        if (containerIndex !== null && newBooking) {
            const container = containers[containerIndex];
            containerService.moveContainerToBooking(container.bookingId, newBooking, container.number).then((data) => {
                setContainers((prev) => prev.map((item, index) => index === containerIndex ? data : item));
            }).catch(() => {});
            setNewBooking(null);
            setContainerIndex(null);
            setOpenModal(false);
            successMessage('Containerul a fost mutat cu succes');
        }else{
            errorMessage('Selectează un container');
        }
    }

    if (loading || containers.length === 0) {
        return (<Loader isLoading={loading} />);
    }

    return (
        <Modal show={openModal} size="lg" popup onClose={() => setOpenModal(false)}>
            <Modal.Header>
                Muta container
            </Modal.Header>
            <Modal.Body className="overflow-visible">
                <div className="space-y-2 pt-5">
                    <div className="gap-4">
                        <div className="mb-5">
                            <CustomSelect
                                value={{label: containerIndex!==null ? `${containers[containerIndex].number} - ${containers[containerIndex].bookingNumber}` : null, value: containerIndex}}
                                onChange={(data) => setContainerIndex(data.value)}
                                options={containers.map((container, index) => ({ value: index, label: `${container.number} - ${container.bookingNumber}` }))}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Container"
                            />
                        </div>
                        <div className="mb-5">
                            <CustomSelect
                                value={bookings.find(item => item.value === newBooking)}
                                onChange={(data) => setNewBooking(data.value)}
                                options={bookings}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Booking Nou"
                            />
                        </div>

                        <div className="col-span-2 flex justify-end">
                            <Button size="sm" onClick={handleSubmit}>Mută container</Button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default MoveContainerModal;