import { gql } from '@apollo/client';

export const GET_USER_PROFILE = gql`
  query GetUserProfile {
    me {
      id
      name
      email
      function
      emailPassword
      department
      permissions
    }
  }
`;