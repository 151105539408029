import React from 'react';
import { NavLink } from 'react-router-dom';
import { TbUsers } from "react-icons/tb";
import { File } from 'lucide-react';
import { AiOutlineProfile } from 'react-icons/ai';
import CheckPermission from './CheckPermission';

const SettingsNavBar: React.FC = () => {
  return (
    <div className="hidden bg-softprimary md:flex items-center px-5 text-black text-[0.95rem] font-medium h-9 ps-[155px]">
      <CheckPermission permissions={['SETTING_VIEW_ALL']}>
          <NavLink to="/settings/recommandations" className={({ isActive }) => (isActive ? 'flex items-center space-x-2 h-full font-bold text-darkprimary px-3 hover:text-main rounded-md' : 'flex items-center space-x-2 h-full hover:text-darkprimary px-3 rounded-md')}>
            <TbUsers className='h-4 w-4'/>
            <span className="mr-2">Recomandări</span>
          </NavLink>
      </CheckPermission>
      <CheckPermission permissions={['SETTING_VIEW_ALL']}>
        <NavLink to="/settings/notice" className={({ isActive }) => (isActive ? 'flex items-center space-x-2 h-full font-bold text-darkprimary px-3 hover:text-main rounded-md' : 'flex items-center space-x-2 h-full hover:text-darkprimary px-3 rounded-md')}>
          <File className='h-4 w-4'/>
          <span className="mr-2">Avizari exporturi</span>
        </NavLink>
      </CheckPermission>
      <NavLink to="/settings/profile" className={({ isActive }) => (isActive ? 'flex items-center space-x-2 h-full font-bold text-darkprimary px-3 hover:text-main rounded-md' : 'flex items-center space-x-2 h-full hover:text-darkprimary px-3 rounded-md')}>
        <AiOutlineProfile className='h-4 w-4'/>
        <span className="mr-2">Setari profil</span>
      </NavLink>
    </div>
  );
};

export default SettingsNavBar;