export interface Vessel { 
    id: number | null;
    terminal: string | null;
    from: string | null;
    to: string | null;
    service: string|null;
    serviceCode: string|null;
    name: string | null;
    code: string | null;
    etd: Date | null;
    atd: Date | null;
    eta: Date | null;
    ata: Date | null;
    arrivalDate?: Date | null;
    arrivalActual?: boolean;
    departureDate?: Date | null;
    departureActual?: boolean;
}

export const defaultVessel : Vessel = {
    id: null,
    terminal: '',
    from: '',
    to: '',
    service: '',
    serviceCode: '',
    name: '',
    code: '',
    etd: null,
    atd: null,
    eta: null,
    ata: null
}
export interface VesselForm { 
  startDate: Date;
  endDate: Date;
}
export const defaultVesselForm : VesselForm = {
  startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
  endDate: new Date(new Date().setDate(new Date().getDate() + 7)),
}

export interface VesselServiceLoop { 
  id: number | null;
  name: string;
  port: string; 
  lastPort: string; 
  firstBound: string;
  secondBound: string;
}

export const defaultServiceLoop : VesselServiceLoop = {
  id: null,
  name: '',
  port: '',
  lastPort: '',
  firstBound: '',
  secondBound: '',
}