import { Button } from 'flowbite-react';
import { CheckCircleIcon, LifeBuoyIcon, ShipIcon, TruckIcon } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';

const Index: React.FC = () => {
    const [selectedOption, setSelectedOption] = useState(null);
  
    const options = [
        {
          id: 'rail',
          title: 'Avizare tren',
          description: 'Gestionează avizările pentru transportul feroviar',
          icon: TruckIcon,
          color: 'from-blue-500 to-blue-600'
        },
        {
          id: 'csct',
          title: 'Avizare CSCT',
          description: 'Sistem de avizare pentru operațiuni CSCT',
          icon: LifeBuoyIcon,
          color: 'from-emerald-500 to-emerald-600'
        },
        {
          id: 'socep',
          title: 'Avizare SOCEP',
          description: 'Platformă de avizare pentru terminal SOCEP',
          icon: ShipIcon,
          color: 'from-amber-500 to-amber-600'
        }
    ];
  
    const handleOptionSelect = (optionId : any) => {
      setSelectedOption(optionId);
    };
  
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 flex flex-col items-center p-4">
        <div className="max-w-3xl w-full">
          <div className="text-center mb-12">
            <h1 className="text-3xl font-bold text-gray-800 mb-3">Selectați tipul de avizare</h1>
            <p className="text-gray-600 max-w-lg mx-auto">
              Alegeți una dintre opțiunile disponibile pentru a continua cu procesul de avizare
            </p>
          </div>
          
          <div className="grid md:grid-cols-3 gap-6">
            {options.map((option) => (
              <div
                key={option.id}
                onClick={() => handleOptionSelect(option.id)}
                className={`
                  relative bg-white rounded-xl shadow-lg overflow-hidden transition-all duration-300 cursor-pointer
                  hover:shadow-xl hover:-translate-y-1
                  ${selectedOption === option.id ? 'ring-2 ring-offset-2 ring-darkprimary' : ''}
                `}
              >
                {selectedOption === option.id && (
                  <div className="absolute top-3 right-3 w-6 h-6 text-darkprimary">
                    <CheckCircleIcon />
                  </div>
                )}
                
                <div className={`h-2.5 w-full bg-gradient-to-r ${option.color}`}></div>
                
                <div className="p-6">
                <div className="text-3xl mb-4"><option.icon /></div>
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">{option.title}</h3>
                  <p className="text-gray-600 text-sm">{option.description}</p>
                </div>
                
                <div className={`
                  mt-4 p-4 bg-gray-50 border-t transition-all duration-300
                  ${selectedOption === option.id ? 'bg-blue-50' : ''}
                `}>
                  <button 
                    className={`
                      w-full py-2 rounded-md font-medium transition-colors
                      ${selectedOption === option.id 
                        ? 'bg-primary' 
                        : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}
                    `}
                  >
                    {selectedOption === option.id ? 'Selectat' : 'Selectează'}
                  </button>
                </div>
              </div>
            ))}
          </div>
          
          {selectedOption && (
            <div className="mt-8 flex justify-center">
                <NavLink to={`/settings/notice/editor/${options.find(o => o.id === selectedOption)?.id}`}>
                  <Button size="sm" type="button">Continuă cu {options.find(o => o.id === selectedOption)?.title}</Button>
                </NavLink>
            </div>
          )}
        </div>
      </div>
    );
};

export default Index;