import { Modal, Button } from 'flowbite-react';
import React, { useState, useEffect } from 'react';
import Input from '../../../../components/Input';
import { ContainerService } from '../../../../services/ContainerService';
import { errorMessage, successMessage } from '../../../../functions/generalHelper';

export interface NoticeBooking {id: number; number: string; email: string; containers: {number: string; id: number; iso: string|null|undefined; departureCarNumber: string|null|undefined; car: string|null|undefined; shipperName: string|null|undefined; isRail: boolean|undefined;}[];}

interface ExportNoticeModalProps {
    bookings: NoticeBooking[];
    openModal: boolean,
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    onSendNotice: () => void;
}

const ExportNoticeModal: React.FC<ExportNoticeModalProps> = ({ bookings, openModal, setOpenModal, onSendNotice }) => {
    const [emailInputs, setEmailInputs] = useState<{[key: number]: string}>({});
    const [errors, setErrors] = useState<{[key: number]: string}>({});
    const containerService = new ContainerService();

    useEffect(() => {
        bookings.forEach(booking => {
            booking.containers.forEach(container => {
                let errors = "";
                if(container.number === undefined || container.number === null || container.number.includes('TBA')){
                    errors += `"Număr container nu este avizat" `;
                }
                if(container.iso === undefined || container.iso === null || container.iso === ''){
                    errors += `"ISO" `;
                }
                if(container.departureCarNumber === undefined || container.departureCarNumber === null || container.departureCarNumber === ''){
                    errors += `"Număr mașina/CFR plecare" `;
                }
                if(!container.isRail){
                    if(container.car === undefined || container.car === null || container.car === ''){
                        errors += `"Telefon sofer" `;
                    }
                    if(container.shipperName === undefined || container.shipperName === null || container.shipperName === ''){
                        errors += `"Loc de incarcare" `;
                    }
                }

                setErrors(prev => ({
                    ...prev,
                    [container.id]: errors
                }));
            });
        });
    }, [bookings]);

    useEffect(() => {
        const initialEmails = bookings.reduce((acc, booking) => {
            acc[booking.id] = booking.email || '';
            return acc;
        }, {} as {[key: number]: string});
        
        setEmailInputs(initialEmails);
    }, [bookings]);

    const handleEmailChange = (bookingId: number, value: string) => {
        setEmailInputs(prev => ({
            ...prev,
            [bookingId]: value
        })); 
    };

    const handleSubmit = async () => {
        onSendNotice();
        try {
            await containerService.sendExportNotice(bookings.map(booking => ({
                bookingId: booking.id,
                email: emailInputs[booking.id],
                containers: booking.containers.map(c => c.id)
            })));
            successMessage('Avizările au fost trimise cu succes!');
        }catch(e){
            errorMessage('A apărut o eroare la trimiterea avizărilor!');
        }

        console.log('Sending notices with emails:', emailInputs);
    };

    return (
        <Modal show={openModal} size="5xl" onClose={() => setOpenModal(false)}>
            <Modal.Header>
                Trimite avizare
            </Modal.Header>
            <Modal.Body className="overflow-visible">
                <div className="max-h-[60vh] overflow-y-auto divide-y divide-gray-200">
                    {bookings.map((booking) => (
                        <div key={`div-${booking.id}`} className="flex items-center justify-between py-4">
                            <div className="flex flex-col w-1/2">
                                <span key={`span1-${booking.id}`} className="font-medium text-gray-900">Booking: {booking.number}</span>
                                <span key={`span2-${booking.id}`} className="text-sm text-gray-500">
                                    Containere: {booking.containers.map(c => c.number).join(', ')}
                                </span>
                                {
                                    booking.containers.map(c => (
                                        errors[c.id] && errors[c.id] !== '' && (
                                            <span key={`span3-${booking.id}-${c.id}`} className="text-sm text-red-500">
                                                Erori {c.number}: {errors[c.id]}
                                            </span>
                                        )
                                    ))
                                }
                            </div>
                            <div className="w-1/2">
                                <Input
                                    key={`email-${booking.id}`}
                                    id={`email-${booking.id}`}
                                    label="Email(uri) separate prin ;"
                                    type="text"
                                    value={emailInputs[booking.id] || ''}
                                    onChange={(e) => handleEmailChange(booking.id, e.target.value)}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex justify-end mt-4">
                    <Button onClick={handleSubmit} size={'sm'}>
                        Trimite avizare
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ExportNoticeModal;
