import React, { useState, useCallback } from 'react';
import { Button, Modal } from 'flowbite-react';
import Input from '../../components/Input';

interface LoadingListModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    submit: (port: string, rows: number[]) => void;
    rows: number[];
}

const LoadingListModal: React.FC<LoadingListModalProps> = ({ openModal, setOpenModal, submit, rows }) => {
    const [port, setPort] = useState<string>('TRKMX');

    const handleSubmit = useCallback(async () => {
        submit(port, rows);
        setOpenModal(false);
    }, [port, rows]);

    return (
        <Modal show={openModal} size="sm" popup onClose={() => setOpenModal(false)}>
            <Modal.Header>
                Creaza lista de incarcare
            </Modal.Header>
            <Modal.Body className="overflow-visible">
                <div className="space-y-2 pt-5">
                    <Input 
                        id="port"
                        label="Port de descarcare(TRKMX)"
                        value={port}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPort(e.target.value)}
                    />
                </div>
                <div className="flex justify-end mt-5">
                    <Button onClick={handleSubmit}>Trimite</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default LoadingListModal;