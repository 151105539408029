import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs } from 'flowbite-react';
import { HiClipboardList, HiUserCircle, HiTruck, HiDocumentText, HiCube } from 'react-icons/hi';
import { BookingService } from '../../services/BookingService';
import { ViewBooking } from '../../interfaces/Booking';
import General from './viewTabs/General';
import Movements from './viewTabs/Movements';
import Bills from './viewTabs/Bills';
import Manifest from './viewTabs/Manifest';
import Containers from './viewTabs/Containers';
import renderSkeleton from '../../components/SkeletonLoader';
import Files from './viewTabs/Files';
import GeneralExport from './exportTabs/GeneralExport';
import ContainersExport from './viewTabs/ContainersExport';
import History from './viewTabs/History';
import ExportBills from './viewTabs/ExportBills';

const View: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [booking, setBooking] = useState<ViewBooking>({} as ViewBooking);
  const [historyTab, setHistoryTab] = useState<number>(0);
  const bookingService = new BookingService();

  const fetchBooking = React.useCallback(() => {
    if (id) {
      bookingService.getBookingById(parseInt(id)).then((data) => { setBooking(data); setLoading(false); });
    }
  }, [id]);

  useEffect(() => {
    fetchBooking();
  }, [id]);
  

  const handleChangeTab = useCallback((active: number) => {
    if(active === 5){
      setHistoryTab(prev => prev + 1);
    }
  }, [historyTab]);

  return loading ? renderSkeleton() : (
    <div className="px-3">
      <h2 className="text-xl font-bold mb-1 mt-1">Detalii Booking - {booking.number}</h2>
      <Tabs aria-label="Booking tabs" onActiveTabChange={handleChangeTab}>
        {
          booking.shippingType!.includes('Export') ? 
            <Tabs.Item active title="General" icon={HiClipboardList}>
              <GeneralExport booking={booking} onSave={fetchBooking} />
            </Tabs.Item>
          : 
            <Tabs.Item active title="General" icon={HiClipboardList}>
              <General booking={booking} onSave={fetchBooking} />
            </Tabs.Item>
        }
        <Tabs.Item title="Mișcări" icon={HiTruck}>
          <Movements booking={booking} />
        </Tabs.Item>
        {
          booking.shippingType!.includes('Export') ? 
            <Tabs.Item title="B/L's" icon={HiUserCircle}>
              <ExportBills booking={booking} setBooking={setBooking}/>
            </Tabs.Item>
          :
            <Tabs.Item title="B/L's" icon={HiUserCircle}>
              <Bills booking={booking} setBooking={setBooking}/>
            </Tabs.Item>
        }
        
        {
          !booking.shippingType!.includes('Export') && 
          <Tabs.Item title="Marfa" icon={HiDocumentText}>
            <Manifest booking={booking} setBooking={setBooking} />
          </Tabs.Item>
        }
        {
        booking.shippingType!.includes('Export') ? 
          <Tabs.Item active title="Containere" icon={HiCube}>
            <ContainersExport booking={booking} setBooking={setBooking} fetchBooking={fetchBooking} />
          </Tabs.Item>
        : 
          <Tabs.Item active title="Containere" icon={HiCube}>
            <Containers booking={booking} setBooking={setBooking} />
          </Tabs.Item>
        }
        <Tabs.Item title="Fisiere" icon={HiCube}>
          <Files booking={booking} setBooking={setBooking} />
        </Tabs.Item>
        <Tabs.Item title="Istoric" icon={HiCube} key={`history-${historyTab}`}>
          <History id={booking.id} />
        </Tabs.Item>
        {/* <Tabs.Item title="Facturi" icon={HiCurrencyDollar}>
          <Invoices />
        </Tabs.Item>
        <Tabs.Item title="Costuri" icon={HiCurrencyDollar}>
          <Costs />
        </Tabs.Item> */}
      </Tabs>
    </div>
  );
};

export default View;