import { ColDef, ColGroupDef, ValueFormatterParams, ValueSetterParams } from "ag-grid-community";
import { CellRenderer } from "../../../functions/agGridHelper";
import { BookingLinkRenderer } from "./BookingLinkRenderer";
import DateEditor from "../../../components/AgGrid/DateEditor";
import { formatDate } from "../../../functions/dateHelper";

export const parseDate = (date: Date | string): Date | string | null => {
    // Dacă parametrul este deja Date, îl returnăm direct
    if (date instanceof Date) {
      return date;
    }
  
    // Dacă este string, verificăm formatul
    if (typeof date === 'string') {
      // Verificăm dacă string-ul respectă formatul DD.MM.YYYY sau DD/MM/YYYY
      const dotRegex = /^(\d{2})\.(\d{2})\.(\d{4})$/;
      const slashRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
      
      const dotMatch = date.match(dotRegex);
      const slashMatch = date.match(slashRegex);
      
      // Procesăm fie formatul cu punct, fie cel cu slash
      const match = dotMatch || slashMatch;
  
      if (match) {
        // Extragem ziua, luna și anul
        const [, day, month, year] = match;
        
        // Creăm un nou obiect Date (luna -1 pentru că lunile în JavaScript încep de la 0)
        const parsedDate = new Date(+year, +month - 1, +day);
  
        // Verificăm dacă data este validă (ex: 31.02.2025 nu este o dată validă)
        if (
          parsedDate.getDate() === +day &&
          parsedDate.getMonth() === +month - 1 &&
          parsedDate.getFullYear() === +year
        ) {
          return parsedDate;
        }
      }
    }
  
    // În toate celelalte cazuri returnăm null
    return null;
}

const getTare = (size: string, type: string) : number|string => {
    if(size == '20' && type == "DC"){
        return 2000;
    }
    if(
        (size == '40' && type == "RQ") || 
        (size == '40' && type == "RF") || 
        (size == '45')
    ){
        return 5000;
    }
    if(size == '40'){
        return 4000;
    }
    return '';
}

const formatVgm = (data : any) => {
    if(data.size && data.type && data.weight) {
        const tare = getTare(data.size, data.type);
        if(typeof data.weight == "number" && tare!==''){
            const sum = parseFloat((tare + data.weight).toFixed(2));
            return Number.isInteger(sum) ? Math.trunc(sum) : sum;
        }
    }
    return '';
}

export const columns = (modificaCelula : (params : ValueSetterParams, column : string) => void, type: string, actionsColumn?: any) : (ColDef | ColGroupDef)[] => {
    const containerColumns: (ColDef | ColGroupDef)[] = [
        { 
            headerName: 'ID', 
            field: 'id', 
            filter: 'agNumberColumnFilter',
            cellClass: 'coloaneBucuresti', 
            floatingFilter: type !== 'booking',
            minWidth: 70,
            hide: true
        },
        { 
            headerName: 'Număr unic', 
            field: 'containerNumber', 
            editable: true,
            cellClass: 'group coloaneBucuresti', 
            cellRenderer: CellRenderer,
            filter: 'agTextColumnFilter',
            floatingFilter: type !== 'booking',
            minWidth: 140,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'containerNumber');
                return true;
            }
        },
        { 
            headerName: 'F/V', 
            field: 'booking.lastVesselCode', 
            enableRowGroup: true,
            editable: true,
            cellClass: 'group coloaneBucuresti', 
            cellRenderer: CellRenderer,
            filter: 'agTextColumnFilter',
            floatingFilter: type !== 'booking',
            minWidth: 120,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'lastVesselCode');
                return true;
            }
        },
        { 
            headerName: 'M/V', 
            field: 'booking.motherVesselCode', 
            enableRowGroup: true,
            editable: true,
            cellClass: 'group coloaneBucuresti', 
            cellRenderer: CellRenderer,
            filter: 'agTextColumnFilter',
            floatingFilter: type !== 'booking',
            minWidth: 120,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'motherVesselCode');
                return true;
            }
        },
        { 
            headerName: 'Terminal', 
            field: 'booking.terminal', 
            enableRowGroup: true,
            editable: true,
            cellClass: 'group coloaneBucuresti', 
            cellRenderer: CellRenderer,
            filter: 'agTextColumnFilter',
            floatingFilter: type !== 'booking',
            minWidth: 120,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'terminal');
                return true;
            }
        },
        { 
            headerName: 'Booking', 
            field: 'booking.number', 
            enableRowGroup: true,
            cellClass: 'group coloaneBucuresti', 
            filter: 'agTextColumnFilter',
            floatingFilter: type !== 'booking',
            minWidth: 140,
            cellRenderer: BookingLinkRenderer
        },
    ];

    const bookingColumns: (ColDef | ColGroupDef)[] = [
        { 
            headerName: 'Număr unic', 
            field: 'containerNumber', 
            editable: true,
            cellClass: 'group coloaneBucuresti', 
            cellRenderer: CellRenderer,
            filter: 'agTextColumnFilter',
            floatingFilter: type !== 'booking',
            sortable: false,
            minWidth: 140,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'containerNumber');
                return true;
            }
        },
    ];

    const restColumns: (ColDef | ColGroupDef)[] = [
        { 
            headerName: 'Size', 
            field: 'size', 
            enableRowGroup: true,
            editable: true,
            cellClass: 'group coloaneBucuresti', 
            cellRenderer: CellRenderer,
            filter: 'agTextColumnFilter',
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            minWidth: 60,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'size');
                return true;
            }
        },
        { 
            headerName: 'Type', 
            field: 'type', 
            enableRowGroup: true,
            editable: true,
            cellClass: 'group coloaneBucuresti', 
            cellRenderer: CellRenderer,
            filter: 'agTextColumnFilter',
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            minWidth: 70,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'type');
                return true;
            }
        },
        { 
            headerName: 'ISO', 
            field: 'iso', 
            enableRowGroup: true,
            editable: true,
            cellClass: 'group coloaneBucuresti', 
            cellRenderer: CellRenderer,
            filter: 'agTextColumnFilter',
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            minWidth: 70,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'iso');
                return true;
            }
        },
        { 
            headerName: 'Loc de încărcare', 
            field: 'loadingPlace', 
            enableRowGroup: true,
            editable: true,
            cellClass: 'group coloaneBucuresti', 
            cellRenderer: CellRenderer,
            filter: 'agTextColumnFilter',
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            minWidth: 100,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'loadingPlace');
                return true;
            }
        },
        { 
            headerName: 'De facturat', 
            field: 'note', 
            enableRowGroup: true,
            editable: true,
            cellClass: 'group coloaneBucuresti', 
            cellRenderer: CellRenderer,
            filter: 'agTextColumnFilter',
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            minWidth: 100,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'note');
                return true;
            }
        },
        { 
            headerName: 'VGM', 
            field: 'vgmNumber', 
            editable: true,
            cellClass: 'group coloaneBucuresti', 
            cellRenderer: CellRenderer,
            filter: 'agNumberColumnFilter',
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            minWidth: 90,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'vgmNumber');
                return true;
            }
        },
        { 
            headerName: 'VGM estimat', 
            field: 'estVgm', 
            cellClass: 'group coloaneBucuresti', 
            filter: 'agNumberColumnFilter',
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            minWidth: 90,
            cellRenderer: (params : ValueFormatterParams) => formatVgm(params.data)
        },
        { 
            headerName: 'Zile libere', 
            field: 'freeDays', 
            editable: true,
            cellClass: 'group coloaneBucuresti', 
            cellRenderer: CellRenderer,
            filter: 'agNumberColumnFilter',
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            minWidth: 90,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'freeDays');
                return true;
            }
        },
        { 
            headerName: 'Detenție', 
            field: 'detention', 
            enableRowGroup: true,
            editable: true,
            cellClass: 'group coloaneBucuresti', 
            cellRenderer: CellRenderer,
            filter: 'agTextColumnFilter',
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            minWidth: 70,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'detention');
                return true;
            }
        },
        {
            headerName: 'P. Date',
            field: 'pDate',
            sortable: false,
            cellClass: 'coloaneBucuresti',
            filter: 'agDateColumnFilter',
            floatingFilter: type !== 'booking',
            filterParams: {
                inRangeInclusive: true,
                defaultOption: 'inRange'
            },
            valueFormatter: (params : ValueFormatterParams) => formatDate(params.value!)!,
            editable: true,
            minWidth: 100,
            cellEditor: DateEditor,
            valueSetter: (params : any) => {
                modificaCelula(params, 'pDate');
                return true;
            },
            valueParser: (params: any) => (parseDate(params.newValue))
        },
        {
            headerName: 'A. Date',
            field: 'aDate',
            sortable: false,
            cellClass: 'coloaneBucuresti',
            filter: 'agDateColumnFilter',
            floatingFilter: type !== 'booking',
            filterParams: {
                inRangeInclusive: true,
                defaultOption: 'inRange'
            },
            valueFormatter: (params : ValueFormatterParams) => formatDate(params.value!)!,
            editable: true,
            minWidth: 100,
            cellEditor: DateEditor,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'aDate');
                return true;
            },
            valueParser: (params: any) => (parseDate(params.newValue))
        },
        { 
            headerName: 'Rail?', 
            field: 'isRail', 
            enableRowGroup: true,
            cellClass: 'coloaneBucuresti', 
            minWidth: 70,
            editable: true,
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            filter: 'agSelectFilter',
            cellEditor: 'agSelectCellEditor',
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'isRail');
                return true; 
            },
        },
        { 
            headerName: 'Telefon sofer', 
            field: 'car', 
            editable: true,
            cellClass: 'group coloaneBucuresti', 
            cellRenderer: CellRenderer,
            filter: 'agTextColumnFilter',
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            minWidth: 120,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'car');
                return true;
            }
        },
        { 
            headerName: 'Exportator', 
            field: 'shipperName', 
            editable: true,
            cellClass: 'group coloaneConstanta', 
            cellRenderer: CellRenderer,
            filter: 'agTextColumnFilter',
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            minWidth: 110,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'shipperName');
                return true;
            }
        },
        { 
            headerName: 'Destinatar', 
            field: 'consigneeName', 
            editable: true,
            cellClass: 'group coloaneConstanta', 
            cellRenderer: CellRenderer,
            filter: 'agTextColumnFilter',
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            minWidth: 110,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'consigneeName');
                return true;
            }
        },
        { 
            headerName: 'Greutate', 
            field: 'weight', 
            editable: true,
            cellClass: 'group coloaneConstanta', 
            cellRenderer: CellRenderer,
            filter: 'agNumberColumnFilter',
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            minWidth: 90,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'weight');
                return true;
            }
        },
        { 
            headerName: 'Volum', 
            field: 'volume', 
            editable: true,
            cellClass: 'group coloaneConstanta', 
            cellRenderer: CellRenderer,
            filter: 'agNumberColumnFilter',
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            minWidth: 80,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'volume');
                return true;
            }
        },
        { 
            headerName: 'Sigiliu', 
            field: 'sealNumber', 
            editable: true,
            cellClass: 'group coloaneConstanta', 
            cellRenderer: CellRenderer,
            filter: 'agTextColumnFilter',
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            minWidth: 110,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'sealNumber');
                return true;
            }
        },
        { 
            headerName: 'MRN', 
            field: 'mrnNumber', 
            editable: true,
            cellClass: 'group coloaneConstanta', 
            cellRenderer: CellRenderer,
            filter: 'agTextColumnFilter',
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            minWidth: 190,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'mrnNumber');
                return true;
            }
        },
        { 
            headerName: 'Număr RI', 
            field: 'riNumber', 
            editable: true,
            cellClass: 'group coloaneConstanta', 
            cellRenderer: CellRenderer,
            filter: 'agTextColumnFilter',
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            minWidth: 130,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'riNumber');
                return true;
            }
        },
        { 
            headerName: 'Număr vamal', 
            field: 'customsNumber', 
            editable: true,
            cellClass: 'group coloaneConstanta', 
            cellRenderer: CellRenderer,
            filter: 'agTextColumnFilter',
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            minWidth: 130,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'customsNumber');
                return true;
            }
        },
        { 
            headerName: 'Marfă', 
            field: 'commodity', 
            editable: true,
            cellClass: 'group coloaneConstanta', 
            cellRenderer: CellRenderer,
            filter: 'agTextColumnFilter',
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            minWidth: 150,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'commodity');
                return true;
            }
        },
        { 
            headerName: 'HS Code', 
            field: 'hsCode', 
            editable: true,
            cellClass: 'group coloaneConstanta', 
            cellRenderer: CellRenderer,
            filter: 'agTextColumnFilter',
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            minWidth: 100,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'hsCode');
                return true;
            }
        },
        { 
            headerName: 'Pachete', 
            field: 'packageNumber', 
            editable: true,
            cellClass: 'group coloaneConstanta', 
            cellRenderer: CellRenderer,
            filter: 'agNumberColumnFilter',
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            minWidth: 70,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'packageNumber');
                return true;
            }
        },
        { 
            headerName: 'Număr mașina/CFR sosire', 
            field: 'arrivalCarNumber', 
            editable: true,
            cellClass: 'group coloaneConstanta', 
            cellRenderer: CellRenderer,
            filter: 'agTextColumnFilter',
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            minWidth: 186,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'arrivalCarNumber');
                return true;
            }
        },
        { 
            headerName: 'Număr mașina/CFR plecare', 
            field: 'departureCarNumber', 
            editable: true,
            cellClass: 'group coloaneConstanta', 
            cellRenderer: CellRenderer,
            filter: 'agTextColumnFilter',
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            minWidth: 200,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'departureCarNumber');
                return true;
            }
        },
        { 
            headerName: 'RfTemp', 
            field: 'reeferTemp', 
            editable: true,
            cellClass: 'group coloaneConstanta', 
            cellRenderer: CellRenderer,
            filter: 'agTextColumnFilter',
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            minWidth: 70,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'reeferTemp');
                return true;
            }
        },
        { 
            headerName: 'ImdgDetails', 
            field: 'imoDetails', 
            editable: true,
            cellClass: 'group coloaneConstanta', 
            cellRenderer: CellRenderer,
            filter: 'agTextColumnFilter',
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            minWidth: 100,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'imoDetails');
                return true;
            }
        },
        { 
            headerName: 'OOG DIMS', 
            field: 'oogDims', 
            editable: true,
            cellClass: 'group coloaneConstanta', 
            cellRenderer: CellRenderer,
            filter: 'agTextColumnFilter',
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            minWidth: 90,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'oogDims');
                return true;
            }
        },
        { 
            headerName: 'REMARKS', 
            field: 'remarks', 
            editable: true,
            cellClass: 'group coloaneConstanta', 
            cellRenderer: CellRenderer,
            filter: 'agTextColumnFilter',
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            minWidth: 90,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'remarks');
                return true;
            }
        },
        { 
            headerName: 'Firma transport plecare', 
            field: 'shippingCompany', 
            editable: true,
            cellClass: 'group coloaneConstanta', 
            cellRenderer: CellRenderer,
            filter: 'agTextColumnFilter',
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            minWidth: 140,
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'shippingCompany');
                return true;
            }
        },
        { 
            headerName: 'IMO?', 
            field: 'dangerCargo', 
            enableRowGroup: true,
            cellClass: 'coloaneConstanta', 
            minWidth: 70,
            editable: true,
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            filter: 'agSelectFilter',
            cellEditor: 'agSelectCellEditor',
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'dangerCargo');
                return true; 
            },
        },
        { 
            headerName: 'SOC?', 
            field: 'soc',
            enableRowGroup: true,
            cellClass: 'coloaneConstanta', 
            minWidth: 70,
            editable: true,
            floatingFilter: type !== 'booking',
            sortable: type === 'containere',
            filter: 'agSelectFilter',
            cellEditor: 'agSelectCellEditor',
            valueSetter: (params : ValueSetterParams) => {
                modificaCelula(params, 'soc');
                return true; 
            },
        },
    ];

    const statusAvizare : (ColDef | ColGroupDef)[] = [
        { 
            headerName: 'Status avizare', 
            field: 'noticeStatus', 
            cellClass: 'coloaneBucuresti', 
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            minWidth: 120,
        },
    ];

    return [
        ...(type === "containere" ? containerColumns : bookingColumns),
        ...restColumns,
        ...(type === "containere" || type === "viewBooking" ? statusAvizare : []),
        ...(type === "booking" || type === "viewBooking" ? [actionsColumn] : []),
    ];
}