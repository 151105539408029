import React from 'react';
import { FaWhatsapp, FaEnvelope } from 'react-icons/fa';

const ContactButtons = () => {
  const openWhatsApp = () => {
    const message = encodeURIComponent("Buna Serban, am urmatoarea intrebare referitoare la program:");
    window.open(`https://wa.me/40770635586?text=${message}`, '_blank')
  };

  const openEmail = () => {
    const subject = encodeURIComponent("Intrebare program TLS");
    const body = encodeURIComponent("Buna Serban,\n\nam urmatoarea intrebare referitoare la program:\n\n");
    window.open(`mailto:office@freevox.ro?subject=${subject}&body=${body}`);
  };

  if(localStorage.getItem('ascundeButoane')){
    return <></>;
  }

  return (
    <div className="fixed bottom-2 left-6 flex-row gap-4 z-50 hidden md:flex">
      <button 
        onClick={openWhatsApp}
        className="bg-green-500 hover:bg-green-600 p-3 rounded-full shadow-lg transform hover:scale-110 transition-all duration-200"
        aria-label="Contact on WhatsApp"
      >
        <FaWhatsapp className="text-white text-2xl" />
      </button>

      <button 
        onClick={openEmail}
        className="bg-blue-500 hover:bg-blue-600 p-4 rounded-full shadow-lg transform hover:scale-110 transition-all duration-200"
        aria-label="Send email"
      >
        <FaEnvelope className="text-white text-lg" /> 
      </button>
    </div>
  );
};

export default ContactButtons;