import React, { useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { HtmlEditor, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar, Table } from '@syncfusion/ej2-react-richtexteditor';
import "../../editor.css";
import { RecommandationService } from '../../services/RecommandationService';
import { Button } from 'flowbite-react';
import { successMessage } from '../../functions/generalHelper';

const Editor: React.FC = () => {
    const { slug } = useParams<{ slug?: string }>();
    const optionService = new RecommandationService();
    const [content, setContent] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if(slug){
            setLoading(true);
            optionService.getExportNotice(slug).then((data) => {
                setContent(data.message);
                setLoading(false);
            });
        }
    }, [slug]);

    const submit = () => {
        optionService.saveExportNotice(slug!, content).then((data) => {          
            successMessage(data.message);
        });
    }
    
    const toolbarSettings: object = {
        items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
        'FontSize', 'FontColor', 'BackgroundColor',
        'LowerCase', 'UpperCase', '|',
        'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
        'Outdent', 'Indent', 'CreateTable', '|',
        'CreateLink', '|', 'ClearFormat',
        'SourceCode', 'FullScreen', '|', 'Undo', 'Redo']
    };

    if(loading){
        return <div>Loading...</div>;
    }
    
    return (
        <>
            <div className="">
                <RichTextEditorComponent className="max-w-[1200px] mx-auto noticeEditor" toolbarSettings={toolbarSettings} change={(e) => setContent(e.value)} >
                    <div dangerouslySetInnerHTML={{ __html: content}} />
                    <Inject services={[Toolbar, Link, HtmlEditor, QuickToolbar, Table]}/>
                </RichTextEditorComponent>
                <div className="flex justify-end space-x-4 mt-3 max-w-[1200px] mx-auto">
                    <NavLink to="/settings/notice/index">
                        <Button size="sm" color="gray">Înapoi</Button>
                    </NavLink>
                    <Button size="sm" onClick={submit}>Salvează</Button>
                </div>
            </div>
        </>
    );
};

export default Editor;