import React, { useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { CellValueChangedEvent, ColDef } from 'ag-grid-community';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Booking, CargoManifest, ViewBooking } from '../../../../interfaces/Booking';
import { CellRenderer } from '../../../../functions/agGridHelper';
import { AG_GRID_LOCALE_RO } from '../../../../functions/langHelper';
import Actions from '../../../../components/Actions';
import { BookingService } from '../../../../services/BookingService';
import { successMessage } from '../../../../functions/generalHelper';

interface CargoManifestTableProps {
  booking: ViewBooking;
  setBooking: React.Dispatch<React.SetStateAction<ViewBooking>>;
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
}

const CargoManifestTable: React.FC<CargoManifestTableProps> = ({ booking, onEdit, onDelete, setBooking }) => {
  const bookingService = new BookingService();

  const columns: ColDef<CargoManifest>[] = [
    { 
      headerName: 'B/L', 
      field: 'bookingBillId', 
      minWidth: 160,
      valueFormatter: (params) => booking.bookingBills?.find(bill => bill.id === params.value)?.code || '',
    },
    { 
      headerName: 'Container', 
      field: 'bookingContainerId', 
      minWidth: 160,
      valueFormatter: (params) => {
        return booking.containers?.find(c => c.id === params.value)?.containerNumber || '';
      },
    },
    { 
      headerName: 'HS Code', 
      field: 'hsCode', 
      editable: true,
      cellClass: 'group', 
      cellRenderer: CellRenderer,
      valueSetter: (params) => {
        modificaCelula(params, 'hsColde');
        return params.newValue;
      } 
    },
    { 
      headerName: 'Numar pachete', 
      field: 'packageNumber', 
      editable: true,
      cellClass: 'group', 
      cellRenderer: CellRenderer,
      valueSetter: (params) => {
        modificaCelula(params, 'packageNumber');
        return params.newValue;
      } 
    },
    { 
      headerName: 'Tip pachete', 
      field: 'packageType', 
      editable: true,
      cellClass: 'group', 
      cellRenderer: CellRenderer,
      valueSetter: (params) => {
        modificaCelula(params, 'packageType');
        return params.newValue;
      } 
    },
    { 
      headerName: 'Greutate brută', 
      field: 'weight',
      editable: true,
      cellClass: 'group', 
      cellRenderer: CellRenderer,
      valueSetter: (params) => {
        modificaCelula(params, 'weight');
        return params.newValue;
      } 
    },
    { 
      headerName: 'Volum', 
      field: 'volume',
      editable: true,
      cellClass: 'group', 
      cellRenderer: CellRenderer,
      valueSetter: (params) => {
        modificaCelula(params, 'volume');
        return params.newValue;
      } 
    },
    {
      headerName: 'Acțiuni',
      width: 150, 
      maxWidth: 150,
      field: 'id',
      cellRenderer: (params: any) => (
        <div>
          <Actions 
            id={params.data.id} 
            onEdit={() => onEdit(params.data.id)} 
            onDelete={() => onDelete(params.data.id)} 
            moduleName="marfă"
          />
        </div>
      )
    }
  ];

  const modificaCelula = async (params : any, coloana : string) => {
    const updatedCargoManifest = {
      ...booking.cargoManifests.find(manifest => manifest.id! === params.data.id!)!,
      [coloana!]: params.newValue
    };
  
    // Actualizăm containerul în backend
    const savedCargo = await bookingService.createBookingCargo(updatedCargoManifest);
  
    // Actualizăm state-ul local cu noul container
    setBooking(prev => ({
      ...prev,
      cargoManifests: prev.cargoManifests.map(manifest => 
        manifest.id === savedCargo.id ? savedCargo : manifest
      )
    }));
  
    successMessage('Marfă editată cu succes!');
  }

  return (
    <div className="ag-theme-quartz">
      <AgGridReact 
        localeText={AG_GRID_LOCALE_RO}
        columnDefs={columns} 
        rowData={booking.cargoManifests}
        defaultColDef={{ flex: 1, minWidth: 20 }}
        domLayout='autoHeight'
        pagination={false}
        gridOptions={{rowHeight: 35}}
        enableRangeSelection={true}
        suppressClickEdit={true}
        stopEditingWhenCellsLoseFocus={true}
      />
    </div>
  );
};

export default CargoManifestTable;