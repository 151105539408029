import React from 'react';
import { IFloatingFilterParams } from 'ag-grid-community';
import { IFloatingFilterReactComp } from 'ag-grid-react';

interface FloatingFilterButtonProps extends IFloatingFilterParams {
  // Add any additional props if needed
}

export default class FloatingFilterButton extends React.Component<FloatingFilterButtonProps> 
  implements IFloatingFilterReactComp {
  
  private readonly inputRef = React.createRef<HTMLInputElement>();

  onParentModelChanged(parentModel: any): void {
    // Not needed for this implementation as we're just opening the filter
  }

  componentDidMount() {
    // Make sure AG Grid is properly initialized
    if (this.inputRef.current) {
      this.inputRef.current.addEventListener('click', this.handleClick);
    }
  }

  componentWillUnmount() {
    // Clean up event listeners
    if (this.inputRef.current) {
      this.inputRef.current.removeEventListener('click', this.handleClick);
    }
  }

  handleClick = (event: any) => {
    event.stopPropagation();
    
    // This is the key part - calling showParentFilter() will open the filter menu
    this.props.showParentFilter();
  }

  render() {
    return (
        <div 
        role="presentation" 
        data-ref="eFloatingFilterText" 
        className="ag-labeled ag-label-align-left ag-text-field ag-input-field"
        onClick={this.handleClick}
      >
        <div 
          data-ref="eLabel" 
          className="ag-input-field-label ag-label ag-hidden ag-text-field-label" 
          aria-hidden="true" 
          role="presentation" 
          id="ag-170-label"
        ></div>
        <div 
          data-ref="eWrapper" 
          className="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper" 
          role="presentation"
        >
          <input 
            ref={this.inputRef}
            data-ref="eInput" 
            className="ag-input-field-input ag-text-field-input" 
            type="text" 
            id="ag-170-input" 
            aria-label="Tip Intrare Filtru" 
            readOnly
          />
          <div className='absolute top-0 left-0 w-full h-full bg-[#f1f1f2] z-1 rounded' style={{
            color: 'var(--ag-disabled-foreground-color)',
            backgroundColor: 'var(--ag-input-disabled-background-color)',
            borderColor: 'var(--ag-input-disabled-border-color)',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderRadius: 'var(--ag-border-radius)',
          }} />
        </div>
      </div>
    );
  }
}