import React, { useState, useEffect, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Tooltip } from "flowbite-react";
import { Vessel } from '../../../interfaces/Vessel';
import { VesselService } from '../../../services/VesselService';
import renderSkeleton from '../../../components/SkeletonLoader';
import ModalSync from './ModalSync';
import Table from './Table';

const Index: React.FC = () => {
  const [vessels, setVessels] = useState<Partial<Vessel>[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showModalServiceLoop, setShowModalServiceLoop] = useState<boolean>(false);
  const vesselService = new VesselService();

  useEffect(() => {
    fetchVessels();
  }, []);

  const fetchVessels = useCallback(
    async () => {
        setLoading(true);
        vesselService.getPortVessels().then((data) => { setVessels(data); setLoading(false)}).catch(() => {setLoading(false)});
  },[])
  
  return (
    <div className="ag-theme-quartz px-4">
        <div className="flex justify-between items-center py-2">
            <div className="text-lg font-bold">
                Nave
            </div>
            <div className="flex gap-2">
              <Tooltip content="Sincronizează nave">
                  <Button size="sm" onClick={() => { setShowModalServiceLoop(true); }}>Sincronizează nave</Button>
              </Tooltip>
              <Tooltip content="Adaugă serviciu">
                <NavLink to="/bookings/vessels/services">
                  <Button size="sm">Servicii</Button>
                </NavLink>
              </Tooltip>
            </div>
        </div>
        {loading ? ( renderSkeleton() ) : (
            <>
                <Table vessels={vessels} />
            </>
        )}
        <ModalSync showModal={showModalServiceLoop} setShowModal={() => {setShowModalServiceLoop(false)}} onSave={fetchVessels} />
    </div>
  );
};

export default Index;