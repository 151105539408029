import React, { useState } from 'react';
import Input from "../../../components/Input";
import CustomSelect from "../../../components/CustomSelect";
import { Button, ToggleSwitch } from 'flowbite-react';
import { handleInputChange, handleMultipleSelectChange, handleSelectChange } from "../../../functions/selectHelper";
import { defaultContainer } from "../../../interfaces/Booking";
import { RecommendationResp } from "../../../interfaces/Recommandation";
import { handleRecommendations, handleOptions } from '../../../functions/generalHelper';
import { errorMessage } from '../../../functions/generalHelper';
import InputSug from '../../../components/InputSug';
import ModalContainers from './ModalContainers';
import ContainersTable from './components/ContainersTable';
import { getContainerInfo } from '../../../interfaces/Movement';
import TextArea from '../../../components/Textarea';
import { BookingUpdateForm } from '../../../validations/booking/CreateBooking';
import { UpdateBookingContainerBooking } from '../../../validations/booking/CreateBookingContainerBooking';

interface TabProps {
  booking: Partial<BookingUpdateForm>;
  setBooking: React.Dispatch<React.SetStateAction<Partial<BookingUpdateForm>>>;
  recommendations: RecommendationResp[];
}

const ContainersTab: React.FC<TabProps> = ({ booking, setBooking, recommendations }) => {
  const [newContainer, setNewContainer] = useState<UpdateBookingContainerBooking>(defaultContainer as UpdateBookingContainerBooking);
  const [isEdit, setIsEdit] = useState<number>(-1);
  const [openModalContainers, setOpenModalContainers] = useState<boolean>(false);

  const containerNumberSuggestions = handleRecommendations(recommendations, 'booking-containerNumber');
  const typeOptions = handleOptions(recommendations, 'container-type');
  const sizeOptions = handleOptions(recommendations, 'container-size');

  const handleAddOrEditContainer = () => {
    if (!newContainer.type || !newContainer.size) {
      errorMessage('Tipul si dimensiunea containerului sunt obligatorii!');
      return;
    }
    
    if (isEdit !== -1) {
      const updatedContainers = [...booking.containers || []];
      updatedContainers[isEdit] = newContainer;
      setBooking({ ...booking, containers: updatedContainers });
      setIsEdit(-1);
    } else {
      setBooking({ ...booking, containers: [...booking.containers || [], newContainer] });
    }
    setNewContainer(defaultContainer as UpdateBookingContainerBooking);
  };

  return (
    <div>
      <ModalContainers setBooking={setBooking} booking={booking} recommendations={recommendations} openModal={openModalContainers} setOpenModal={setOpenModalContainers} />
      <div className="flex justify-end mb-2 -mt-3">
        <Button size="sm" onClick={() => setOpenModalContainers(true)}>Importă</Button>
      </div>
      <div className="rounded-lg border border-gray-200 bg-white p-5 relative mb-6">
        <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">Containere</h4>
        <ContainersTable booking={booking} isEdit={isEdit} setBooking={setBooking} setIsEdit={setIsEdit} setNewContainer={setNewContainer} />
      </div>

      {/* <div className="rounded-lg border border-gray-200 bg-white p-5 relative mb-6">
        <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">
          {isEdit !== -1 ? 'Editează container' : 'Adaugă container'}
        </h4>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6">
          <InputSug
            id="containerNumber"
            label="Număr unic"
            value={newContainer.containerNumber}
            suggestions={containerNumberSuggestions}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(newContainer, setNewContainer, 'containerNumber', e.target.value, defaultContainer as UpdateBookingContainerBooking)}
          />
          <div className="grid grid-cols-2 gap-4">
            <CustomSelect
              value={sizeOptions.find(option => option.value === newContainer.size)}
              onChange={(data) => handleSelectChange(newContainer, setNewContainer, 'size', data)}
              options={sizeOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Dimensiune"
            />
            <CustomSelect
              value={typeOptions.find(option => option.value === newContainer.type)}
              onChange={(data) => handleSelectChange(newContainer, setNewContainer, 'type', data)}
              options={typeOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Tip"
            />
          </div>
          <Input 
            id="iso" 
            label="ISO"
            value={newContainer.iso}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleInputChange(newContainer, setNewContainer, 'iso', e.target.value, defaultContainer as UpdateBookingContainerBooking);
                const sizeType = getContainerInfo(e.target.value);
                if(sizeType.size && sizeType.type){
                  setNewContainer(prev => ({...prev, size: sizeType.size, type: sizeType.type}));
                }
            }}
          />
          <Input 
            id="freeDays"
            label="Zile libere"
            type="number"
            step="1"
            value={newContainer.freeDays}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(newContainer, setNewContainer, 'freeDays', parseInt(e.target.value), defaultContainer as UpdateBookingContainerBooking)}
          />
          <Input 
            id="weight"
            label="Greutate"
            type="number"
            step="0.001"
            value={newContainer.weight}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(newContainer, setNewContainer, 'weight', parseFloat(e.target.value), defaultContainer as UpdateBookingContainerBooking)}
          />
          <div className="grid grid-cols-2 gap-4">
            <Input 
              id="vgmNumber"
              label="VGM"
              type="number"
              step="0.001"
              value={newContainer.vgmNumber}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(newContainer, setNewContainer, 'vgmNumber', parseFloat(e.target.value), defaultContainer as UpdateBookingContainerBooking)}
            />
            <Input 
              id="volume"
              label="Volum"
              type="number"
              step="0.001"
              value={newContainer.volume}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(newContainer, setNewContainer, 'volume', parseFloat(e.target.value), defaultContainer as UpdateBookingContainerBooking)}
            />
          </div>
          <Input 
            id="sealNumber"
            label="Număr sigiliu"
            value={newContainer.sealNumber}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(newContainer, setNewContainer, 'sealNumber', e.target.value, defaultContainer as UpdateBookingContainerBooking)}
          />
          <Input 
            id="packageNumber"
            label="Număr pachete"
            type="number"
            step="1"
            value={newContainer.packageNumber}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(newContainer, setNewContainer, 'packageNumber', parseInt(e.target.value), defaultContainer as UpdateBookingContainerBooking)}
          />
          <TextArea
            id={`mrnNumber`}
            label="MRN"
            value={newContainer.mrnNumber}
            rows={1}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleInputChange(newContainer, setNewContainer, 'mrnNumber', e.target.value, defaultContainer as UpdateBookingContainerBooking)}
          />
          <TextArea
            id="riNumber"
            label="Număr RI"
            rows={1}
            value={newContainer.riNumber}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleInputChange(newContainer, setNewContainer, 'riNumber', e.target.value, defaultContainer as UpdateBookingContainerBooking)}
          />
          <TextArea
            id="customsNumber"
            label="Număr vamal"
            rows={1}
            value={newContainer.customsNumber}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleInputChange(newContainer, setNewContainer, 'customsNumber', e.target.value, defaultContainer as UpdateBookingContainerBooking)}
          />
          <Input 
            id="arrivalCarNumber"
            label="Număr mașina/CFR sosire"
            value={newContainer.arrivalCarNumber}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(newContainer, setNewContainer, 'arrivalCarNumber', e.target.value, defaultContainer as UpdateBookingContainerBooking)}
          />
          <Input 
            id="departureCarNumber"
            label="Număr mașina/CFR plecare"
            value={newContainer.departureCarNumber}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(newContainer, setNewContainer, 'departureCarNumber', e.target.value, defaultContainer as UpdateBookingContainerBooking)}
          />
          <Input 
            id="reeferTemp"
            label="RfTemp"
            value={newContainer.reeferTemp}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(newContainer, setNewContainer, 'reeferTemp', e.target.value, defaultContainer as UpdateBookingContainerBooking)}
          />
          <TextArea
            id="imoDetails"
            label="ImdgDetails"
            rows={1}
            value={newContainer.imoDetails}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleInputChange(newContainer, setNewContainer, 'imoDetails', e.target.value, defaultContainer as UpdateBookingContainerBooking)}
          />
          <TextArea
            id="oogDims"
            label="OOG DIMS"
            rows={1}
            value={newContainer.oogDims}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleInputChange(newContainer, setNewContainer, 'oogDims', e.target.value, defaultContainer as UpdateBookingContainerBooking)}
          />
          <TextArea
            id="remarks"
            label="REMARKS"
            rows={1}
            value={newContainer.remarks}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleInputChange(newContainer, setNewContainer, 'remarks', e.target.value, defaultContainer as UpdateBookingContainerBooking)}
          />
          <div className="flex flex-row items-center">
            <ToggleSwitch color="primary" checked={newContainer.dangerCargo!} label="Este IMO?" onChange={() => {setNewContainer(prev => ({ ...prev, dangerCargo: !newContainer.dangerCargo! }))}} />
          </div>
          <div className="flex flex-row items-center">
            <ToggleSwitch color="primary" checked={newContainer.soc!} label="Este SOC?" onChange={() => {setNewContainer(prev => ({ ...prev, soc: !newContainer.soc! }))}} />
          </div>
        </div>
        <div className="flex justify-end space-x-4 mt-3">
          <Button size="sm" color="gray" onClick={() => {
            setIsEdit(-1);
            setNewContainer(defaultContainer as UpdateBookingContainerBooking);
          }}>
            {isEdit !== -1 ? 'Renunță la editare' : 'Resetează câmpuri'}
          </Button>
          <Button size="sm" onClick={handleAddOrEditContainer}>
            {isEdit !== -1 ? 'Editează container' : 'Adaugă container'}
          </Button>
        </div>
      </div> */}
    </div>
  );
};

export default ContainersTab;