import client from '../apollo-client';
import { gql } from '@apollo/client';
import { Vessel, VesselServiceLoop } from '../interfaces/Vessel';

export class VesselService {

  async syncVessels(service : string, direction: string) : Promise<{ status: string; message: string; }> { 
    const response = await client.mutate({
      mutation: gql`
        mutation {
          syncVessels(
            service: "${service}",
            direction: "${direction}"
          ){
            status
            message  
          }
        }
      `,
    });
    return response.data.syncVessels;
  }

  async getPortVessels(): Promise<Vessel[]> {
    const response = await client.query({
        query: gql`
            query {
                getPortVessels {
                  id
                  terminal
                  from
                  to
                  service
                  serviceCode
                  name
                  code
                  etd
                  atd
                  eta
                  ata
                }
            }
        `,
    });
    return response.data.getPortVessels.map((vessel : Vessel) => ({
        ...vessel,
        arrivalDate : (vessel.eta || vessel.ata) ? new Date((vessel.ata ? vessel.ata! : vessel.eta!)) : null,
        arrivalActual : vessel.ata ? true : false,
        departureDate : (vessel.etd || vessel.atd) ? new Date((vessel.atd ? vessel.atd! : vessel.etd!)) : null,
        departureActual : vessel.atd ? true : false,
    }));
  }

  async getVesselsForBooking(port: string): Promise<Vessel[]> {
    const response = await client.query({
        query: gql`
            query {
                getVesselsForBooking(port: "${port}") {
                  id
                  terminal
                  from
                  to
                  service
                  serviceCode
                  name
                  code
                  etd
                  atd
                  eta
                  ata
                }
            }
        `,
    });
    return response.data.getVesselsForBooking.map((vessel : Vessel) => ({
        ...vessel,
        arrivalDate : (vessel.eta || vessel.ata) ? new Date((vessel.ata ? vessel.ata! : vessel.eta!)) : null,
        arrivalActual : vessel.ata ? true : false,
        departureDate : (vessel.etd || vessel.atd) ? new Date((vessel.atd ? vessel.atd! : vessel.etd!)) : null,
        departureActual : vessel.atd ? true : false,
    }));
  }

  async createServiceLoop(serviceLoop: VesselServiceLoop): Promise<VesselServiceLoop> {
      const response = await client.mutate({
        mutation: gql`
          mutation {
              createServiceLoop(
                input: { 
                    name: "${serviceLoop.name}", 
                    port: "${serviceLoop.port}",
                    firstBound: "${serviceLoop.firstBound}",
                    secondBound: "${serviceLoop.secondBound}",
                  }
              ){
                id
                name
                port
                firstBound
                secondBound
              }
          }
        `,
      });
      return response.data.createServiceLoop;
  }

  async updateServiceLoop(serviceLoop: VesselServiceLoop): Promise<VesselServiceLoop> {
      const response = await client.mutate({
        mutation: gql`
          mutation {
              updateServiceLoop(
                input: { 
                    id: ${serviceLoop.id}, 
                    name: "${serviceLoop.name}", 
                    port: "${serviceLoop.port}",
                    firstBound: "${serviceLoop.firstBound}",
                    secondBound: "${serviceLoop.secondBound}",
                  }
              ){
                id
                name
                port
                firstBound
                secondBound
              }
          }
        `,
      });
      return response.data.updateServiceLoop;
  }

  async getServiceLoops(): Promise<VesselServiceLoop[]> {
      const response = await client.query({
          query: gql`
              query {
                  vesselServiceLoops {
                      id
                      name
                      port
                      firstBound
                      secondBound
                  }
              }
          `,
      });
      return response.data.vesselServiceLoops;
  }

  async getServiceLoop(id : number): Promise<VesselServiceLoop> {
      const response = await client.query({
          query: gql`
              query {
                  vesselServiceLoop(id: ${id}) {
                      id
                      name
                      port
                      firstBound
                      secondBound
                  }
              }
          `,
      });
      return response.data.vesselServiceLoop;
  }

  async deleteServiceLoop(id: number): Promise<VesselServiceLoop> {
      const response = await client.mutate({
        mutation: gql`
          mutation {
            deleteServiceLoop(id: ${id})
          }
        `,
      });
      return response.data.deleteServiceLoop;
  }
}