import React, { useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { ViewBooking, ViewContainer } from '../../../../interfaces/Booking';
import { CellRenderer } from '../../../../functions/agGridHelper';
import { AG_GRID_LOCALE_RO } from '../../../../functions/langHelper';
import Actions from '../../../../components/Actions';
import { BookingService } from '../../../../services/BookingService';
import { successMessage } from '../../../../functions/generalHelper';
import useGridState from '../../../../functions/useGridState';

interface ContainersTableProps {
  booking: ViewBooking;
  setBooking: React.Dispatch<React.SetStateAction<ViewBooking>>;
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
}

const ContainersTable: React.FC<ContainersTableProps> = ({ booking, onEdit, onDelete, setBooking }) => {
    const bookingService = new BookingService();
    const { saveState, restoreState } = useGridState('bookingContainers-import-grid');

    const onGridReady = useCallback((event: GridReadyEvent) => {
      const api = event.api;
      event.api.closeToolPanel();
      
      // Restaurăm starea salvată
      const savedState = localStorage.getItem(`agGrid-bookingContainers-import-grid`);
      if (savedState) {
        // Dacă există state salvat, îl restaurăm
        restoreState(api);
      }
      // Adăugăm event listeners pentru salvarea stării
      const saveCurrentState = () => saveState(api);
      
      api.addEventListener('filterChanged', saveCurrentState);
      api.addEventListener('sortChanged', saveCurrentState);
      api.addEventListener('columnMoved', saveCurrentState);
      api.addEventListener('columnResized', saveCurrentState);
      api.addEventListener('paginationChanged', saveCurrentState);

      // Optional: Cleanup function
      return () => {
        api.removeEventListener('filterChanged', saveCurrentState);
        api.removeEventListener('sortChanged', saveCurrentState);
        api.removeEventListener('columnMoved', saveCurrentState);
        api.removeEventListener('columnResized', saveCurrentState);
        api.removeEventListener('paginationChanged', saveCurrentState);
      };
    }, [saveState, restoreState, booking.containers]);

    const modificaCelula = async (params : any, coloana : string) => {
      const updatedContainer = {
        ...booking.containers.find(cont => cont.id! === params.data.id!)!,
        [coloana!]: params.newValue
      };
    
      // Actualizăm containerul în backend
      const savedContainer = await bookingService.updateBookingContainer(updatedContainer);
    
      // Actualizăm state-ul local cu noul container
      setBooking(prev => ({
        ...prev,
        containers: prev.containers.map(cont => 
          cont.id === savedContainer.id ? savedContainer : cont
        )
      }));
    
      successMessage('Container editat cu succes!');
    }

    const columns: ColDef<ViewContainer>[] = [
    { 
      headerName: 'Număr unic', 
      field: 'containerNumber', 
      editable: true,
      cellClass: 'group', 
      cellRenderer: CellRenderer,
      minWidth: 160,
      valueSetter: (params) => {
        modificaCelula(params, 'containerNumber');
        return true;
      }
    },
    { 
      headerName: 'Dimensiune',
      field: 'size',
      editable: true,
      cellClass: 'group', 
      minWidth: 90,
      cellRenderer: CellRenderer,
      valueSetter: (params) => {
        modificaCelula(params, 'size');
        return true;
      }
    },
    { 
      headerName: 'Tip', 
      field: 'type', 
      editable: true,
      cellClass: 'group', 
      minWidth: 90,
      cellRenderer: CellRenderer,
      valueSetter: (params) => {
        modificaCelula(params, 'type');
        return true;
      }
    },
    { 
      headerName: 'Număr sigiliu', 
      field: 'sealNumber', 
      editable: true,
      cellClass: 'group', 
      minWidth: 160,
      cellRenderer: CellRenderer,
      valueSetter: (params) => {
        modificaCelula(params, 'sealNumber');
        return true;
      }
    },
    { 
      headerName: 'ISO', 
      field: 'iso', 
      minWidth: 80,
      editable: true,
      cellClass: 'group', 
      cellRenderer: CellRenderer,
      valueSetter: (params) => {
        modificaCelula(params, 'iso');
        return true; 
      },
    },
    { 
      headerName: 'Zile libere', 
      field: 'freeDays', 
      editable: true,
      cellClass: 'group', 
      minWidth: 90,
      cellRenderer: CellRenderer,
      valueSetter: (params) => {
        modificaCelula(params, 'freeDays');
        return true;
      }
    },
    { 
      headerName: 'Greutate', 
      field: 'weight', 
      editable: true,
      cellClass: 'group', 
      cellRenderer: CellRenderer,
      minWidth: 90,
      valueSetter: (params) => {
        modificaCelula(params, 'weight');
        return true;
      }
    },
    { 
      headerName: 'Pachete', 
      field: 'packageNumber', 
      editable: true,
      cellClass: 'group', 
      cellRenderer: CellRenderer,
      minWidth: 90,
      valueSetter: (params) => {
        modificaCelula(params, 'packageNumber');
        return true;
      }
    },
    { 
      headerName: 'Volum', 
      field: 'volume', 
      editable: true,
      cellClass: 'group', 
      cellRenderer: CellRenderer,
      minWidth: 90,
      valueSetter: (params) => {
        modificaCelula(params, 'volume');
        return true;
      }
    },
    { 
      headerName: 'IMO', 
      field: 'dangerCargo', 
      editable: true,
      minWidth: 70,
      valueSetter: (params) => {
        modificaCelula(params, 'dangerCargo');
        return true;
      }
    },
    {
      headerName: 'Acțiuni',
      minWidth: 100,
      field: 'id',
      cellRenderer: (params: any) => (
        <div>
          <Actions 
            id={params.data.id} 
            onEdit={() => onEdit(params.data.id)} 
            onDelete={() => onDelete(params.data.id)} 
            moduleName="container"
          />
        </div>
      )
    }
  ];

  return (
    <div className="ag-theme-quartz">
      <AgGridReact 
        localeText={AG_GRID_LOCALE_RO}
        columnDefs={columns} 
        rowData={booking.containers}
        defaultColDef={{ flex: 1, minWidth: 20 }}
        domLayout='autoHeight'
        pagination={false}
        gridOptions={{rowHeight: 35}}
        suppressClickEdit={true}
        stopEditingWhenCellsLoseFocus={true}
        onGridReady={onGridReady}
        enableCharts={true}
        sideBar={true}
      />
    </div>
  );
};

export default ContainersTable;