import React, { useCallback, useEffect, useState } from 'react';
import { Booking, BookingFile, ViewBooking } from '../../../interfaces/Booking';
import DeleteModal from '../../../components/DeleteModal';
import { FileService } from '../../../services/FileService';
import FilesTable from './components/FilesTable';
import DragDropFileUpload from '../../../components/DragDropFileUpload';
import { errorMessage, successMessage } from '../../../functions/generalHelper';

interface FilesProps {
  booking: ViewBooking;
  setBooking: React.Dispatch<React.SetStateAction<ViewBooking>>;
}

const Files: React.FC<FilesProps> = ({ booking, setBooking }) => {
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [bookingFiles, setBookingFiles] = useState<Partial<BookingFile>[]>([]);
  const [idToDelete, setIdToDelete] = useState<number>(0);
  const fileService = new FileService();

  useEffect(() => {
    if(booking.id){
        fetchFiles(booking.id)
    }
  }, [booking.id]);

  const fetchFiles = useCallback((id : number) => {
    fileService.getBookingFiles(id).then((data) => {
        setBookingFiles(data);
    });
  }, [booking.id]);

  const handleDelete = (id : number) => {
    setIdToDelete(id);
    setShowModalDelete(true);
  };

    const confirmDelete = () => {
        fileService.removeBookingFile(idToDelete).then((response) => {
            if(response.status === 'error'){
                errorMessage('Eroare la stergerea containerului!');
                return;
            }
            setBookingFiles(prev => prev.filter(file => file.id !== idToDelete));
            successMessage(response.message);
        }).finally(() => {
            setIdToDelete(0);
            setShowModalDelete(false);
        });
    }

    const handleFileChange = async (file : File) => {
        if(booking.id){
            const fileResponse = await fileService.uploadBookingFile(file, booking.id);
            if(fileResponse.status === 'success'){
                successMessage('Fisier incarcat cu succes!');
                fetchFiles(booking.id);
            }else{
                errorMessage('Eroare la incarcarea fisierului!');
            }
        }else{
            errorMessage('Booking-ul nu a fost gasit!');
        }
    };

  return (
    <div>
      <div className="rounded-lg border border-gray-200 bg-white p-5 relative mb-6">
        <h4 className="absolute text-base font-semibold text-main bg-white -top-3 px-3 left-10">Fisiere</h4>
        <FilesTable files={bookingFiles} onDelete={handleDelete} />
        <div className="mt-3">
            <DragDropFileUpload onFileUpload={handleFileChange} />
        </div>
        <DeleteModal isOpen={showModalDelete} onClose={() => {setShowModalDelete(false)}} onConfirm={confirmDelete} deleteText='Sunteți sigur că doriți să eliminați acest fisier?' />
      </div>
    </div>
  );
};


export default Files;